import { makeStyles } from "@material-ui/core";
import React from "react";
import Carousel, { consts } from "react-elastic-carousel";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { SERVER_URL } from "api/ApiConstants";

const InternationalSection = ({ data }) => {
  const classes = useStyles();
  const history = useHistory();
  const width = window.innerWidth;

  function HotelDetail(name, packageType, index, advertise, packageId) {
    if (advertise === true) {
      history.push("/packages/packageinfo", { data: packageId });
    } else {
      history.push({
        pathname: "/packages/",
        state: { name, packageType, index },
      });
    }
    // setSecLogo(true);
  }

  const CarouselContainer = ({ pack, index }) => {
    const [hoverDescription, setHoverDescription] = useState(false);
    const [hoverTitle, setHoverTitle] = useState(false);

    return (
      <div
        className="carousel"
        // style={{ width: index === 0 || index === 5 ? "50%" : "25%" }}
        onClick={() =>
          HotelDetail(
            pack.packageCategoryId,
            pack.packageCategoryType,
            1,
            true,
            pack.packageId
          )
        }
      >
        <div className="carousel-subContainer">
          <img
            //  src={topDestinationPlace}
            src={
              SERVER_URL +
              // "http://oari.co:8080/" +
              "packageadmin/ImageReaderArticle?path=/" +
              pack.packageCategoryType +
              "/" +
              pack.packageCategoryId +
              "/" +
              pack.packageId +
              "/" +
              pack.packageCategoryImage
            }
            className="carouselImage"
          // style={{ width: (index === 0 || index === 5) && 400 }}
          />
          <div className="carouselDescription">
            <div
              onMouseEnter={() => setHoverTitle(true)}
              onMouseLeave={() => setHoverTitle(false)}
              className="carouselHeader"
            >
              {pack.packageCategoryName}
            </div>
            {/* {hoverTitle && (
            <div className="packageDestinationHoverTitle">
              {pack.packageCategoryName.replaceAll("&#45;", ", ")}
            </div>
          )} */}
            <div className="packageDestinationPrice">
              <span className="popularRoutePriceText">
                ₹ {pack.packageCategoryStartingPrice?.toLocaleString("en-IN")}
              </span>
              Per Person
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.rootContainer}>
      <div className={classes.root}>
        <div className="headerContainer">
          <div className="topHeader">International</div>
          {/* <div className="carouselHeaderContainer">
          <div className="carouselHeaderText">
            A destination is a bundle package consisting of various tourism
            facilities and services which, like any other service product,
            consists of a number of multidimensional attributes that together
            determine their attractiveness to certain individuals in certain
            preferred situations.
          </div>
          <div className="exploreBtn">Explore</div>
        </div> */}
        </div>

        <div className="carouselContainer">
          {/* <Carousel
          itemsToShow={width > 1350 ? 3 : width < 550 ? 1 : 2}
          itemPadding={[0, 10, 0, 0]}
          // pagination={false}

          // renderArrow={CustomArrow}
        > */}

          <div className="carouselSub">
            {data?.packagesCategories && data?.packagesCategories
              .slice(0, 3)
              .map((pack, index) => <CarouselContainer pack={pack} />)}
          </div>

          <div className="carouselSub">
            {data?.packagesCategories && data?.packagesCategories
              .slice(4, 7)
              .map((pack, index) => <CarouselContainer pack={pack} />)}
          </div>

          {/* </Carousel> */}
        </div>
      </div>
    </div>
  );
};

export default InternationalSection;

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    background:
      "linear-gradient(180deg, rgba(241, 185, 28, 0.74) 0%, rgba(255, 122, 0, 0.74) 100%)",
    width: "100%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
  },
  root: {
    paddingTop: 50,
    marginBottom: 20,
    maxWidth: 1280,
    width: "100%",
    [theme.breakpoints.down(1280)]: {
      padding: "0px 20px",
    },

    // background:
    //   "linear-gradient(180deg, rgba(241, 185, 28, 0.74) 0%, rgba(255, 122, 0, 0.74) 100%)",

    [theme.breakpoints.down(900)]: {
      padding: "0px 20px",
      paddingTop: 30,
    },
    "& .popularRoutePriceText": {
      color: theme.palette.primary.defaultText,
      fontWeight: 500,
      marginRight: 5,
      fontSize: 18,
    },
    "& .exploreBtn": {
      background: theme.palette.secondary.main,
      width: 100,
      height: 40,
      borderRadius: 20,
      marginTop: 4,
      color: theme.palette.primary.contrastText,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .rec-dot": {
      backgroundColor: "#9F9F9F",
      boxShadow: "none",
      opacity: "30%",
    },
    "& .rec-dot_active": {
      boxShadow: "0 0 1px 3px #9F9F9F",
      opacity: "100%",
    },
    "& .rec-arrow": {
      display: "none",
    },
    // height: 430,
    // backgroundColor: "#e9e9e9",
    // width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .headerContainer": {
      display: "flex",
    },
    "& .carousel": {
      // width: "26%",
      cursor: "pointer",
      display: "flex",
      padding: 10,
      justifyContent: "center",

      "& .carousel-subContainer": {
        width: 300,
        position: "relative",
        [theme.breakpoints.down(1100)]: {
          width: 250,
        },
        [theme.breakpoints.down(870)]: {
          width: 200,
        },
        // [theme.breakpoints.down(560)]: {
        //   width: 220,
        // },
      },
    },
    "& .carouselContainer": {
      //   marginTop: 30,
      //   display: "flex",
      //   justifyContent: "space-between",
      maxWidth: 1280,
      width: "100%",
      marginBottom: 40,
    },
    "& .carouselDescription": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      //   position: "absolute",
      //   bottom: 20,
      //   width: 280,
      //   left: 0,
      //   background: "#0000007D",
    },
    "& .carouselSub": {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down(720)]: {
        flexDirection: "column",
      },
    },
    "& .carouselImage": {
      width: "100%",
      height: 300,
      [theme.breakpoints.down(1100)]: {
        height: 250,
      },
      [theme.breakpoints.down(870)]: {
        height: 200,
      },
      border: `10px solid ${theme.palette.background.default}`,

      //   width: 330,
      borderRadius: "50%",

      //   [theme.breakpoints.down(1250)]: {
      //     width: 250,
      //   },
      //   [theme.breakpoints.down(560)]: {
      //     width: 220,
      //     height: 180,
      //   },
    },
    "& .carouselHeaderContainer": {
      width: "65%",
    },
    "& .carouselHeaderText": {
      marginTop: 20,
      // width: "65%",
      fontSize: 19,
      [theme.breakpoints.down(550)]: {
        marginTop: 5,
        textAlign: "left",
      },
    },
    "& .carouselHeader": {
      fontSize: 24,
      [theme.breakpoints.down(870)]: {
        fontSize: 17,
      },
      fontWeight: 700,
      fontFamily: "TimesNewRoman",
      marginTop: 10,
      marginBottom: 5,
      //   height: 40,
      // width: 330,
      overflow: "hidden",
      textAlign: "center",
      color: theme.palette.primary.defaultText,
      paddingLeft: 15,

      // marginBottom: 30,
    },
    "& .topHeader": {
      fontSize: 55,
      fontWeight: 700,
      fontFamily: "TimesNewRoman",
      marginBottom: 20,
      width: "35%",
      [theme.breakpoints.down(900)]: {
        fontSize: 40,
        lineHeight: 0.9,
      },
      [theme.breakpoints.down(550)]: {
        fontSize: 30,
        textAlign: "center",
        lineHeight: 0.9,
      },
    },
    "& .hightlight": {
      color: "#FF0000",
      marginRight: 10,
    },
    "& .packageDestinationHoverTitle": {
      position: "absolute",
      backgroundColor: theme.palette.primary.lightText,
      fontSize: 12,
      padding: 6,
      borderRadius: 5,
      border: `1px solid ${theme.palette.primary.contrastText}`,
      zIndex: 1000,
      bottom: 20,
      left: 45,
      // boxShadow: "0 0 1px 3px #ff0000",

      // height: "100%",
    },
    "& .packageDestinationPrice": {
      textAlign: "left",
      paddingLeft: 15,
      color: theme.palette.primary.defaultText,
    },
  },
}));
