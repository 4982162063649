import { makeStyles } from "@material-ui/core";
import React from "react";
import Carousel, { consts } from "react-elastic-carousel";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { SERVER_URL } from "api/ApiConstants";

const WhyChooseUs = ({ data }) => {
  const classes = useStyles();
  const history = useHistory();
  const width = window.innerWidth;

  //   function HotelDetail(name, packageType, index, advertise, packageId) {
  //     if (advertise === true) {
  //       history.push("/packages/packageinfo", { data: packageId });
  //     } else {
  //       history.push({
  //         pathname: "/packages/",
  //         state: { name, packageType, index },
  //       });
  //     }
  //     // setSecLogo(true);
  //   }

  //   const CarouselContainer = ({ pack }) => {
  //     const [hoverDescription, setHoverDescription] = useState(false);
  //     const [hoverTitle, setHoverTitle] = useState(false);

  //     return (
  //       <div
  //         className="carousel"
  //         onClick={() =>
  //           HotelDetail(
  //             pack.packageCategoryId,
  //             pack.packageCategoryType,
  //             1,
  //             true,
  //             pack.packageId
  //           )
  //         }
  //       >
  //         <div className="carousel-subContainer">
  //           <img
  //             //  src={topDestinationPlace}
  //             src={
  //               //   SERVER_URL +
  //               "http://oari.co:8080/" +
  //               "packageadmin/ImageReaderArticle?path=/" +
  //               pack.packageCategoryType +
  //               "/" +
  //               pack.packageCategoryId +
  //               "/" +
  //               pack.packageId +
  //               "/" +
  //               pack.packageCategoryImage
  //             }
  //             className="carouselImage"
  //           />
  //           <div className="carouselDescription">
  //             <div
  //               onMouseEnter={() => setHoverTitle(true)}
  //               onMouseLeave={() => setHoverTitle(false)}
  //               className="carouselHeader"
  //             >
  //               {pack.packageCategoryName}
  //             </div>
  //             {hoverTitle && (
  //               <div className="packageDestinationHoverTitle">
  //                 {pack.packageCategoryName.replaceAll("&#45;", ", ")}
  //               </div>
  //             )}
  //             <div className="packageDestinationPrice">
  //               <span className="popularRoutePriceText">
  //                 ₹ {pack.packageCategoryStartingPrice.toLocaleString("en-IN")}
  //               </span>
  //               Per Person
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   };

  return (
    <div className={classes.rootContainer}>
      <div className={classes.root}>
        <div
          className="headerC
        ontainer"
        >
          <div className="topHeader">Why Should Choose us?</div>
          <div className="carouselHeaderContainer">
            <div className="contentContainer">
              <div className="contentHeader">200+</div>
              <div className="carouselHeaderText">
                Positive feedback we get while serving our customers
              </div>
            </div>
            <div className="contentContainer">
              <div className="contentHeader">17 years</div>
              <div className="carouselHeaderText">
                Our very long experience make us successful
              </div>
            </div>
            <div className="contentContainer">
              <div className="contentHeader">200+</div>
              <div className="carouselHeaderText">
                Positive feedback about the services we provide
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    // background:
    //   "linear-gradient(180deg, rgba(241, 185, 28, 0.74) 0%, rgba(255, 122, 0, 0.74) 100%)",
    width: "100%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
  },
  root: {
    paddingTop: 50,
    marginBottom: 20,
    width: 1280,
    [theme.breakpoints.down(900)]: {
      padding: "0px 20px",
      paddingTop: 30,
    },
    "& .popularRoutePriceText": {
      color: theme.palette.primary.main,
      fontWeight: 500,
      marginRight: 5,
      fontSize: 20,
    },
    "& .exploreBtn": {
      background: theme.palette.secondary.main,
      width: 100,
      height: 40,
      borderRadius: 20,
      marginTop: 4,
      color: theme.palette.primary.contrastText,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .rec-dot": {
      backgroundColor: "#fff",
      boxShadow: "none",
      opacity: "30%",
    },
    "& .rec-dot_active": {
      boxShadow: "0 0 1px 3px #fff",
      opacity: "100%",
    },
    "& .rec-arrow": {
      display: "none",
    },
    // height: 430,
    // backgroundColor: "#e9e9e9",
    // width: "100%",
    display: "flex",
    // flexDirection: "column",
    alignItems: "flex-start",
    "& .headerContainer": {
      display: "flex",
      //   flexDirection: "column",
      width: "100%",
      [theme.breakpoints.down(570)]: {
        flexDirection: "column",
      },
    },
    "& .carousel": {
      // width: "26%",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",

      "& .carousel-subContainer": {
        [theme.breakpoints.down(1250)]: {
          width: 250,
        },
        [theme.breakpoints.down(560)]: {
          width: 220,
        },
      },
    },
    "& .carouselContainer": {
      width: "60%",
      // marginTop: 30,
      display: "flex",
      justifyContent: "space-between",
      maxWidth: 1280,
      width: "100%",
      marginBottom: 40,
    },
    "& .carouselDescription": {
      position: "absolute",
      bottom: 20,
      width: 210,
      left: 10,
      background: "#0000007D",
    },
    "& .carouselImage": {
      height: 500,

      padding: 10,
      // width: 330,
      // borderRadius: 80,
      [theme.breakpoints.down(1250)]: {
        width: 250,
      },
      [theme.breakpoints.down(560)]: {
        width: 220,
        height: 180,
      },
    },
    "& .carouselHeaderContainer": {
      // width: "65%",
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down(500)]: {
        flexDirection: "column",
        alignItems: "center",
      },
      "& .contentHeader": {
        fontSize: 23,
        fontWeight: 600,
      },
      "& .contentContainer": {
        width: "30%",
        textAlign: "center",
        [theme.breakpoints.down(500)]: {
          width: "60%",
          marginBottom: 20,
          textAlign: "center",
        },
      },
    },
    "& .carouselHeaderText": {
      // marginTop: 20,
      // width: "65%",
      fontSize: 17,
      [theme.breakpoints.down(550)]: {
        marginTop: 5,
        textAlign: "center",
      },
    },
    "& .carouselHeader": {
      fontSize: 20,
      fontWeight: 700,
      fontFamily: "TimesNewRoman",
      marginTop: 10,
      marginBottom: 5,
      // height: 40,
      color: theme.palette.primary.contrastText,
      // width: 330,
      overflow: "hidden",
      // textAlign: "center",
      paddingLeft: 10,

      // marginBottom: 30,
    },
    "& .topHeader": {
      fontSize: 40,
      fontWeight: 700,
      fontFamily: "TimesNewRoman",
      marginBottom: 20,
      color: theme.palette.primary.defaultText,
      // width: "35%",
      [theme.breakpoints.down(900)]: {
        fontSize: 40,
        lineHeight: 0.9,
      },
      [theme.breakpoints.down(550)]: {
        fontSize: 30,
        textAlign: "center",
        lineHeight: 0.9,
      },
    },
    "& .hightlight": {
      color: "#FF0000",
      marginRight: 10,
    },
    "& .packageDestinationHoverTitle": {
      position: "absolute",
      backgroundColor: theme.palette.primary.lightText,
      fontSize: 12,
      padding: 6,
      borderRadius: 5,
      border: `1px solid ${theme.palette.primary.contrastText}`,
      zIndex: 1000,
      bottom: 20,
      left: 45,
      // boxShadow: "0 0 1px 3px #ff0000",

      // height: "100%",
    },
    "& .packageDestinationPrice": {
      // textAlign: "center",
      color: theme.palette.primary.main,
      paddingLeft: 10,
    },
  },
}));
