import React, { useState, useEffect } from "react";
import CancellationSearchForm from "./CancellationSearchForm";
import CancellationSearchResult from "./CancellationSearchResult";
import CancellationNonPNRSearchResult from "./CancellationNonPNRSearchResult";
import OaCard from "oahoc/OaCard";
import dateFnsFormat from "date-fns/format";

import {
  getQueryVar,
  getCurrentTab,
} from "oautils/oaCommonUtils";
import OaPriTabs from "oahoc/OaPriTabs";
import OaError from "pages/components/OaError";

export const Cancellation = (props) => {
  const [isLoading, setIsLoading] = useState(true);

  let d = new Date();
  let fromDate = d.setDate(d.getDate() - 365);
  //let fromDate = d;
  let today = new Date();

  let tabs = [
    {
      name: "cancellation",
      label: "Cancellation",
      url: "/admin/module/cancellations/flight/cancellation",
    },
    {
      name: "rollback",
      label: "Rollback",
      url: "/admin/module/cancellations/flight/rollback",
    },
  ];
  // let currentTab = 'cancellation';

  const queryVar = getQueryVar({ ...props, q: "priTab" });
  let currentTab = getCurrentTab(queryVar, "cancellation");

  let initialParams = {
    agencyId: 0,
    dateFromDisplay: today,
    dateToDisplay: today,
    dateFrom: dateFnsFormat(fromDate, "dd-MM-yyyy"),
    dateTo: dateFnsFormat(today, "dd-MM-yyyy"),
    type: "Pending",
    userType: ""
  };
  const [tableKey, setTableKey] = useState(0);
  const [searchParams, setSearchParams] = useState(initialParams);
  // console.log("props cancell", props);

  function renderTab(currentTab) {
    // console.log(currentTab)
    switch (currentTab) {
      case "cancellation":
        return (
          <CancellationSearchResult
            key={tableKey}
            searchParams={searchParams}
            {...props}
          />
        );
      case "rollback":
        return (
          <CancellationNonPNRSearchResult
            key={tableKey}
            searchParams={searchParams}
            {...props}
          />
        );
      default:
        return <OaError />;
    }
  }

  function ledgerReportLoader(searchParams) {
    setSearchParams(searchParams);
    setTableKey(tableKey + 1);
  }

  useEffect(() => {
    setSearchParams({ ...searchParams });
    setTableKey(tableKey + 1);
    setIsLoading(false);
  }, [props]);

  return (
    <>
      {!isLoading && (
        <OaCard className={"oa-card-primary"}>
          <OaPriTabs tabs={tabs} currentTab={currentTab} />
          <OaCard>
            <CancellationSearchForm
              target={ledgerReportLoader}
              searchParams={searchParams}
            />
          </OaCard>
          <OaCard>{renderTab(currentTab)}</OaCard>
        </OaCard>
      )}
    </>
  );
};
export default Cancellation;
