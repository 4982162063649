import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  RadioGroup,
  Radio,
  Button,
  useMediaQuery,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import React, { useContext, useEffect, useState } from "react";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import Menu from "pages/b2c/component/Menu";
import Flight from "assets/img/6E.png";
import Profile from "./component/Profile";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import OaFormAlerts from "pages/components/OaFormAlerts";
import { AppStateContext } from "layouts/AppStateProvider";
import { connect } from "react-redux";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PrintIcon from "@material-ui/icons/Print";
import DescriptionIcon from "@material-ui/icons/Description";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { ArrowBack } from "@material-ui/icons";
import { GET_FILE_UPLOAD } from "api/ApiConstants";
import { localforageGetItem } from "oautils/oaForageUtils";

const MuiAccordion = withStyles({
  root: {
    width: "100%",
    border: "0px",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(Accordion);

const MuiAccordionSummary = withStyles({
  root: {
    backgroundColor: "white",
    padding: 0,
    margin: 0,
    minHeight: "auto",
  },
  expanded: {},
})(AccordionSummary);

const MuiAccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
    background: "white",
  },
}))(AccordionDetails);

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    margin: "auto",
    paddingTop: "40px",
    paddingBottom: "40px",
    //marginTop: 100,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  rightGrid: {
    background: "#fff",
    paddingBottom: "6px",
    textAlign: "center",
    border: `1px solid ${theme.palette.primary.disabled}`,
    padding: "20px",
    borderRadius: "5px",
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 15,
      marginRight: 15,
      marginTop: 10,
      padding: 5,
    },
  },
  typo1: {
    fontSize: "11px",
    fontWeight: 500,
    lineHeight: "1.5",
    color: theme.palette.primary.darkText,
    marginLeft: 5,
    letterSpacing: "0.5px !important",
  },
  typo2: {
    fontSize: "13px",
    paddingRight: "10px",
    fontWeight: 700,
    color: theme.palette.primary.darkText,
    letterSpacing: "0.5px !important",
    borderRight: "1px solid",
  },
  typo3: {
    fontSize: "13px",
    fontWeight: 700,
    color: theme.palette.primary.darkText,
    letterSpacing: "0.5px !important",
    paddingLeft: "10px",
  },
  typo4: {
    fontWeight: 500,
    lineHeight: 1.5,
    color: theme.palette.primary.darkText,
  },
  typo5: {
    fontSize: 12,
    color: theme.palette.primary.darkText,
    fontWeight: 500,
  },
  fai: {},
  typo6: {
    textAlign: "center",
    color: theme.palette.primary.darkText,
    fontSize: "12px",
    padding: "0px 10px",
  },
  img: {
    width: "26px",
    marginTop: "5px",
    verticalAlign: " baseline",
  },
  typo7: {
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.primary.darkText,
  },
  typo8: {
    color: "gray",
    fontSize: "13px",
  },

  typo9: {
    lineHeight: 1.5,
    color: theme.palette.primary.darkText,
    fontSize: "12px",
  },
  typo10: {
    fontSize: 12,
    color: theme.palette.primary.darkText,
    fontWeight: 400,
  },
  heading2: {
    fontSize: 18,
    fontWeight: 500,
    marginTop: 10,
    marginBottom: 10,
  },
  table: {
    width: "100%",
    marginBottom: "1rem",
    color: theme.palette.primary.darkText,
    borderCollapse: "collapse",

    "& thead": {
      "& tr": {
        "& th": {
          border: `1px solid ${theme.palette.primary.disabled} !important`,
          fontSize: "12px !important",
          fontWeight: "500 !important",

          padding: ".75rem",
          color: theme.palette.primary.darkText,
          [theme.breakpoints.down("sm")]: {
            padding: "0px",
            fontSize: "7px !important",
          },
        },
      },
    },
    "& tbody": {
      "& tr": {
        "& td": {
          border: `1px solid ${theme.palette.primary.disabled} !important`,
          fontSize: "12px !important",
          fontWeight: "400 !important",
          padding: ".75rem",
          [theme.breakpoints.down("sm")]: {
            padding: "0px",
            fontSize: "7px !important",
          },
        },
      },
    },
  },

  bookingGrid: {
    marginLeft: "60%",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0px",
    },
  },
  Grid1: {
    borderRight: `1px solid ${theme.palette.primary.gray}`,
    marginTop: 10,
    [theme.breakpoints.down("xs")]: {
      borderRight: "none",
    },
  },
  Grid2: {
    paddingLeft: 15,
    paddingBottom: 25,
    paddingRight: 25,
    [theme.breakpoints.down("xs")]: {
      paddingRight: 15,
    },
  },
  backButton: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.primary.main,
    "& .MuiSvgIcon-root": {
      fontSize: 16,
      marginRight: 5,
    },
    "&:hover": {
      color: theme.palette.primary.sub,
    },
  },
}));

const Itinerary = (props) => {
  const classes = useStyles();
  // console.log("ticket");
  // console.log("ticket");

  const [onwardCancelPassengers, setOnwardCancelPassengers] = useState([]);
  const [returnCancelPassengers, setReturnCancelPassengers] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [onwardExpanded, setOnwardExpanded] = useState(false);
  const [returnExpanded, setReturnExpanded] = useState(false);
  const [value, setValue] = useState("");
  const [valueReturn, setValueReturn] = useState("");
  const [cancellationType, setCancellationType] = useState("");
  const [cancellationTypeReturn, setCancellationTypereturn] = useState("");

  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const { setIsCancelled } = useContext(AppStateContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // console.log(props.location.state.index);
  //let userType = "";
  // console.log("itin props",props);
  // if (props.history.location.state && props.history.location.state.userType) {
  //  userType = props.history.location.state.userType;
  // }

  const handleChangeRadio = (event) => {
    setValue(event.target.value);
  };

  const handleChangeRadioReturn = (event) => {
    setValueReturn(event.target.value);
  };

  const handleChangeCancellation = (event) => {
    setCancellationType(event.target.value);
  };
  const handleChangeCancellationReturn = (event) => {
    setCancellationTypereturn(event.target.value);
  };

  // const ticket = props.flightList[props.location?.state?.index];
  const ticket = props.location?.state?.ticket;

  //const userType=props.location?.state?.userType;
  const { setUserTypeb2c } = React.useContext(AppStateContext);
  const [userTypeAbv, setUserTypeAbv] = React.useState(null);

  // console.log("ticket", ticket);

  const handleChange = (ev, passenger) => {
    const temp = [...onwardCancelPassengers];
    const index = temp.findIndex((a) => a === passenger);
    if (index !== -1) {
      temp.splice(temp.indexOf(index), 1);
    } else {
      temp.push(passenger);
    }

    // if (ev.target.checked) {
    //   setOnwardCancelPassengers([...onwardCancelPassengers, passenger]);
    // } else {
    //   setOnwardCancelPassengers(value => value.filter(obj => { return obj.uid !== passenger.uid }));
    // }

    setOnwardCancelPassengers(temp);
  };
  const handleReturnChange = (passenger) => {
    const temp = [...returnCancelPassengers];
    const index = temp.findIndex((a) => a === passenger);
    if (index !== -1) {
      temp.splice(temp.indexOf(index), 1);
    } else {
      temp.push(passenger);
    }
    // console.log("returnCancelPassengers", temp);
    setReturnCancelPassengers(temp);
  };

  let passengerReturn = [];
  returnCancelPassengers.length > 0 &&
    returnCancelPassengers.map((value, index) => {
      let returnPassanger = {
        paxUId: value.uid,
        journeyType: ticket.journeyType,
        flightUId: ticket.returnFlightDetails.details[0].uid,
      };
      passengerReturn.push(returnPassanger);
    });

  let passenger = [];
  onwardCancelPassengers.length > 0 &&
    onwardCancelPassengers.map((value, index) => {
      let temp = {
        paxUId: value.uid,
        journeyType: ticket.journeyType,
        flightUId: ticket.onwardFlightDetails.details[0].uid,
      };
      passenger.push(temp);
    });
  // console.log("passender array", passenger);

  const cancelRequest = () => {
    apiCall(
      WebApi.b2CFlightCancellation,
      {
        passenger,
        remark: cancellationType,
        tid: ticket.tid,
        transType: 1,
        typeOfCancellation: value,
      },
      (response) => {
        // console.log(response);
        if (response.succes) {
          apiCall(
            WebApi.getB2CFlightList,
            { userId: 1, type: "completed" },
            (response) => {
              props.setB2CFlightList(response.data);
              // console.log("itetete");
            }
          );
        }
        setIsError(!response.success);
        setIsSuccess(response.success);

        setIsSaving(false);
        setInfoText(response.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
        setTimeout(() => {
          props.history.goBack();
        }, 3000);
      }
    );
    // setIsCancelled(true)
  };

  const cancelRequestReturn = () => {
    let passenger = passengerReturn;
    apiCall(
      WebApi.b2CFlightCancellation,
      {
        passenger,
        remark: cancellationTypeReturn,
        tid: ticket.tid,
        transType: 1,
        typeOfCancellation: valueReturn,
      },
      (response) => {
        // console.log(response);
        if (response.succes) {
          apiCall(
            WebApi.getB2CFlightList,
            { userId: 1, type: "completed" },
            (response) => {
              props.setB2CFlightList(response.data);
              // console.log("itetete");
            }
          );
        }
        setIsError(!response.success);
        setIsSuccess(response.success);

        setIsSaving(false);
        setInfoText(response.message);

        window.scrollTo({ top: 0, behavior: "smooth" });

        setTimeout(() => {
          props.history.goBack();
        }, 3000);
      }
    );
    // setIsCancelled(true)
  };

  // console.log("passengerDetails",ticket.passengerDetails);
  // let userType = "Registered";
  

  let userType = props?.location?.state?.userType;
  

  return (
    <div className={classes.root}>
        <Grid container spacing={isMobile ? 0 : 2} justifyContent="center">
        {/* {userType != "GUEST" && userType != "agent" && userType != "direct_agent" &&  */}

        {/* {userType === "R" && (
          <>
            <Grid item md={3} xs={12} style={{ padding: 0 }}>
              <Menu />
              <div style={{ marginLeft: 15, marginRight: 15, marginTop: 10 }}>
                <Profile />
              </div>
            </Grid>
          </>
        )} */}

        {/* } */}

        <Grid item md={8} xs={12} style={{ padding: isMobile && 15 }}>
          {/* <Grid
            container
            item
            md={12}
            xs={12}
            sm={12}
            style={{
              background: "#fff",
              border: `1px solid ${theme.palette.primary.disabled}`,
            // border: "1px solid #eee",
              borderRadius: "5px",
              boxShadow: `0px 0px 6px -1px ${theme.palette.primary.disabled}`,
            }}
          >
            <Grid item md={12}>
              <OaFormAlerts
                isSaving={isSaving}
                isSuccess={isSuccess}
                isError={isError}
                infoText={infoText}
                setIsError={setIsError}
                setIsSuccess={setIsSuccess}
              />
            </Grid>
         
          </Grid> */}

          {/* {ticket.cancellationDetails.length > 0 && (
            <Grid
              container
              style={{
                marginTop: "15px",
                border: `1px solid ${theme.palette.primary.disabled}`,
                borderRadius: "5px",
                boxShadow: `0px 0px 6px -1px ${theme.palette.primary.disabled}`,
                paddingTop: "12px",
                borderTop: `3px solid ${theme.palette.primary.main}`,
                padding: 15,
              }}
            >
              <Grid item xs={12}>
                <Typography className={classes.heading2}>
                  Cancellation Request Details
                </Typography>
              </Grid>
              <Grid container item xs={12}>
                {ticket.cancellationDetails.map((cancel, index) => (
                  <MuiAccordion
                    key={index}
                    square
                    expanded={expanded}
                    onChange={() => setExpanded(!expanded)}
                  >
                    <MuiAccordionSummary>
                      <Grid item xs={12}>
                        <a className={classes.aTag}>
                          <table cellSpacing={0} className={classes.table}>
                            <thead>
                              <tr>
                                <th>ID - {cancel.canId}</th>
                                <th>Status - {cancel.canStatus}</th>
                                <th>Refund - {cancel.refundStatus}</th>
                                <th>
                                  {expanded ? (
                                    <ExpandLess />
                                  ) : (
                                    <ExpandMoreIcon />
                                  )}
                                </th>
                              </tr>
                            </thead>
                          </table>
                        </a>
                      </Grid>
                    </MuiAccordionSummary>
                    <MuiAccordionDetails>
                      <Grid container>
                        <Grid item xs={12}>
                          <table
                            cellSpacing={0}
                            cellPadding={5}
                            className={classes.table}
                          >
                            <tbody>
                              <tr className={classes.tableRow}>
                                <td
                                  className={classes.tableCell3}
                                  style={{ width: "30%" }}
                                >
                                  Passenger Name
                                </td>
                                <td className={classes.tableCell3}>Flight</td>
                                <td className={classes.tableCell3}>
                                  Ticket No
                                </td>
                              </tr>
                              {cancel.cancellationPassenger.map(
                                (passenger, secIndex) => (
                                  <tr key={secIndex}>
                                    <td
                                      className={classes.tableCell3}
                                      style={{ width: "30%" }}
                                    >
                                      {`${secIndex + 1}. ${
                                        passenger.passengerName
                                      }`}
                                    </td>
                                    <td className={classes.tableCell3}>
                                      {passenger.flightNo}
                                    </td>
                                    <td className={classes.tableCell3}>
                                      {passenger.eticketNo}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </Grid>
                        <Grid item xs={12}>
                          <table
                            cellSpacing={0}
                            cellPadding={5}
                            className={classes.table}
                          >
                            <tbody>
                              <tr className={classes.tableRow}>
                                <td
                                  className={classes.tableCell3}
                                  style={{ width: "30%" }}
                                >
                                  Cancel Status : {cancel.canStatus}
                                </td>
                                <td colSpan="2" className={classes.tableCell3}>
                                  Cancelation Request DateTime :{" "}
                                  {moment(cancel.canReqDatetime).format(
                                    "DD MMM'YY"
                                  )}
                                </td>
                              </tr>
                              <tr className={classes.tableRow}>
                                <td
                                  className={classes.tableCell3}
                                  style={{ width: "30%" }}
                                >
                                  Refund Status : {cancel.refundStatus}
                                </td>
                                <td className={classes.tableCell3}>
                                  Refund Request DateTime :{" "}
                                  {cancel.refundDatetime}
                                </td>
                                <td className={classes.tableCell3}>
                                  Refund Amount: Rs{" "}
                                  {Number(cancel.refundAmount).toFixed(2)}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  className={classes.tableCell3}
                                  style={{
                                    textAlign: "left",
                                    paddingLeft: "1.5em",
                                  }}
                                  colSpan="3"
                                >
                                  Remarks : {cancel.canReqRemark}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Grid>
                      </Grid>
                    </MuiAccordionDetails>
                  </MuiAccordion>
                ))}
              </Grid>
            </Grid>
          )} */}
    
        </Grid>
      </Grid>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    flightList: state.flightList.flightList,
  };
}
export default withRouter(connect(mapStateToProps)(Itinerary));
