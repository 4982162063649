import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import ScrollToTopComp from "components/LandingPage/BuzzHolidays/ScrollToTopComp";
import HomePage from "components/LandingPage/BuzzHolidays/HomePage";
import AboutUs from "components/LandingPage/BuzzHolidays/siteinfo/AboutUsPage";
import ContactUs from "components/LandingPage/BuzzHolidays/siteinfo/ContactUsPage";
import TermsAndConditions from "components/LandingPage/BuzzHolidays/siteinfo/TermsAndConditions";
import PrivacyPolicy from "components/LandingPage/BuzzHolidays/siteinfo/PrivacyPolicy";
import { Route, Switch } from "react-router-dom";
import { FooterSection } from "components/LandingPage/BuzzHolidays/FooterSection";
import LandingPageBase from "components/LandingPage/BuzzHolidays/LandingPageBase";
import HomePageNavbar from "components/LandingPage/BuzzHolidays/HomePageNavbar";
import WebCheckIn from "pages/module/flight/webCheckIn";
import AirlineContact from "pages/module/flight/airlineContact";
import HomePageNavbarMobile from "components/LandingPage/BuzzHolidays/HomePageNavbarMobile";
import PostLoginNavBar from "components/Navbars/PostLoginNavBar.BuzzHolidays";
import CancellationPolicy from "components/LandingPage/BuzzHolidays/siteinfo/CancellationPolicy";

const useStyles = makeStyles(styles);

const width = window.innerWidth;
const breakpoint = 980;

export default function Pages(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);

  // console.log(window.location.href.split("/"));
  const conditions = [
    "termsandconditions",
    "cancellationVisaPolicy",
    "aboutus",
    "contactus",
    "privacypolicy",
    "webcheckin",
    "airlinecontacts",
  ];
  const test1 = conditions.some((el) =>
    window.location.href.split("/").includes(el)
  );
  console.log(test1);

  return (
    <div className={classes.wrapper}>
      {width < 980 ? (
        <HomePageNavbarMobile />
      ) : test1 ? (
        <PostLoginNavBar isloading={isLoading} />
      ) : (
        <HomePageNavbar />
      )}

      <Switch>
        <Route path="/aboutus" component={AboutUs} />
        <Route path="/contactus" component={ContactUs} />
        <Route path="/termsandconditions" component={TermsAndConditions} />
        <Route path="/cancellationVisaPolicy" component={CancellationPolicy} />
        <Route path="/privacypolicy" component={PrivacyPolicy} />
        <Route path="/webcheckin" component={WebCheckIn} />
        <Route path="/airlinecontacts" component={AirlineContact} />
        <Route component={LandingPageBase} />
      </Switch>
      <FooterSection />
      <ScrollToTopComp />
    </div>
  );
}
