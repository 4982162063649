import React from "react";
import HomePage from "components/LandingPage/BuzzHolidays/HomePage";
import PlanYourTripMobile from "components/LandingPage/BuzzHolidays/HomePageMobile";

const LandingPageBase = (props) => {
  const width = window.innerWidth;
  const breakpoint = 980;

  return width < breakpoint ? <PlanYourTripMobile /> : <HomePage />;
};

export default LandingPageBase;
