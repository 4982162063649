import React from "react";
import { useHistory, withRouter } from "react-router-dom";
import image1 from "assets/rive/flightanimation.riv";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import dateFnsFormat from "date-fns/format";
import WebApi from "api/ApiConstants";
import {
  setTimerInitialMinute,
  setTimerInitialSecond,
  setFlightBookingInitialTime,
} from "redux/action";
import { connect } from "react-redux";
import Rive from "rive-react";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import OaFormAlerts from "pages/components/OaFormAlerts";
import { localforageGetItem } from "oautils/oaForageUtils";
import{ AppConstant }from "appConstant"
// import demoResponse from "pages/module/flight/JSON_SAMPLE_RESPONSES/domestic/dom_oneway_avail_response.json";
// import demoResponse from "pages/module/flight/JSON_SAMPLE_RESPONSES/domestic/dome_round_avail_response.json";
// import demoResponse from "pages/module/flight/JSON_SAMPLE_RESPONSES/international/int_flight_oneway_avail.json";

const FlightSearchProgress = (props) => {
  const classes = useStyles();
  const [isSaving, setIsSaving] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [infoText, setInfoText] = React.useState("");
  const [size, setSize] = React.useState(0);

  const [isLoading, setIsLoading] = React.useState(true);
  const theme = useTheme();
  const isMdDev = useMediaQuery(theme.breakpoints.down(992));
  const history = useHistory();

  const params = {};
  params.adultPax = history.location.state.adultPax;
  params.childPax = history.location.state.childPax;
  params.infantPax = history.location.state.infantPax;
  params.clientType = history.location.state.clientType;
  params.mode = history.location.state.mode;
  params.preferredAirline = history.location.state.preferredAirline;
  params.preferredclass = history.location.state.preferredclass;
  params.returnDate = history.location.state.returnDate;
  params.partnerId = history.location.state.partnerId;
  params.isNonStop = history.location.state.isNonStop;
  params.fareType = history.location.state.fareType;

  params.sectors = [];

  if (history.location.state.mode == "MULTICITY") {
    params.mode = "ONE";

    history.location.state.sectors.map((val) => {
      params.sectors.push({
        departDate: val.departDate,
        destination: val.destination,
        origin: val.origin,
      });
    });
  } else {
    params.sectors.push({
      departDate: history.location.state.sectors[0].departDate,
      destination: history.location.state.sectors[0].destination,
      origin: history.location.state.sectors[0].origin,
    });
  }

  let displayParams = {
    retArrDate: history.location.state.returnDate,
    mode: history.location.state.mode,
  };

  const redirectToSearchEngine = () => {
    setTimeout(() => {
      history.push("/flights");
    }, 1500);
  };

  React.useEffect(() => {
    const fetching = window.sessionStorage.getItem("fetching");
    if (fetching) {
      setSize(window.innerWidth);

      let isDomestic = true;

      localforageGetItem("user-details", function(err, details) {
        if (details != null) {
          const userType = details.userType;
          const corporateType = details.corporateType;
          params.partnerId = details.partnerId;
          params.clientType = "Normal";

          switch (details.userTypeAbv) {
            case "S": {
              params.clientType = "Corporate";
              break;
            }

            case "G": {
              params.clientType = "Normal";
              break;
            }

            default: {
              params.clientType = "Normal";
              break;
            }
          }

          history.location.state &&
            history.location.state.sectors.map((val) => {
              if (
                val.destinationObj?.countryCode !== undefined &&
                val.originObj?.countryCode !== undefined
              ) {
                // console.log(val.destinationObj.countryCode);
                if (
                  val.destinationObj.countryCode != "IN" ||
                  val.originObj.countryCode != "IN"
                ) {
                  isDomestic = false;
                }
              }
            });
          const historyState = { ...history.location.state };
          historyState.isDomestic = isDomestic;

          // return history.push({
          //   pathname: "/flight/search-result",
          //   // state: { ...response, requestObj: history.location.state }
          //   state: { ...demoResponse, requestObj: history.location.state }
          // });

          // return history.push({
          //   pathname: "/flight/international/search-result",
          //   state: { ...demoResponse, requestObj: history.location.state },
          // });
          // console.log(details.userTypeAbv, "USERTYPE 2525")
          if((details.userTypeAbv === "G" || details.userTypeAbv === "A") && AppConstant.B2BOnly === true ){
            history.push({
              pathname: "/",
        
              //state: demoResponse,
            });
            return
          }
          WebApi.searchFlight(
            params,
            (response) => {
              // if (response.data === null || response.success == "false") {
              //   history.push({
              //     pathname: "/flight/search-result",
              //     state: {
              //       ...response,
              //       requestObj: historyState,
              //     },
              //     //state: demoResponse,
              //   });
              // }
              
              props.setTimerInitialMinute(0);
              props.setTimerInitialSecond(0);
              props.setFlightBookingInitialTime(
                new Date().getTime() + 15 * 60 * 1000
              );
              window.sessionStorage.setItem("service", "flight");

              if (isDomestic && historyState.mode != "MULTICITY") {
                history.push({
                  pathname: "/flight/search-result",
                  state: {
                    ...response,
                    requestObj: historyState,
                  },
                  //state: demoResponse,
                });
              } else {
                /* if (response.combineResp.length > 0) {
                    history.push({
                      pathname: "/flight/international/search-result",
                      state: {
                        ...response,
                        requestObj: historyState,
                      },
                    });
                  } else {
                    setInfoText("No results found!");
                    setIsError(true);
                    redirectToSearchEngine();
                  } */
                history.push({
                  pathname: "/flight/international/search-result",
                  state: {
                    ...response,
                    requestObj: historyState,
                  },
                });
              }
              // } else if (response.status === 400) {
              //   setInfoText(response.status + " : " + response.message);
              //   setIsSuccess(false);
              //   setIsError(true);
              //   redirectToSearchEngine();
              // } else {

              //   setInfoText("Request Failed");
              //   setIsSuccess(false);
              //   setIsError(true);
              //   redirectToSearchEngine();
              // }
            },
            (error) => {
              console.log(error);
              history.push({
                pathname: "/flight/search-result",
                state: {
                  // ...response,
                  requestObj: historyState,
                },
                //state: demoResponse,
              });
              return;
              if (history.location.pathname === "/flight/search-progress") {
                setInfoText("internal error! please contact admin");
                setIsError(true);
                redirectToSearchEngine();
              }

              // console.log("internal error", error);
            }
          );
        } else {
          history.push("/logout");
        }
      });
    } else {
      history.push("/");
    }
  }, [isLoading]);
  const origin = history.location.state.sectors[0].originObj.cityName;
  const destination =
    history.location.state.sectors[history.location.state.sectors.length - 1]
      .destinationObj.cityName;
  const fareType = history.location.state.fareType;
  const departure = history.location.state.sectors[0].departDate;
  const arrival = history.location.state.returnDate;
  const adult = history.location.state.adultPax;
  const child = history.location.state.childPax;
  const infant = history.location.state.infantPax;
  const classType = history.location.state.preferredclass;
  const mode = history.location.state.mode;

  //   return (
  //     <div className={classes.root}>
  //       <div className="alert-div">
  //         <OaFormAlerts
  //           isSaving={isSaving}
  //           isSuccess={isSuccess}
  //           isError={isError}
  //           infoText={infoText}
  //           setIsError={setIsError}
  //           setIsSuccess={setIsSuccess}
  //         />
  //       </div>

  //       <div className="itin-info-div">
  //         <GridContainer
  //           style={{
  //             width: "100%",
  //           }}
  //         >
  //           <GridItem
  //             md={12}
  //             style={{
  //               height: "20vh",
  //               width: "100%",
  //               display: "flex",
  //               justifyContent: "center",
  //               alignItems: "center",
  //               backgroundColor: "transparent",
  //             }}
  //           >
  //             {/* <Rive src={image1} /> */}
  //             <img src={props.availoader} style={{ height: "8rem" }} />
  //           </GridItem>

  //           <GridItem
  //             style={{
  //               height: "10vh",
  //               width: "100%",
  //               display: "flex",
  //               justifyContent: "center",
  //               alignItems: "center",
  //               backgroundColor: "transparent",
  //             }}
  //           >
  //             <GridContainer>
  //               <GridItem
  //                 md={12}
  //                 xs={12}
  //                 align={"center"}
  //                 // flexDirection={size < 950 ? "row" : "column"}
  //                 style={{
  //                   paddingTop: 0,
  //                   flexDirection: "row",
  //                   display: "flex",
  //                   alignItems: "center",
  //                   justifyContent: "center",
  //                 }}
  //                 className={"destination"}
  //               >
  //                 Please Wait.....
  //                 <br />
  //                 While we're searching for the best available fare for you.
  //               </GridItem>
  //             </GridContainer>
  //           </GridItem>
  //           {history.location.state.sectors.map((val, index) => (
  //             <GridItem
  //               key={index}
  //               style={{
  //                 height: "20vh",
  //                 width: "100%",
  //                 display: "flex",
  //                 justifyContent: "center",
  //                 alignItems: "center",
  //                 backgroundColor: "transparent",
  //               }}
  //             >
  //               <GridContainer>
  //                 <GridItem
  //                   md={4}
  //                   xs={12}
  //                   align={size < 950 ? "center" : "right"}
  //                   // flexDirection={size < 950 ? "row" : "column"}
  //                   style={{
  //                     paddingTop: theme.breakpoints.down(550) ? 0 : 25,
  //                     flexDirection: theme.breakpoints.down(950)
  //                       ? "row"
  //                       : "column",
  //                     display: "flex",
  //                     alignItems: "center",
  //                     justifyContent: "center",
  //                   }}
  //                   className={"destination"}
  //                 >
  //                   {val.originObj.cityName} ({val.originObj.airportCode})
  //                 </GridItem>
  //                 <GridItem md={4} xs={12} align="center">
  //                   <div
  //                     style={{
  //                       color: theme.palette.primary.darkText,
  //                       marginBottom: 15,
  //                     }}
  //                   >
  //                     <span>
  //                       Departure:{" "}
  //                       {dateFnsFormat(new Date(val.departDate), "do MMM yyyy")}
  //                     </span>
  //                   </div>
  //                   <div className="arrow-container">
  //                     <span className="arrow-right"></span>
  //                   </div>
  //                   {displayParams.mode === "ROUND" ? (
  //                     <div
  //                       style={{
  //                         color: theme.palette.primary.darkText,
  //                         marginTop: 15,
  //                       }}
  //                     >
  //                       <span>
  //                         Arrival:{" "}
  //                         {dateFnsFormat(
  //                           new Date(displayParams.retArrDate),
  //                           "do MMM yyyy"
  //                         )}
  //                       </span>
  //                     </div>
  //                   ) : (
  //                     <div></div>
  //                   )}
  //                 </GridItem>
  //                 <GridItem
  //                   md={4}
  //                   xs={12}
  //                   align={size < 950 ? "center" : "left"}
  //                   // flexDirection={size < 950 ? "row" : "column"}
  //                   style={{
  //                     paddingTop: theme.breakpoints.down(550) ? 0 : 25,
  //                     flexDirection: theme.breakpoints.down(950)
  //                       ? "row"
  //                       : "column",
  //                     display: "flex",
  //                     alignItems: "center",
  //                     justifyContent: "center",
  //                   }}
  //                 >
  //                   {val.destinationObj.cityName} (
  //                   {val.destinationObj.airportCode})
  //                 </GridItem>
  //               </GridContainer>
  //             </GridItem>
  //           ))}
  //         </GridContainer>
  //       </div>
  //     </div>
  //   );
  // };
  return (
    <div className={classes.baseContainer}>
      <GridContainer className={classes.root}>
        <GridItem md={12}>
          <OaFormAlerts
            isSaving={isSaving}
            isSuccess={isSuccess}
            isError={isError}
            infoText={infoText}
            setIsError={setIsError}
            setIsSuccess={setIsSuccess}
          />
        </GridItem>
        {/* <GridItem
          md={12}
          className="section-row justify-content-center align-items-flex-end wt-100p"
        >
          <Rive src={image1} width="315" height="165" />
        </GridItem> */}
        <GridItem
          md={12}
          className="section-row justify-content-center align-items-flex-end wt-100p avail-row"
        >
          {/* <Rive src={image1} /> */}
          <img src={props.availoader} className="avail-loader" />
        </GridItem>

        <GridItem className="section-row justify-content-center align-items-center wt-100p">
          <GridContainer>
            <GridItem
              md={12}
              xs={12}
              align={"center"}
              // flexDirection={size < 950 ? "row" : "column"}
              style={{
                paddingTop: 0,
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className={"destination"}
            >
              Please Wait.....
              <br />
              While we're searching for the best Flight Deals for you.
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem
          md={12}
          className="section-row justify-content-center align-items-center wt-100p"
        >
          <GridContainer className="section-row justify-content-center align-items-center wt-100p">
            {/* <GridItem md={4} align={isMobile ? "center" : "right"} xs={12}>
            Checkin:{" "}
            {dateFnsFormat(
              new Date(
                props.hotelSearch.checkInDate.split("/")[2],
                props.hotelSearch.checkInDate.split("/")[1] - 1,
                props.hotelSearch.checkInDate.split("/")[0]
              ),
              "do MMM yyyy"
            )}
          </GridItem> */}
            <GridItem md={4} align="center" xs={12}>
              {/* <div className="arrow-container" style={{ marginTop: 10 }}>
              <span className="arrow-right"></span>
            </div> */}
              <div className="journey-type-container" >
                <span className="journey-type-span">
                  Type:{" "}
                  {mode === "ONE"
                    ? "One Way"
                    : mode === "ROUND"
                    ? "Round Trip"
                    : mode === "MULTICITY"
                    ? "Multicity"
                    : ""}
                </span>
              </div>
            </GridItem>
            {/* <GridItem md={4} xs={12} align={isMobile ? "center" : "left"}>
            Checkout:{" "}
            {dateFnsFormat(
              new Date(
                props.hotelSearch.checkOutDate.split("/")[2],
                props.hotelSearch.checkOutDate.split("/")[1] - 1,
                props.hotelSearch.checkOutDate.split("/")[0]
              ),
              "do MMM yyyy"
            )}
          </GridItem> */}
          </GridContainer>
        </GridItem>
        {/* {props.hotelSearch.departureCityId} {props.hotelSearch.destinationCityId} */}
        <GridItem
          md={12}
          className="section-row align-items-flex-start wt-100p itin-grid"
        >
          <div className="section-row itin-wrapper flex-wrap wt-100p">
            <div className="section-row itin-item wt-50p">
              <span className="itin-label">Origin:</span>
              <span className="itin-content">{origin}</span>
            </div>
            <div className="section-row itin-item wt-50p">
              <span className="itin-label">Destination:</span>
              <span className="itin-content">{destination}</span>
            </div>

            <div className="section-row itin-item wt-50p">
              <span className="itin-label">Departure:</span>
              <span className="itin-content">
                {dateFnsFormat(new Date(departure), "do MMM yyyy")}
              </span>
            </div>
            <div className="section-row itin-item wt-50p">
              <span className="itin-label">FareType:</span>
              <span className="itin-content">
                {/* {dateFnsFormat(new Date(departure), "do MMM yyyy")} */}
                {fareType}
              </span>
            </div>
            {mode === "ROUND" && (
              <div className="section-row itin-item wt-50p">
                <span className="itin-label">Arrival:</span>
                <span className="itin-content">
                  {dateFnsFormat(new Date(arrival), "do MMM yyyy")}
                </span>
              </div>
            )}

            <div className="section-row itin-item wt-50p">
              <span className="itin-label">Passenger(s):</span>
              <span className="itin-content">
                {adult} Adult(s)
                {child > 0 &&
                  `${child === 1 ? ", 1 Child" : ", " + child + " Children"}`}
                {infant > 0 && `, ${infant} Infant(s)`}
              </span>
            </div>
            <div className="section-row itin-item wt-50p">
              <span className="itin-label">Class:</span>
              <span className="itin-content">
                {classType === "E"
                  ? "Economy"
                  : classType === "ER"
                  ? "Premium Economy"
                  : classType === "B"
                  ? "Business"
                  : ""}
              </span>
            </div>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};
function mapStateToProps(state, props) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setTimerInitialMinute: (timerInitialMinute) =>
      dispatch(setTimerInitialMinute(timerInitialMinute)),
    setTimerInitialSecond: (timerInitialSecond) =>
      dispatch(setTimerInitialSecond(timerInitialSecond)),
    setFlightBookingInitialTime: (flightBookingInitialTime) =>
      dispatch(setFlightBookingInitialTime(flightBookingInitialTime)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlightSearchProgress);

const useStyles = makeStyles((theme) => ({
  // root: {
  //   padding: "0 35px 14px 35px",
  //   "& .alert-div": {
  //     marginTop: 75,
  //     padding: "0 8vw",
  //     backgroundColor: theme.palette.background.default,
  //     color: theme.palette.background.defaultContrast,
  //   },
  //   "& .itin-info-div": {
  //     backgroundColor: theme.palette.background.default,
  //     color: theme.palette.background.defaultContrast,
  //     minHeight: "70vh",
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "center",
  //   },
  // },
  baseContainer: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: 60,
    width: "100%",
    // height: "70vh",
    minHeight: "100%",
    maxWidth: 1440,
    padding: "10px 20px 20px 20px",
  },
  root: {
    // height: "60vh",
    minHeight: "100%",
    maxWidth: 768,
    boxShadow: `0px 0px 3px 0px rgba(107,107,107,0.75)`,
    marginTop: 30,
    "& .section-row": {
      display: "flex",
      backgroundColor: "transparent",
    },
    "& .justify-content-center": {
      justifyContent: "center",
    },
    "& .align-items-flex-end": {
      alignItems: "flex-end",
      [theme.breakpoints.down(960)]: {
        alignItems: "center"
      }
    },
    "& .align-items-flex-start": {
      alignItems: "flex-start",
    },
    "& .align-items-center": {
      alignItems: "center",
    },
    "& .ht-20-vh": {
      height: "20vh",
    },
    "& .ht-10-vh": {
      height: "10vh",
    },
    "& .wt-100p": {
      width: "100%",
    },
    "& .wt-50p": {
      width: "50%",
      [theme.breakpoints.down(520)]: {
        width: "100%",
      },
    },
    "& .flex-wrap": {
      flexWrap: "wrap",
    },
    "& .itin-grid": {
      background: "#e2e6f2",
      height: 80,
      paddingTop: 10,
      [theme.breakpoints.down(520)]: {
        height: "auto",
        paddingBottom: 10
      },
    },
    "& .itin-wrapper": {
      [theme.breakpoints.down(960)]: {
        margin: "0 15px",
      },
    },
    "& .itin-label": {
      width: "35%",
      fontWeight: 600,
    },
    "& .itin-content": {
      width: "65%",
      fontWeight: 600,
    },
    "& .journey-type-container": {
      color: theme.palette.secondary.grayDove,
      padding: "10px 0",
    },
    "& .journey-type-span": {
      fontWeight: 600, 
      fontSize: 18,
    },
    "& .avail-loader": {
      height: "15rem",
      [theme.breakpoints.down(520)]: {
        height: "10rem"
      }
    },
    "& .avail-row": {
      padding: "10px 0"
    }
  },
}));
