import { makeStyles } from "@material-ui/core";
import React from "react";
import Carousel, { consts } from "react-elastic-carousel";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { SERVER_URL } from "api/ApiConstants";

const PopularRoutes = ({ data }) => {
  const classes = useStyles();
  const history = useHistory();
  const width = window.innerWidth;

  function HotelDetail(name, packageType, index, advertise, packageId) {
    if (advertise === true) {
      history.push("/packages/packageinfo", { data: packageId });
    } else {
      history.push({
        pathname: "/packages/",
        state: { name, packageType, index },
      });
    }
    // setSecLogo(true);
  }

  const CarouselContainer = ({ pack }) => {
    const [hoverDescription, setHoverDescription] = useState(false);
    const [hoverTitle, setHoverTitle] = useState(false);

    return (
      <div
        className="carousel"
        onClick={() =>
          HotelDetail(
            pack.packageCategoryId,
            pack.packageCategoryType,
            1,
            true,
            pack.packageId
          )
        }
      >
        <div className="carousel-subContainer">
          <img
            //  src={topDestinationPlace}
            src={
                SERVER_URL +
              // "http://oari.co:8080/" +
              "packageadmin/ImageReaderArticle?path=/" +
              pack.packageCategoryType +
              "/" +
              pack.packageCategoryId +
              "/" +
              pack.packageId +
              "/" +
              pack.packageCategoryImage
            }
            className="carouselImage"
          />
          <div
            onMouseEnter={() => setHoverTitle(true)}
            onMouseLeave={() => setHoverTitle(false)}
            className="carouselHeader"
          >
            {pack.packageCategoryName}
          </div>
          {hoverTitle && (
            <div className="packageDestinationHoverTitle">
              {pack.packageCategoryName.replaceAll("&#45;", ", ")}
            </div>
          )}
          <div className="packageDestinationPrice">
            <span className="popularRoutePriceText">
              ₹ {pack.packageCategoryStartingPrice?.toLocaleString("en-IN")}
            </span>
            Per Person
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className="headerContainer">
        <div className="topHeader">
          Popular Destinations <br className="break" /> in the world
        </div>
        <div className="carouselHeaderContainer">
          <div className="carouselHeaderText">
            A destination is a bundle package consisting of various tourism
            facilities and services which, like any other service product,
            consists of a number of multidimensional attributes that together
            determine their attractiveness to certain individuals in certain
            preferred situations.
          </div>
          <div className="exploreBtn">Explore</div>
        </div>
      </div>

      <div className="carouselContainer">
      {data?.packagesCategories && data?.packagesCategories.length > 0 && <Carousel
          itemsToShow={width > 1350 ? 3 : width < 550 ? 1 : 2}
          itemPadding={[0, 10, 0, 0]}
          // pagination={false}

          // renderArrow={CustomArrow}
        >
          {data?.packagesCategories.map((pack) => (
            <CarouselContainer pack={pack} />
          ))}
        </Carousel>}
      </div>
    </div>
  );
};

export default PopularRoutes;

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 50,
    marginBottom: 20,
    maxWidth: 1280,
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.down(1280)]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    [theme.breakpoints.down(900)]: {
      padding: "0px 20px",
      paddingTop: 30,
    },
    "& .popularRoutePriceText": {
      color: "#FF7A00",
      fontWeight: 500,
      marginRight: 5,
      fontSize: 20,
    },
    "& .exploreBtn": {
      background: theme.palette.secondary.main,
      width: 100,
      height: 40,
      borderRadius: 20,
      marginTop: 4,
      color: theme.palette.primary.contrastText,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .rec-dot": {
      backgroundColor: "#9F9F9F",
      boxShadow: "none",
      opacity: "30%",
    },
    "& .rec-dot_active": {
      boxShadow: "0 0 1px 3px #9F9F9F",
      opacity: "100%",
    },
    "& .rec-arrow": {
      display: "none",
    },
    // height: 430,
    // backgroundColor: "#e9e9e9",
    // width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .headerContainer": {
      display: "flex",
      [theme.breakpoints.down(900)]: {
        flexDirection: "column",
        alignItems: "center",
      },
    },
    "& .carousel": {
      // width: "26%",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",

      "& .carousel-subContainer": {
        [theme.breakpoints.down(1250)]: {
          width: 250,
        },
        [theme.breakpoints.down(560)]: {
          width: 220,
        },
      },
    },
    "& .carouselContainer": {
      marginTop: 30,
      display: "flex",
      justifyContent: "space-between",
      maxWidth: 1280,
      width: "100%",
      marginBottom: 40,
    },
    "& .carouselImage": {
      height: 200,
      width: 330,
      borderRadius: 80,
      [theme.breakpoints.down(1250)]: {
        width: 250,
      },
      [theme.breakpoints.down(560)]: {
        width: 220,
        height: 180,
      },
    },
    "& .carouselHeaderContainer": {
      width: "65%",
      [theme.breakpoints.down(900)]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      },
      [theme.breakpoints.down(550)]: {
        width: "100%",
      },
    },
    "& .carouselHeaderText": {
      marginTop: 20,
      // width: "65%",
      fontSize: 19,
      [theme.breakpoints.down(550)]: {
        marginTop: 5,
        textAlign: "center",
      },
    },
    "& .carouselHeader": {
      fontSize: 20,
      fontWeight: 700,
      fontFamily: "TimesNewRoman",
      marginTop: 10,
      marginBottom: 5,
      height: 40,
      width: 330,
      overflow: "hidden",
      textAlign: "center",
      [theme.breakpoints.down(1250)]: {
        width: "unset",
      },

      // marginBottom: 30,
    },
    "& .topHeader": {
      fontSize: 55,
      fontWeight: 700,
      fontFamily: "TimesNewRoman",
      marginBottom: 20,
      width: "35%",
      "& .break": {
        [theme.breakpoints.down(900)]: {
          display: "none",
        },
      },
      [theme.breakpoints.down(900)]: {
        width: 600,
        fontSize: 35,
        textAlign: "center",
      },
      [theme.breakpoints.down(550)]: {
        fontSize: 30,
        textAlign: "center",
        width: "100%",
        lineHeight: 0.9,
      },
    },
    "& .hightlight": {
      color: "#FF0000",
      marginRight: 10,
    },
    "& .packageDestinationHoverTitle": {
      position: "absolute",
      backgroundColor: theme.palette.primary.lightText,
      fontSize: 12,
      padding: 6,
      borderRadius: 5,
      border: `1px solid ${theme.palette.primary.contrastText}`,
      zIndex: 1000,
      bottom: 20,
      left: 45,
      // boxShadow: "0 0 1px 3px #ff0000",

      // height: "100%",
    },
    "& .packageDestinationPrice": {
      textAlign: "center",
    },
  },
}));
