import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Grid,
  makeStyles,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  IconButton,
  FormControl,
  Button,
  FormHelperText,
  ListItemText,
  Checkbox,
  OutlinedInput,
  Popper,
  useTheme,
} from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { AiOutlineSwap } from "react-icons/ai";

import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import { connect } from "react-redux";
import {
  setDomesticCarriers,
  setInternationalCarriers,
  setAirports,
  setFlightBookingInitialTime,
} from "redux/action";
import { withRouter } from "react-router";
import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import { localforageGetItem } from "oautils/oaForageUtils";

import * as Yup from "yup";
import OaFormAlerts from "pages/components/OaFormAlerts";
import _ from "lodash";
import OaOutlinedDatePicker from "oahoc/OaOutlinedDatepicker";
import OaOutlinedAutoComplete from "oahoc/OaOutlinedAutoComplete";
import dateFnsFormat from "date-fns/format";
import { useHistory } from "react-router";
import PassengerCount from "components/CommonSearchComponents/Flight/PassengerCounter";
// import FlightLocationInput from "../Tickat/forms/formControls/FlightLocationInput"
import FlightLocationInput from "components/CommonSearchComponents/Flight/FlightLocationInput"
import FlightDateInput from "components/CommonSearchComponents/Flight/FlightDateInput"
import { createFilterOptions } from "@material-ui/lab/Autocomplete";

import { Close } from "@material-ui/icons";
import { allAirlines } from "api/ApiConstants";

const MobileFlightSection = (props) => {
  const arriveAutocompleteEl = useRef(null);
  const isMounted = React.useRef(false);
  const history = useHistory();
  const formikRef = React.useRef();
  const theme = useTheme();
  const paxDropDownRef = React.useRef(null);

  const classes = useStyles();
  const [dropdownTraveler, setDropdownTraveler] = useState(false);
  const [dropdownClass, setDropdownClass] = useState(false);
  const [partnerId, setPartnerId] = React.useState(null);
  const [clientType, setClientType] = React.useState(null);
  const [switchedRound, setSwitchedRound] = React.useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [fromCountry, setFromCountry] = useState("");
  const [toCountry, setToCountry] = useState("");
  const [carrier, setCarrier] = useState(props.domCarriers);
  const [userType, setUserType] = useState(null);
  const [corporateType, setCorporateType] = useState(null);
  const [airportList, setAirportList] = useState(popularCities);
  const [tripMode, setTripMode] = useState("ONE");
  const isModifying = props?.isModifying;

  let totalTravelers = 0;
  const modifyPassengerCount = (type, val) => {
    if (formikRef.current) {
      switch (type) {
        case "adult": {
          formikRef.current.setFieldValue(
            "adultPax",
            Number(formikRef.current.values.adultPax) + val
          );
          break;
        }

        case "child": {
          formikRef.current.setFieldValue(
            "childPax",
            Number(formikRef.current.values.childPax) + val
          );
          break;
        }

        case "infant": {
          formikRef.current.setFieldValue(
            "infantPax",
            Number(formikRef.current.values.infantPax) + val
          );
          break;
        }
      }
    }
  };

  // const filterOptions = createFilterOptions({
  //   stringify: (option) => option.cityName,
  // });
  const filterOptions = createFilterOptions({
    stringify: (option) => option.cityName + option.airportCode,
  });

  const openQuicklink = (quicklinktype) => {
    switch (quicklinktype) {
      case "flights-report": {
        props.history.push("/admin/booking-report/flight");
        break;
      }

      case "travel-calendar": {
        window.openPage("travel-calendar");
        break;
      }

      case "cancellation": {
        props.history.push("/admin/booking-report/flight");
        break;
      }

      case "group-booking": {
        props.history.push("/admin/groupbooking");
        break;
      }

      case "airlines-contact": {
        // window.openPage("airlines-contact");
        props.history.push("/airlinecontacts");
        break;
      }

      case "web-checkin": {
        // window.openPage("web-checkin");
        props.history.push("/webcheckin");
        break;
      }
    }
  };

  const getAirlineData = () => {
    if (
      props.domCarriers === undefined ||
      props.intlCarriers === undefined ||
      props.airports === undefined
    ) {
      apiCall(WebApi.getAirports, {}, (response) => {
        if (response.success === true) {
          props.setAirports(response.data.airports);
          props.setDomesticCarriers(response.data.domCarriers);
          props.setInternationalCarriers(response.data.intlCarriers);
          setCarrier(response.data.domCarriers);
          setIsLoading(false);
        } else {
          setIsError(true);
        }
      });
    }
  };

  const fetchAirports = (searchParams) => {
    if (searchParams.length >= 3) {
      apiCall(
        WebApi.fetchAirportList,
        {
          airportCode: searchParams,
        },
        (response) => {
          // console.log("response", response);

          if (response.success == true) {
            setAirportList(response.data.airports);
          }
        }
      );
    }
  };

  function changeHandler(fromTo, countryCode) {
    if (fromTo === "from") {
      setFromCountry(countryCode ? countryCode : "IN");
    } else if (fromTo === "to") {
      setToCountry(countryCode ? countryCode : "IN");
    }
  }

  const submitSearchForm = (event) => {
    // event.preventDefault();
    if (formikRef.current) {
      formikRef.current.handleSubmit();
    }
  };

  const handleSubmitTravelData = (values) => {
    values.clientType = clientType;
    values.partnerId = partnerId;
    window.sessionStorage.removeItem("service");
    window.sessionStorage.setItem("fetching", true);
    props.setFlightBookingInitialTime(null);

    history.push({
      pathname: "/flight/search-progress",
      state: values,
    });
  };

  const initialParams = props?.initialvalues || {
    adultPax: 1,
    childPax: 0,
    infantPax: 0,
    mode: "ONE",
    preferredAirline: [allAirlines],
    preferredclass: "E",
    fareType: "Regular",
    returnDate: dateFnsFormat(new Date(), "yyyy-MM-dd"),
    returnDateDisplay: new Date(),
    sectors: [
      {
        departDate: dateFnsFormat(new Date(), "yyyy-MM-dd"),
        departDateDisplay: new Date(),
        destination: popularCities[1].airportCode,
        destinationObj: popularCities[1],
        origin: popularCities[0].airportCode,
        originObj: popularCities[0],
      },
    ],
  };

  const valSchema = Yup.object().shape({
    preferredclass: Yup.string().required("Mandatory"),
    adultPax: Yup.number().required(),
    childPax: Yup.number().required(),
    infantPax: Yup.number().required(),
    sectors: Yup.array().of(
      Yup.object().shape({
        destination: Yup.string().required("Mandatory"),
        origin: Yup.string().required("Mandatory"),
      })
    ),
  });

  const addMultiStopInp = (values, arrayHelpers) => {
    let index = values.sectors.length - 1;
    if (index < 3) {
      arrayHelpers.push({
        departDate: dateFnsFormat(
          values.sectors[index].departDateDisplay,
          "yyyy-MM-dd"
        ),
        departDateDisplay: values.sectors[index].departDateDisplay,
        destination: values.sectors[index].origin,
        destinationObj: values.sectors[index].originObj,
        origin: values.sectors[index].destination,
        originObj: values.sectors[index].destinationObj,
      });
    }
  };

  const validateSectorsForNonMulticity = () => {
    if (formikRef.current) {
      if (
        formikRef.current.values.mode !== "MULTICITY" &&
        formikRef.current.values.sectors.length > 1
      ) {
        formikRef.current.setFieldValue(
          `sectors`,
          formikRef.current.values.sectors.splice(1)
        );
      }
    }
  };

  const onChangeMode = (e) => {
    if (formikRef.current) {
      formikRef.current.setFieldValue(`mode`, e.target.value);
    }
    if (e.target.value === "ROUND") {
      setSwitchedRound(true);
    }
    setTripMode(e.target.value);
  };

  const toggleReturn = () => {
    if (formikRef.current) {
      //console.log("formikRef.current.values.mode",formikRef.current.values.mode)
      switch (formikRef.current.values.mode) {
        case "ONE": {
          formikRef.current.setFieldValue("mode", "ROUND");
          setSwitchedRound(true);
          setTripMode("ROUND");
          break;
        }
        case "ROUND": {
          formikRef.current.setFieldValue("mode", "ONE");
          setTripMode("ONE");
          break;
        }
        case "ROUND-SP": {
          formikRef.current.setFieldValue("mode", "ONE");
          setTripMode("ONE");
          break;
        }
      }
    }
  };

  const outOfPaxDdContexArea = (event) => {
    if (
      paxDropDownRef.current &&
      !paxDropDownRef.current.contains(event.target)
    ) {
      setDropdownTraveler(false);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    document.addEventListener("mousedown", outOfPaxDdContexArea);
    return () => {
      document.removeEventListener("mousedown", outOfPaxDdContexArea);
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getAirlineData();
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      if (fromCountry === "IN" && toCountry === "IN") {
        setCarrier(props.domCarriers);
      } else {
        setCarrier(props.intlCarriers);
      }
    }
  }, [fromCountry, toCountry]);

  useEffect(() => {
    localforageGetItem("user-details", function (err, details) {
      if (isMounted.current && details) {
        setUserType(details.userType);
        setCorporateType(details.corporateType);
        setPartnerId(details.partnerId);

        switch (details.userTypeAbv) {
          case "S": {
            setClientType("Corporate");
            break;
          }
          case "G": {
            setClientType("Normal");
            break;
          }
          default: {
            setClientType("Normal");
            break;
          }
        }
      }
    });
  }, [isMounted.current]);

  React.useEffect(() => {
    validateSectorsForNonMulticity();
  }, [tripMode]);

  return (
    <Box className={classes.root} m={4}>
      {/* oaFormAlert  */}
      {/* -----------  */}
      <OaFormAlerts
        isSaving={isSaving}
        isSuccess={isSuccess}
        isError={isError}
        infoText={infoText}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
      />
      <Formik
        innerRef={formikRef}
        initialValues={initialParams}
        validationSchema={valSchema}
        onSubmit={(values) => handleSubmitTravelData(values)}
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => (
          <Form className={classes.FlightSectionFormContainer}>
            {/* -----------  */}
            {/* oaFormAlert  */}

            <Grid container className={classes.FlightSectionContainer}>
              <Grid
                container
                justifyContent="space-between"
                className={classes.rowUpper}
              >
                <Grid item xs={12}>
                  <Box className={classes.FlightSection_flightway}>
                    <RadioGroup
                      className={classes.FlightSection_radiogroup}
                      value={values.mode}
                      name={`mode`}
                      onChange={onChangeMode}
                    >
                      {flightWayList.map((a) => (
                        <FormControlLabel
                          key={a.val}
                          value={a.val}
                          control={<Radio />}
                          label={a.text}
                        />
                      ))}
                    </RadioGroup>
                    <ErrorMessage
                      name="flightWay"
                      component="div"
                      className="error"
                    />
                  </Box>
                </Grid>
              </Grid>
              <FieldArray
                name="sectors"
                render={(arrayHelpers) => (
                  <div
                    className="flight-info-wrapper"
                    style={{ width: "100%", padding: "0 0px" }}
                  >
                    {values.sectors &&
                      values.sectors.map((sector, index) => {
                        return (
                          <Grid
                            key={index}
                            className={classes.flightInfoGridRoot}
                          >
                            {values.mode === "MULTICITY" ? (
                              <div className="multi-city-div">
                                <Grid container>
                                  <Grid item xs={4}>
                                    {/* <OaOutlinedAutoComplete
                                      className="autocomplete"
                                      filterOptions={filterOptions}
                                      labeltxt="FROM"
                                      name={`sectors[${index}].originObj`}
                                      PopperComponent={customPopperTo}
                                      options={airportList || []}
                                      getOptionLabel={(o) =>
                                        o ? `${o.airportCode}` : ""
                                      }
                                      value={
                                        values.sectors[index]?.originObj || ""
                                      }
                                      openOnFocus
                                      getOptionSelected={(option, value) => {
                                        return (
                                          option.airportCode ==
                                          value.airportCode
                                        );
                                      }}
                                      onKeyUp={(event) =>
                                        fetchAirports(event.target.value.trim())
                                      }
                                      onChange={(event, newValue, reason) => {
                                        if (reason === "clear") {
                                          setAirportList(popularCities);
                                        } else {
                                          changeHandler(
                                            "from",
                                            newValue?.countryCode
                                          );
                                          setFieldValue(
                                            `sectors[${index}].originObj`,
                                            newValue
                                          );
                                          setFieldValue(
                                            `sectors[${index}].origin`,
                                            newValue.airportCode
                                          );
                                        }
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          color="secondary"
                                          inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password",
                                          }}
                                          placeholder="Departure city"
                                        />
                                      )}
                                      renderOption={(optionsData, propes) =>
                                        renderOptionsComp(optionsData, propes)
                                      }
                                      disablePortal={true}
                                      secondarytxt={
                                        values.sectors[index].originObj &&
                                        ` ${values.sectors[index].originObj.cityName}`
                                      }
                                      inputtext={
                                        values.sectors[index].originObj
                                          ?.airportCode
                                      }
                                    /> */}
                                                                 <div className="flight-input-label">FROM</div>
                                     <FlightLocationInput props={{props, values, setFieldValue}} index={index} type={"origin"} />
                                    <ErrorMessage
                                      name={`sectors[${index}].originObj`}
                                      component="div"
                                      className="error"
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                  <div className="flight-input-label">TO</div>
                                  <FlightLocationInput props={{props, values, setFieldValue}} index={index} type={"destination"} />
                                    {/* <OaOutlinedAutoComplete
                                      className="autocomplete"
                                      filterOptions={filterOptions}
                                      name={`sectors[${index}].destinationObj`}
                                      PopperComponent={customPopperTo}
                                      options={airportList || []}
                                      getOptionLabel={(o) =>
                                        o ? `${o.airportCode}` : ""
                                      }
                                      value={
                                        values.sectors[index].destinationObj ||
                                        ""
                                      }
                                      openOnFocus
                                      getOptionSelected={(option, value) => {
                                        return (
                                          option.airportCode ==
                                          value.airportCode
                                        );
                                      }}
                                      onKeyUp={(event) =>
                                        fetchAirports(event.target.value.trim())
                                      }
                                      onChange={(event, newValue, reason) => {
                                        if (reason === "clear") {
                                          setAirportList(popularCities);
                                        } else {
                                          changeHandler(
                                            "from",
                                            newValue?.countryCode
                                          );
                                          setFieldValue(
                                            `sectors[${index}].destinationObj`,
                                            newValue
                                          );
                                          setFieldValue(
                                            `sectors[${index}].destination`,
                                            newValue.airportCode
                                          );
                                        }
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          color="secondary"
                                          inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password",
                                          }}
                                        />
                                      )}
                                      renderOption={(optionsData, propes) =>
                                        renderOptionsComp(optionsData, propes)
                                      }
                                      disablePortal={true}
                                      secondarytxt={
                                        values.sectors[index].destinationObj &&
                                        ` ${values.sectors[index].destinationObj.cityName}`
                                      }
                                      labeltxt="TO"
                                      inputtext={
                                        values.sectors[index].destinationObj
                                          ?.airportCode
                                      }
                                    /> */}

                                    <ErrorMessage
                                      name={`sectorArray[${index}].toAirport`}
                                      component="div"
                                      className="error"
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className="left-dashed-item"
                                  >
                                    <OaOutlinedDatePicker
                                      showDisabledMonthNavigation
                                      name={`sectors[${index}].onwardDateDisplay`}
                                      minDate={
                                        // values.sectors[index].departDateDisplay
                                        index == 0
                                          ? new Date()
                                          : values.sectors[index - 1]
                                            .departDateDisplay
                                      }
                                      customInput={
                                        <TextField
                                          id="standard-basic"
                                          variant="standard"
                                          fullWidth={true}
                                        />
                                      }
                                      selected={
                                        values.sectors[index].departDateDisplay
                                      }
                                      onChange={(e, v) => {
                                        setFieldValue(
                                          `sectors[${index}].departDateDisplay`,
                                          e
                                        );
                                        setFieldValue(
                                          `sectors[${index}].departDate`,
                                          dateFnsFormat(e, "yyyy-MM-dd")
                                        );
                                        setFieldValue(`returnDateDisplay`, e);
                                        setFieldValue(
                                          `returnDate`,
                                          dateFnsFormat(e, "yyyy-MM-dd")
                                        );
                                      }}
                                      monthsShown={1}
                                      dateFormat="dd MMM yyyy"
                                      labeltxt="DEPARTURE DATE"
                                      secondarytxt={null}
                                      // secondarytxt={
                                      //   days[
                                      //     values.sectors[
                                      //       index
                                      //     ].departDateDisplay.getDay()
                                      //   ]
                                      // }
                                      showinput={true}
                                    />
                                    <ErrorMessage
                                      name={`sectorArray[${index}].onwardDateDisplay`}
                                      component="div"
                                      className="error"
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            ) : (
                              <>
                                <div className="tofrom">
                                  <div className="from">
                                    <Grid container>
                                      <Grid item xs={12}>
                                        {/* <OaOutlinedAutoComplete
                                          className="autocomplete"
                                          filterOptions={filterOptions}
                                          name={`sectors[${0}].originObj`}
                                          PopperComponent={customPopperTo}
                                          options={airportList || []}
                                          getOptionLabel={(o) =>
                                            o ? `${o.airportCode}` : ""
                                          }
                                          value={
                                            values.sectors[0]?.originObj || ""
                                          }
                                          openOnFocus
                                          getOptionSelected={(
                                            option,
                                            value
                                          ) => {
                                            return (
                                              option.airportCode ==
                                              value.airportCode
                                            );
                                          }}
                                          onKeyUp={(event) =>
                                            fetchAirports(
                                              event.target.value.trim()
                                            )
                                          }
                                          onChange={(
                                            event,
                                            newValue,
                                            reason
                                          ) => {
                                            if (reason === "clear") {
                                              setAirportList(popularCities);
                                            } else {
                                              changeHandler(
                                                "from",
                                                newValue?.countryCode
                                              );
                                              setFieldValue(
                                                `sectors[0].originObj`,
                                                newValue
                                              );
                                              setFieldValue(
                                                `sectors[0].origin`,
                                                newValue.airportCode
                                              );
                                            }
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              fullWidth
                                              color="secondary"
                                              inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "new-password",
                                              }}
                                              placeholder="Departure city"
                                            />
                                          )}
                                          renderOption={(optionsData, propes) =>
                                            renderOptionsComp(
                                              optionsData,
                                              propes
                                            )
                                          }
                                          disablePortal={true}
                                          secondarytxt={
                                            values.sectors[0].originObj &&
                                            `${values.sectors[0].originObj.cityName}`
                                          }
                                          labeltxt="FROM"
                                          inputtext={
                                            values.sectors[0].originObj
                                              ?.airportCode
                                          }
                                        /> */}
                                          <div className="flight-input-label">FROM</div>
                                          <FlightLocationInput props={{values, setFieldValue}} index={0} type={"origin"} />

                                        <ErrorMessage
                                          name={`sectorArray[${index}].fromAirport`}
                                          component="div"
                                          className="error"
                                        />
                                      </Grid>
                                    </Grid>
                                  </div>

                                  <div className="swiperIconBtn">
                                    <Grid item xs={12} className="btn_div">
                                      <IconButton
                                        onClick={() => {
                                          const originTemp = {
                                            str: values.sectors[index].origin,
                                            obj:
                                              values.sectors[index].originObj,
                                          };
                                          setFieldValue(
                                            `sectors[${index}].originObj`,
                                            values.sectors[0].destinationObj
                                          );
                                          setFieldValue(
                                            `sectors[${index}].origin`,
                                            values.sectors[0].destination
                                          );
                                          setFieldValue(
                                            `sectors[${index}].destinationObj`,
                                            originTemp.obj
                                          );
                                          setFieldValue(
                                            `sectors[${index}].destination`,
                                            originTemp.str
                                          );
                                        }}
                                        aria-label="delete"
                                        className={classes.swapIconButton}
                                      >
                                        <AiOutlineSwap />
                                      </IconButton>
                                    </Grid>
                                  </div>

                                  <div className="to">
                                    <Grid item xs={12}>
                                      {/* <OaOutlinedAutoComplete
                                        className="autocomplete"
                                        filterOptions={filterOptions}
                                        name={`sectors[${index}].destinationObj`}
                                        PopperComponent={customPopperTo}
                                        options={airportList || []}
                                        getOptionLabel={(o) =>
                                          o ? `${o.airportCode}` : ""
                                        }
                                        value={
                                          values.sectors[0].destinationObj || ""
                                        }
                                        openOnFocus
                                        getOptionSelected={(option, value) => {
                                          return (
                                            option.airportCode ==
                                            value.airportCode
                                          );
                                        }}
                                        onKeyUp={(event) =>
                                          fetchAirports(
                                            event.target.value.trim()
                                          )
                                        }
                                        onChange={(event, newValue, reason) => {
                                          if (reason === "clear") {
                                            setAirportList(popularCities);
                                          } else {
                                            changeHandler(
                                              "from",
                                              newValue?.countryCode
                                            );
                                            setFieldValue(
                                              `sectors[0].destinationObj`,
                                              newValue
                                            );
                                            setFieldValue(
                                              `sectors[0].destination`,
                                              newValue.airportCode
                                            );
                                          }
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            fullWidth
                                            style={{
                                              paddingLeft: "10px !important",
                                            }}
                                            color="secondary"
                                            inputRef={arriveAutocompleteEl}
                                            placeholder="Arrival city"
                                            inputProps={{
                                              ...params.inputProps,
                                              autoComplete: "new-password",
                                            }}
                                          />
                                        )}
                                        renderOption={(optionsData, propes) =>
                                          renderOptionsComp(optionsData, propes)
                                        }
                                        disablePortal={true}
                                        secondarytxt={
                                          values.sectors[0].destinationObj &&
                                          `${values.sectors[0].destinationObj.cityName}`
                                        }
                                        inputtext={
                                          values.sectors[0].destinationObj
                                            ?.airportCode
                                        }
                                        labeltxt="TO"
                                      /> */}

                                    <div className="flight-input-label">TO</div>
                                    <FlightLocationInput props={{values, setFieldValue}} index={0} type={"destination"} />

                                      <ErrorMessage
                                        name={`sectorArray[${index}].toAirport`}
                                        component="div"
                                        className="error"
                                      />
                                    </Grid>
                                  </div>
                                </div>
                                <div className="date">
                                  <div className="departure">
                                    <Grid
                                      item
                                      xs={12}
                                      className="left-dashed-item"
                                    >
                                      {/* <OaOutlinedDatePicker
                                        showDisabledMonthNavigation
                                        name={`sectors[${index}].departDateDisplay`}
                                        minDate={
                                          // values.sectors[index]
                                          //   .departDateDisplay
                                          new Date()
                                        }
                                        customInput={
                                          <TextField
                                            id="standard-basic"
                                            variant="standard"
                                            fullWidth={true}
                                          />
                                        }
                                        selected={
                                          values.sectors[index]
                                            .departDateDisplay
                                        }
                                        onChange={(e, v) => {
                                          setFieldValue(
                                            `sectors[0].departDateDisplay`,
                                            e
                                          );
                                          setFieldValue(
                                            `sectors[0].departDate`,
                                            dateFnsFormat(e, "yyyy-MM-dd")
                                          );
                                          setFieldValue(`returnDateDisplay`, e);
                                          setFieldValue(
                                            `returnDate`,
                                            dateFnsFormat(e, "yyyy-MM-dd")
                                          );
                                        }}
                                        monthsShown={1}
                                        dateFormat="dd MMM yyyy"
                                        labeltxt="DEPARTURE DATE"
                                        secondarytxt=""
                                        // secondarytxt={
                                        //   days[
                                        //     values.sectors[
                                        //       index
                                        //     ].departDateDisplay.getDay()
                                        //   ]
                                        // }
                                        showinput={true}
                                      /> */}
                                         <div className="flight-date-label">Departure Date</div>
                                       <FlightDateInput  props={{values, setFieldValue}} index={0} type={"depart"} />
                                      <ErrorMessage
                                        name={`sectorArray[${index}].onwardDateDisplay`}
                                        component="div"
                                        className="error"
                                      />
                                    </Grid>
                                  </div>

                                  <div className="return">
                                    <Grid
                                      item
                                      xs={12}
                                      className="left-dashed-item"
                                    >
                                      {/* <OaOutlinedDatePicker
                                        showDisabledMonthNavigation
                                        name={`returnDateDisplay`}
                                        className="date-picker"
                                        // minDate={
                                        // values.sectorArray[0]
                                        //     .returnDateDisplay
                                        // }
                                        minDate={
                                          values.sectors[index]
                                            .departDateDisplay
                                        }
                                        customInput={
                                          <TextField
                                            id="standard-basic"
                                            variant="standard"
                                            fullWidth={true}
                                          />
                                        }
                                        selected={values.returnDateDisplay}
                                        // value={values.sectorArray[0]
                                        //   .returnDateDisplay}

                                        onChange={(e, v) => {
                                          setFieldValue(`returnDateDisplay`, e);
                                          setFieldValue(
                                            `returnDate`,
                                            dateFnsFormat(e, "yyyy-MM-dd")
                                          );
                                        }}
                                        monthsShown={1}
                                        dateFormat="dd MMM yyyy"
                                        labeltxt="RETURN DATE"
                                        secondarytxt=""
                                        // secondarytxt={
                                        //   values.mode == "ROUND" ||
                                        //   values.mode == "ROUND-SP"
                                        //     ? days[
                                        //         values.returnDateDisplay.getDay()
                                        //       ]
                                        //     : ""
                                        // }
                                        showinput={false}
                                        disabledtxt={null}
                                      // showinput={true}
                                      /> */}
                                      <div className="flight-date-label">Return Date</div>
                                      <FlightDateInput  props={{values, setFieldValue}} index={0} type={"return"} />
                                      <ErrorMessage
                                        name={`returnDateDisplay`}
                                        component="div"
                                        className="error"
                                      />
                                      {switchedRound && (
                                        <span
                                          className="overlay-close-btn"
                                          onClick={toggleReturn}
                                        >
                                          <Close />
                                        </span>
                                      )}
                                      {values.mode === "ONE" && (
                                        <div
                                          className="return-date-overlay"
                                          onClick={toggleReturn}
                                        ></div>
                                      )}
                                    </Grid>
                                  </div>
                                </div>
                              </>
                            )}
                          </Grid>
                        );
                      })}

                    <div
                      style={{
                        width: "100%",
                        paddingBottom: "1rem",
                        backgroundColor: "#fff",
                        marginTop: "-1px",
                      }}
                    >
                      {/* Add remove btn multi city */}
                      {values.mode === "MULTICITY" && (
                        <Grid container className="multicity-action-container">
                          <Grid item xs={12}>
                            {values.mode === "MULTICITY" &&
                              values.sectors.length < 4 && (
                                <Button
                                  style={{
                                    color: theme.palette.background.darkDefault,
                                    width: 130,
                                    marginTop: 10,
                                    marginRight: 10,
                                    fontSize: "11px",
                                    borderRadius: "16px",
                                    // background: theme.palette.background.whiteGreen,
                                    background:
                                      theme.palette.background.mobileSearch,
                                    // border: `1.3px solid ${theme.palette.background.lightYellowGreen}`,
                                    border: `solid 1.3px ${theme.palette.background.mobileSearchBorder}`,
                                  }}
                                  position="right"
                                  // onClick={() => {
                                  //   arrayHelpers.push({
                                  //     fromAirport: "",
                                  //     toAirport: "",
                                  //     onwardDateDisplay: new Date(),
                                  //   });
                                  // }}
                                  onClick={() =>
                                    addMultiStopInp(values, arrayHelpers)
                                  }
                                >
                                  + Add Sector
                                </Button>
                              )}

                            {values.mode === "MULTICITY" &&
                              values.sectors.length > 1 && (
                                <Button
                                  style={{
                                    border: `solid 1.3px ${theme.palette.background.lightYellowGreen}`,
                                    color: theme.palette.primary.darkText,
                                    width: 130,
                                    marginTop: 10,
                                    fontSize: "11px",
                                    borderRadius: "16px",
                                  }}
                                  position="right"
                                  onClick={() => {
                                    arrayHelpers.pop();
                                  }}
                                >
                                  - Remove Sector
                                </Button>
                              )}
                          </Grid>
                        </Grid>
                      )}

                      {/* traveler and class */}
                      <div className={classes.traveler__box}>
                        <div>
                          <Grid
                            item
                            sm={12}
                            className="passenger-add-container"
                          >
                            <div
                              className="passenger_div"
                              onClick={() =>
                                setDropdownTraveler(!dropdownTraveler)
                              }
                            >
                              <p className="trvl"> TRAVELLER</p>
                              <p className="trvl-count">
                                <span className="trval-tnum">
                                  {
                                    (totalTravelers =
                                      Number(values.adultPax) +
                                      Number(values.childPax) +
                                      Number(values.infantPax))
                                  }
                                </span>
                                <span className="trval-txt">Traveller</span>{" "}
                                <i className="fas fa-chevron-down"></i>
                              </p>
                            </div>

                            {dropdownTraveler && (
                              <div
                                className="passenger_div-show"
                                ref={paxDropDownRef}
                              >
                                <Grid
                                  item
                                  sm={12}
                                  className={classes.FlightSection_passdet}
                                >
                                  <Grid
                                    className={classes.gridRespColPassDet}
                                    container
                                    direction="column"
                                    justifyContent="space-evenly"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    <Box className="counter-box">
                                      <PassengerCount
                                        title="Adults"
                                        type="adult"
                                        val={values.adultPax}
                                        setVal={modifyPassengerCount}
                                        name="adultPax"
                                        totalPax={
                                          values.adultPax +
                                          values.childPax +
                                          values.infantPax
                                        }
                                      />
                                      <ErrorMessage
                                        name="noAdult"
                                        component="div"
                                        className="error"
                                      />
                                    </Box>
                                    <Box className="counter-box">
                                      <PassengerCount
                                        title="Children"
                                        type="child"
                                        val={values.childPax}
                                        setVal={modifyPassengerCount}
                                        name="childPax"
                                        totalPax={
                                          values.adultPax +
                                          values.childPax +
                                          values.infantPax
                                        }
                                      />
                                      <ErrorMessage
                                        name="childPax"
                                        component="div"
                                        className="error"
                                      />
                                    </Box>
                                    <Box className="counter-box">
                                      <PassengerCount
                                        title="Infants"
                                        type="infant"
                                        val={values.infantPax}
                                        setVal={modifyPassengerCount}
                                        name="infantPax"
                                        totalPax={
                                          values.adultPax +
                                          values.childPax +
                                          values.infantPax
                                        }
                                      />
                                      <ErrorMessage
                                        name="noInfant"
                                        component="div"
                                        className="error"
                                      />
                                    </Box>
                                    <Box>
                                      <Button
                                        onClick={() =>
                                          setDropdownTraveler(false)
                                        }
                                      >
                                        Done
                                      </Button>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </div>
                            )}
                          </Grid>
                        </div>
                        <div className="gap_div"></div>
                        {/* class Section */}

                        <div>
                          <Grid item sm={12} className="class-add-container">
                            <div
                              className="class_div"
                              onClick={() => setDropdownClass(!dropdownClass)}
                            >
                              <p>Class</p>
                              <p className="trvl-class">
                                {values.preferredclass === "E"
                                  ? "Economy"
                                  : values.preferredclass === "ER"
                                    ? "Premium Economy"
                                    : values.preferredclass === "B"
                                      ? "Business"
                                      : ""}
                              </p>
                            </div>

                            {dropdownClass && (
                              <div className="class_div-show">
                                <Grid
                                  item
                                  sm={12}
                                  className={classes.FlightSection_passdet}
                                >
                                  <div className="cabinType-dev">
                                    <p className="cabin-title">Select Class:</p>
                                    <FormControl
                                      component="fieldset"
                                      name="isNonStop"
                                    >
                                      <RadioGroup
                                        aria-label="preferredclass"
                                        value={values.preferredclass}
                                        name="preferredclass"
                                        onChange={(e, v) =>
                                          v &&
                                          setFieldValue("preferredclass", v)
                                        }
                                      >
                                        <FormControlLabel
                                          value="E"
                                          control={<Radio />}
                                          label="Economy"
                                        />
                                        <FormControlLabel
                                          value="ER"
                                          control={<Radio />}
                                          label="Premium Economy"
                                        />
                                        <FormControlLabel
                                          value="B"
                                          control={<Radio />}
                                          label="Business"
                                        />
                                      </RadioGroup>
                                      <ErrorMessage
                                        name="cabinType"
                                        component="div"
                                        className="error"
                                      />
                                    </FormControl>
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  sm={12}
                                  className={classes.FlightSection_passdet}
                                >
                                  <div className="cabinType-dev">
                                    <p className="cabin-title">Select Fare:</p>
                                    <FormControl
                                      component="fieldset"
                                      name="isNonStop"
                                    >
                                      <RadioGroup
                                        aria-label="preferredclass"
                                        value={values.fareType}
                                        name="fareType"
                                        onChange={(e, v) =>
                                          v && setFieldValue("fareType", v)
                                        }
                                      >
                                        <FormControlLabel
                                          value="Regular"
                                          control={<Radio />}
                                          label="Regular"
                                        />
                                        <FormControlLabel
                                          value="Student"
                                          control={<Radio />}
                                          label="Student"
                                        />
                                        <FormControlLabel
                                          value="SeniorCitizen"
                                          control={<Radio />}
                                          label="SeniorCitizen"
                                        />
                                      </RadioGroup>
                                      <ErrorMessage
                                        name="cabinType"
                                        component="div"
                                        className="error"
                                      />
                                    </FormControl>
                                    <Button
                                      onClick={() =>
                                        setDropdownClass(!dropdownClass)
                                      }
                                    >
                                      Done
                                    </Button>
                                  </div>
                                </Grid>
                              </div>
                            )}
                          </Grid>
                        </div>
                      </div>
                      {/* Search */}
                      <Grid
                        item
                        sm={12}
                        className={classes.FlightSection_searchBtn}
                      >
                        <Grid
                          className={classes.gridRespCol}
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid item className="search-btn-col">
                            <Box className={classes.searchButtonBox}>
                              <Button onClick={submitSearchForm}>Search</Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                )}
              />

              {/* quick link */}
              {!isModifying && (
                <Grid container className={classes.rowBottom}>
                  <Grid container className="quick-links-container">
                    {userType && userType == "agent" && (
                      <Grid
                        item
                        className="quick-link-item"
                        onClick={() => openQuicklink("flights-report")}
                      >
                        <div className="qlink-icon-wrapper">
                          <i className="fas fa-plane"></i>
                        </div>
                        <div className="qlink-label-wrapper">
                          FLIGHTS REPORT
                        </div>
                      </Grid>
                    )}

                    {userType && userType == "agent" && (
                      <Grid
                        item
                        className="quick-link-item"
                        onClick={() => openQuicklink("travel-calendar")}
                      >
                        <div className="qlink-icon-wrapper">
                          <i className="fas fa-calendar"></i>
                        </div>
                        <div className="qlink-label-wrapper">
                          TRAVEL CALENDER
                        </div>
                      </Grid>
                    )}

                    {userType && userType == "agent" && (
                      <Grid
                        item
                        className="quick-link-item"
                        onClick={() => openQuicklink("cancellation")}
                      >
                        <div className="qlink-icon-wrapper">
                          <i className="fas fa-ban"></i>
                        </div>
                        <div className="qlink-label-wrapper">CANCELLATION</div>
                      </Grid>
                    )}

                    <Grid
                      item
                      className="quick-link-item"
                      onClick={() => openQuicklink("web-checkin")}
                    >
                      <div className="qlink-icon-wrapper">
                        <i className="fas fa-globe"></i>
                      </div>
                      <div className="qlink-label-wrapper">WEB CHECK IN</div>
                    </Grid>

                    <Grid
                      item
                      className="quick-link-item"
                      onClick={() => openQuicklink("airlines-contact")}
                    >
                      <div className="qlink-icon-wrapper">
                        <i className="fas fa-phone"></i>
                      </div>
                      <div className="qlink-label-wrapper">
                        AIRLINES CONTACT
                      </div>
                    </Grid>
                    {userType && userType == "agent" && (
                      <Grid
                        item
                        className="quick-link-item"
                        onClick={() => openQuicklink("group-booking")}
                      >
                        <div className="qlink-icon-wrapper">
                          <i className="fas fa-users"></i>
                        </div>
                        <div className="qlink-label-wrapper">GROUP BOOKING</div>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

const mapPropsToState = (state, props) => {
  return {
    domCarriers: state.flight.domCarriers,
    intlCarriers: state.flight.intlCarriers,
    airports: state.flight.airports,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDomesticCarriers: (domCarriers) =>
      dispatch(setDomesticCarriers(domCarriers)),
    setInternationalCarriers: (intlCarriers) =>
      dispatch(setInternationalCarriers(intlCarriers)),
    setAirports: (airports) => dispatch(setAirports(airports)),
    setFlightBookingInitialTime: (flightBookingInitialTime) =>
      dispatch(setFlightBookingInitialTime(flightBookingInitialTime)),
  };
};

export default withRouter(
  connect(
    mapPropsToState,
    mapDispatchToProps
  )(MobileFlightSection)
);

const customPopperFrom = function (props) {
  // console.log("props", props);
  return <Popper {...props} placement="bottom" style={{}} />;
};

const customPopperTo = function (props) {
  // console.log("props", props);
  return (
    <Popper
      {...props}
      // anchorEl={arriveAutocompleteEl.current.children[0]}
      placement="bottom"
      style={{}}
    />
  );
};

const renderOptionsComp = (optionsData, propes) => {
  return (
    <li selected={propes.selected} className="ac-options-list">
      <span>
        <p className="ac-option-left">
          {optionsData.cityName} ({optionsData.airportCode})
        </p>
        <p className="ac-option-right">{optionsData.countryCode}</p>
      </span>
      <p>{optionsData.airportDesc}</p>
    </li>
  );
};

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const flightWayList = [
  { val: "ONE", text: "One Way" },
  { val: "ROUND", text: "Round Trip" },
  // { val: "ROUNDSP", text: "Round Trip Special" },
  { val: "MULTICITY", text: "Multi City" },
];

const popularCities = [
  {
    airportId: 5669,
    cityName: "Mumbai",
    airportCode: "BOM",
    countryCode: "IN",
    citySearch: "MUMBAI,BOMBAY",
    airportDesc: "Chhatrapati Shivaji International Airport",
  },
  {
    airportId: 5877,
    cityName: "New Delhi",
    airportCode: "DEL",
    countryCode: "IN",
    citySearch: "NEW DELHI,DELHI",
    airportDesc: "Indira Gandhi International Airport",
  },
  {
    airportId: 682,
    cityName: "Bangkok",
    airportCode: "BKK",
    countryCode: "TH",
    citySearch: "",
    airportDesc: "Bangkok",
  },
  {
    airportId: 679,
    cityName: "Bangalore",
    airportCode: "BLR",
    countryCode: "IN",
    citySearch: "BANGALORE,BANGLURU,BANGLORE,BENGALURU",
    airportDesc: "Bengaluru International Airport",
  },
  {
    airportId: 6926,
    cityName: "Pune",
    airportCode: "PNQ",
    countryCode: "IN",
    citySearch: "PUNE,PUNA",
    airportDesc: "Pune Airport",
  },
  {
    airportId: 3454,
    cityName: "Hyderabad",
    airportCode: "HYD",
    countryCode: "IN",
    citySearch: "HYDERABAD,HYDRABAD",
    airportDesc: "Rajiv Gandhi International Airport",
  },
  {
    airportId: 4229,
    cityName: "Kolkata",
    airportCode: "CCU",
    countryCode: "IN",
    citySearch: "KOLKATA,CALCUTTA,CALCUTA",
    airportDesc: "Netaji Subhash Chandra Bose International Airport",
  },
  {
    airportId: 1585,
    cityName: "Chennai",
    airportCode: "MAA",
    countryCode: "IN",
    citySearch: "CHENNAI,MADRAS",
    airportDesc: "Chennai International Airport",
  },
  {
    airportId: 2935,
    cityName: "Goa",
    airportCode: "GOI",
    countryCode: "IN",
    citySearch: "GOA",
    airportDesc: "Dabolim Goa International Airport",
  },
  {
    airportId: 2257,
    cityName: "Dubai",
    airportCode: "DXB",
    countryCode: "AE",
    citySearch: "",
    airportDesc: "Dubai International",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    "& .flight-location-input": {
    background: "transparent",
    height: 25,
    paddingTop: 0,
    paddingBottom: 0
  
    },
    "& .multi-city-div": {
      "& .flight-location-input": {
        background: "transparent",
        height: 30,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft:0,
        paddingRight:5,
        fontSize: 14,
        fontWeight: 500,
        },
        "& .flight-input-label": {
          paddingLeft:0,

          // fontSize: 14,
          // fontWeight: 500,
        },

    },
    "& .date-input-container": {
      paddingTop: 0,
      paddingBottom: 0,
      height: 25,
      "& .MuiInputBase-input": {
        fontWeight: 400,
        fontSize: "14px !important"
      }
      },
    
    "& .clear-icon":{
      // display: none;
            top: 4
    },
    "& .flight-input-label": {
      paddingLeft:10,
      fontSize: 14,
      fontWeight: 500,
    },
    "& .flight-date-label": {
      paddingLeft:10,
      fontSize: 14,
      fontWeight: 500,
      textTransform: "uppercase"
    },

    "& .MuiFormLabel-root": {
      color: theme.palette.primary.darkText,
    },
    "& .MuiInputBase-input": {
      color: theme.palette.primary.defaultText,
    },
    "& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover, .MuiInput-underline:focus": {
      borderBottom: "none",
    },
    "& .multicity-action-container": {
      marginBottom: "23px",
      padding: "0 0px",
    },
    "& .ac-options-list": {
      overflow: "hidden",
      width: "100%",
      "& p": {
        marginBottom: 0,
        color: theme.palette.primary.darkFadedText,
        whiteSpace: "nowrap",
      },
      "& span": {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        "& .ac-option-left": {
          textAlign: "left",
          color: theme.palette.primary.darkText,
          fontWeight: 600,
          flexGrow: 1,
        },
        "& .ac-option-right": {
          textAlign: "right",
          color: theme.palette.primary.darkFadedText,
        },
      },
    },
    "& .webcheckin": {
      display: "flex",
      margin: "0.5rem 0rem",
      gap: "0.5rem",
      fontSize: "14px",
      fontWeight: 300,
      color: " #fff",
      float: " right",
      border: "2px solid #c2d87e",
      padding: " 5px 10px",
      borderRadius: "3px",
      background: "rgba(255,255,255,0.13)",
    },
    "& .left-dashed-item": {
      "& .oa-form-element-card": {
        border: "none",
        padding: 0,
        "& .MuiInputBase-input": {
          fontWeight: 700,
          fontSize: "18px !important",
          paddingLeft: 10,
        },
      },
      // "& .react-datepicker-popper": {
      //   width: "85%",
      //   // inset: "0 auto auto -10px !important",
      //   "& .react-datepicker": {
      //     width: "100%",
      //     "& .react-datepicker__day": {
      //       margin: "0.166rem 0.3rem",
      //     },
      //   },
      // },
      "& .oa-form-element-secondary": {
        fontSize: 12,
        fontWeight: "bold",
        textAlign: "left !important",
      },
      "& .oa-form-element-label": {
        // fontSize: 13,
        color: theme.palette.primary.lightGray,
        // fontWeight: 500,
        textAlign: "left",
      },
    },
    "& .oa-form-element-card": {
      border: "none",
      padding: 0,
      // height:70,
      "&.MuiInputBase-input": {
        [theme.breakpoints.down(960)]: {
          fontWeight: 900,
          fontSize: "43px !important",
        },
      },
    },
    "& .oa-form-element-secondary": {
      fontSize: "16px !important",
      fontWeight: "900 !important",
      paddingLeft: 10,
    },
    "& .oa-form-element-label": {
      fontSize: 12,
      color: theme.palette.primary.lightGray,
      fontWeight: "900 !important",
      paddingLeft: 10,
      // textAlign:'center',
    },
    // autocomplete popper style
    "& .oa-form-element-primary .MuiAutocomplete-popper": {
      position: "absolute",
      width: "calc(100vw - 72px) !important",
      left: 36,
      "& .MuiAutocomplete-paper": {
        "& .MuiAutocomplete-listbox": {
          width: "100% !important",
        },
      },
    },
    "& .return": {
      position: "relative",
      "& .return-date-overlay": {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: `rgba(255,255,255,0.7)`,
        borderRadius: 4,
      },
      "& .overlay-close-btn": {
        cursor: "pointer",
        position: "absolute",
        top: 10,
        right: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: `solid 0.5px ${theme.palette.background.dark}`,
        borderRadius: "50%",
        "& .MuiSvgIcon-root": {
          fontSize: "1rem",
        },
      },
    },
  },
  FlightSectionFormContainer: {},

  FlightSectionContainer: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 10,
    padding: 10,
    border: "1px solid #b1b1b1",
  },

  FlightSection_passdet: {
    paddingTop: 15,
    "& .MuiBox-root span": {
      color: `${theme.palette.primary.darkText} !important`,
      fontWeight: 600,
    },
    "& .MuiBox-root .MuiGrid-container": {
      backgroundColor: "none",
      borderBottom: `solid 0.5px ${theme.palette.primary.disabled}`,
      paddingBottom: 5,
      [theme.breakpoints.down(400)]: {
        borderBottom: "none",
      },
    },
    "& .MuiIconButton-root": {
      padding: 0,
    },
    [theme.breakpoints.down(600)]: {
      maxWidth: "100%",
      flexBasis: "100%",
      marginTop: 5,
    },
  },
  FlightSection_actions: {
    paddingTop: 37,
    [theme.breakpoints.down(600)]: {
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },

  toggleBox: {
    "& .MuiToggleButton-root": {
      color: theme.palette.primary.defaultText,
      textTransform: "none",
    },
    "& .Mui-selected": {
      color: theme.palette.primary.lightText,
      backgroundColor: theme.palette.primary.main,

      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    "& .MuiToggleButtonGroup-root": {
      backgroundColor: theme.palette.background.light,
    },
    "& .MuiToggleButton-sizeSmall": {
      height: 24,
      minWidth: 75,
      [theme.breakpoints.down(840)]: {
        minWidth: 0,
      },
      [theme.breakpoints.down(614)]: {
        minWidth: 75,
      },
      [theme.breakpoints.down(358)]: {
        marginBottom: 20,
      },
    },
    "& .MuiToggleButtonGroup-root": {
      [theme.breakpoints.down(358)]: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
  searchButtonBox: {
    width: "100%",
    height: "100%",
    // paddingBottom:'10px',
    "& .MuiButton-root": {
      width: "100%",
      height: "100%",
      padding: 0,
      backgroundColor: theme.palette.secondary.main,
      "& .MuiButton-label": {
        color: theme.palette.primary.lightText,
        fontSize: "18px",
        paddingBottom: "0.1rem",
      },
    },
  },

  airlineSelect: {
    width: "100%",
    borderBottom: `solid 0.5px ${theme.palette.primary.disabled}`,
    marginTop: 4,
    "& .MuiSelect-icon": {
      color: theme.palette.primary.darkText,
    },
    "& .MuiFormLabel-root": {
      fontWeight: 500,
      fontSize: 20,
    },
    [theme.breakpoints.down(840)]: {
      marginLeft: 10,
    },
  },
  FlightSection_flightway: {
    // "& .MuiFormGroup-root": {
    //   flexDirection: "row",
    //   justifyContent: "space-between",
    //   "& .MuiFormControlLabel-label": {
    //     color: theme.palette.primary.darkText,
    //     // color:"#fff",
    //     fontWeight: "400",
    //     fontSize: 12,
    //   },
    // },
  },
  FlightSection_radiogroup: {
    display: "flex",
    justifyContent: "space-between !important",
    flexWrap: "nowrap",
    flexDirection: "row",
    "& .MuiFormGroup-root": {
      marginBottom: "10px",
    },
    "& .MuiGrid-item": {
      width: "100%",
    },

    "& .MuiFormControlLabel-root": {
      margin: 0,
    },
    "& .MuiRadio-root": {
      display: "none",
    },
    "& .MuiFormControlLabel-root": {
      width: "100%",
      justifyContent: "center",
      margin: 0,
      height: 27,
      color: "#000",
    },
    "& .MuiTypography-body1": {
      fontSize: 12,
    },

    "& .MuiFormControlLabel-root:has(.Mui-checked)": {
      width: "100%",
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 4,
      "& .MuiFormControlLabel-label": {
        color: "#fff",
        fontWeight: "400",
        fontSize: 12,
      },
    },

    // "& .MuiFormGroup-root,& .MuiFormControlLabel-label":{
    //     backgroundColor:"#eee",
    //     borderRadius:"13px",
    //     marginBottom:"10",
    //     padding:'9px 13px',
    // },

    [theme.breakpoints.down(440)]: {
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    // [theme.breakpoints.down(600)]: {
    //   display: "grid",
    //   gridTemplateColumns: "repeat(1, 1fr)",
    // },
  },
  gridRespCol: {
    [theme.breakpoints.down(600)]: {
      flexDirection: "column",
      "& > div": {
        margin: "4px",
      },
    },
  },
  gridRespColPassDet: {
    [theme.breakpoints.down(400)]: {
      flexDirection: "column",
      "& > div": {
        margin: "4px",
      },
      "& .counter-box": {
        display: "flex",
        alignItems: "center",
        margin: "10px 0 !imported",
      },
    },
  },
  rowUpper: {
    // borderBottom: `solid 0.5px ${theme.palette.primary.disabled}`,
    padding: "5px 0",
    marginBottom: "13px",
    justifyContent: "center",
  },
  rowLower: {
    // borderTop: `solid 0.5px ${theme.palette.primary.disabled}`,
    // padding: "5px 25px 15px",
    justifyContent: "space-between",
    "& .airline-select-container": {
      paddingTop: 6,
    },
    "& .row-lower-grid-container-inner": {
      margin: 0,
      // [theme.breakpoints.down(840)]: {
      //   justifyContent: "center",
      //   alignItems: "center",
      // },
    },
  },
  rowBottom: {
    marginTop: 13,
    "& .quick-links-container": {
      justifyContent: "flex-start",
      [theme.breakpoints.down(600)]: {
        flexWrap: "wrap",
        justifyContent: "center",
      },
      "& .quick-link-item": {
        margin: 5,
        padding: "3px 5px",
        color: "#333",
        borderRadius: 4,
        cursor: "pointer",
        textAlign: "center",
        display: "flex",
        columnGap: "0.3rem",
        justifyContent: "center",
        background: "rgba(255,255,255,0.23)",
        width: 122,
        "&:hover": {
          border: `solid 1px ${theme.palette.secondary.main}`,
        },
        "& .qlink-icon-wrapper": {
          textAlign: "center",
        },
        "& .qlink-label-wrapper": {
          fontSize: 10,
        },
      },
    },
  },

  FlightSection_searchBtn: {
    padding: "0px",

    "& .MuiGrid-spacing-xs-2": {
      width: "100%",
      margin: "-8px 0",
    },
    "& .search-btn-col": {
      width: "100%",
      height: "65px",
      padding: "22px",
      overflow: "hidden",
      backgroundColor: theme.palette.secondary.main,
      margin: 0,
      marginTop: "9.3px",
      borderRadius: "4px ",
    },
    [theme.breakpoints.down(600)]: {
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },
  flightInfoGridRoot: {
    background: theme.palette.primary.lightText,

    padding: "1rem  0 10px 0",
    width: "100%",

    // borderRadius:'4px',
    "& .flight-info-wrapper": { width: "100%", padding: "0 10px" },

    "& label": {
      fontSize: 12,
      // fontFamily:theme.palette.font.primary,
      color: theme.palette.primary.darkText,
      textAlign: "center",
    },
    "& .MuiAutocomplete-popupIndicator": {
      display: "none",
    },
    "& .MuiAutocomplete-listbox": {
      // width:'500px',
    },
    "& .MuiAutocomplete-popupIndicator .MuiIconButton-label": {
      color: theme.palette.primary.darkText,
    },

    "& .MuiAutocomplete": {
      textAlign: "center",
    },

    "& .multi-city-div": {
      padding: "3px 0px",

      borderRadius: "4px",
      background: theme.palette.background.mobileSearch,
      border: `1.3px solid ${theme.palette.background.mobileSearchBorder}`,
      "& .MuiGrid-container": {
        padding: "0 10px",
      },
      "& .oa-form-element-label": {
        fontSize: "10px !important",
        padding: 0,
      },
      "& .oa-form-element-secondary": {
        padding: 0,
        fontSize: "15px !important",
      },
      "& .oa-form-element-primary .autocomplete": {
        padding: 0,
      },
      "& .MuiInputBase-input": {
        fontSize: "15px !important",
      },
      "& .left-dashed-item .oa-form-element-card .MuiInputBase-input": {
        fontSize: "15px !important",
        padding: "3px 0",
        fontWeight: 400,
      },
      "& .react-datepicker-popper": {
        width: "85vw",
        // inset: "0 auto auto -10px !important",
        "& .react-datepicker": {
          width: "100%",
          marginLeft: 10,
          "& .react-datepicker__day": {
            margin: "0.166rem 0.3rem",
          },
        },
      },
    },

    "& .tofrom": {
      display: "flex",
      justifyContent: "space-between",
      "& .location-input-container": {
        background: 'initial',
        "& .adornment-icon": {
          width: 0
        },
        "& .MuiAutocomplete-inputRoot":{
          paddingRight: 0
        }
      },
   
      "& .swiperIconBtn": {
        width: "max-content",
        padding: 0,
        border: "none",
        background: "none",
        "& .btn_div": {
          textAlign: "center",
          position: "relative",

          marginLeft: "-13px",
          marginRight: "-10px",
          display: "flex",
          alignItems: "center",
          height: "100%",
          [theme.breakpoints.up(660)]: {
            marginLeft: "0px",
            marginRight: "0px",
          },
        },
        "& .MuiIconButton-label": {
          color: theme.palette.primary.defaultText,
          backgroundColor: theme.palette.primary.LightGraySec,
          borderRadius: 50,
          padding: 10,
        },
        "& .MuiIconButton-root": {
          padding: "0",
          fontSize: "23px",
        },
      },
      "& > div": {
        width: "47%",
        padding: "10px 0",
        borderRadius: "4px",
        background: theme.palette.background.mobileSearch,
        border: `1.3px solid ${theme.palette.background.mobileSearchBorder}`,
      },
    },
    "& .date": {
      display: "flex",
      justifyContent: "space-between",
      "& .date-input-container": {
        background: 'initial',
        "& .adornment-icon": {
          width: 0
        },
        "& .MuiAutocomplete-inputRoot":{
          paddingRight: 0
        }
   },
      "& .oa-form-element-card": {
        border: "none",
        padding: 0,
        // height: 60,
        "& .MuiInputBase-input": {
          fontWeight: 400,
          fontSize: "18px !important",
          padding: "3px 10px",
        },
      },
      "& .react-datepicker-popper": {
        width: "85vw",
        // inset: "0 auto auto -10px !important",
        "& .react-datepicker": {
          width: "100%",
          "& .react-datepicker__day": {
            margin: "0.166rem 0.3rem",
          },
        },
      },

      "& .oa-form-element-secondary": {
        fontSize: 12,
        fontWeight: "bold",
      },
      "& .oa-form-element-label": {
        // fontSize: 13,
        color: theme.palette.primary.lightGray,
        // fontWeight: 500,
      },
      marginTop: "10px",
      padding: "0",
      "& .MuiIconButton-label": {
        color: theme.palette.primary.defaultText,
        backgroundColor: theme.palette.primary.LightGraySec,
        borderRadius: 50,
        padding: 6,
        fontSize: "16px",
      },
      "& .MuiFormControl-fullWidth": {
        width: "100%",
      },
      "& .MuiInputBase-input": {
        padding: 0,
      },
      "& > div": {
        width: "47%",
        padding: "10px 0px",
        borderRadius: "4px",
        background: theme.palette.background.mobileSearch,
        border: `1.5px solid ${theme.palette.background.mobileSearchBorder}`,
      },
    },

    [theme.breakpoints.down(440)]: {
      height: "auto",
    },
  },

  traveler__box: {
    width: "100%",
    background: "#fff",
    // padding: '0 1rem 1rem 1rem',
    marginBottom: "13px",
    display: "flex",
    justifyContent: "space-between",
    "& .gap_div": {
      width: "5%",
      border: "none",
      background: "none",
    },
    "& > div": {
      width: "47%",
      padding: "6px 10px",
      background: theme.palette.background.mobileSearch,
      // "&:nth-child(1)": {
      // },
      borderRadius: "4px",
      border: `1.3px solid ${theme.palette.background.mobileSearchBorder}`,
    },
    "& .passenger-add-container": {
      width: "100%",
      padding: "0",
      position: "relative",

      "& .passenger_div-show": {
        position: "absolute",
        zIndex: "9999",
        width: "190px",
        height: "max-content",
        left: "-3px",
        bottom: "0",
        top: "79px",
        padding: "10px",
        borderRadius: "4px",
        background: "#fff",
        boxSizing: "content-box",
        boxShadow: "0 0 20px 0 rgb(0 0 0 / 45%)",
        "& .MuiButton-root": {
          width: "100%",
          border: `1px solid ${theme.palette.secondary.darkOrange}`,
          color: theme.palette.secondary.darkOrange,
        },
      },

      "& .passenger_div": {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        cursor: "pointer",
        "& p": {
          margin: "0px",
          textTransform: "capitalize",
        },

        "& .trvl": {
          fontSize: "12px",
          fontWeight: "400",
          marginTop: "0.5rem",
          // paddingBottom: '0.3rem',
          // fontFamily: theme.palette.font.primary,
          textTransform: "uppercase",
          // lineHeight:1 ,
        },
        "& .trvl-count": {
          "& span": {
            margin: "0 0.2rem",
          },
          "& .trval-tnum": {
            fontSize: "14px",
            fontWeight: "700",
          },
          "& .trval-txt": {
            fontWeight: "600",
          },
        },
      },
    },

    "& .class-add-container": {
      position: "relative",
      "& .class_div": {
        "& p": {
          margin: 0,
          "&:nth-child(1)": {
            marginTop: "8px",
          },
          "&:nth-child(2)": {
            fontWeight: "600",
            fontSize: "14px",
          },
        },
      },

      "& .class_div-show": {
        position: "absolute",
        zIndex: "9999",
        width: "190px",
        height: "max-content",
        left: "-3px",
        bottom: "0",
        top: "45px",
        padding: "10px",
        borderRadius: "4px",
        background: theme.palette.primary.lightText,
        boxSizing: "content-box",
        boxShadow: "0 0 20px 0 rgb(0 0 0 / 45%)",
        [theme.breakpoints.down(483)]: {
          left: "-113%",
        },
        "& .cabinType-dev": {
          padding: "0 10px",
          "& .cabin-title": {
            borderBottom: "solid 1px #eee",
            paddingBottom: "6px",
          },
          "& .MuiFormControlLabel-root": {
            margin: "5px 0",
          },
          "& .MuiFormControlLabel-label": {
            marginLeft: "6px",
            color: "#000",
            fontSize: "13px",
            // fontFamily: theme.palette.font.primary,
          },
          "& .MuiButton-root": {
            width: "100%",
            border: `1px solid ${theme.palette.secondary.darkOrange}`,
            color: theme.palette.secondary.darkOrange,
          },
          "& .Mui-checked": {
            color: theme.palette.secondary.darkOrange,
          },
        },
      },
    },
  },
}));
