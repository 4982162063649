import React, { useState, useEffect } from "react";
// @material-ui/core components
import {
  CircularProgress,
  TextField,
  FormControl,
  Checkbox,
  FormControlLabel,
  useTheme
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";

import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import WebApi from "api/ApiConstants";
import FlightTicketResult from "pages/report/flight/FlightTicketResult";
import OaCard from "oahoc/OaCard";
import OaTabBar from "oahoc/OaTabBar";
import { apiCall } from "oautils/oaDataUtils";

import OaFormAlerts from "pages/components/OaFormAlerts";
import Send from "@material-ui/icons/Send";
import Save from "@material-ui/icons/Save";
import { useHistory } from "react-router-dom";
// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import { getQueryVar } from "oautils/oaCommonUtils";
import * as jsPDF from "jspdf";
import * as html2canvas from "html2canvas";
import ReactDOMServer from "react-dom/server";
import { pdfDownload, getImageData } from "oautils/oaPdfUtils";

import "assets/css/print-portrait.css";
import "assets/css/FlightTicket.css";
import { useSelector } from "react-redux";
import { Home } from "@material-ui/icons";
import OaButtonPDF from "pages/components/OaButtonPDF";
import { DOWNLOAD_FLIGHT_TICKET_PDF } from "api/ApiConstants";
import OaButtonDownloadPDF from "pages/components/OaButtonDownloadPDF";
import { localforageGetItem } from "oautils/oaForageUtils";
import Axios from "axios";
import { DOWNLOAD_FLIGHT_TICKET_HTML } from "api/ApiConstants";

const useStyles = makeStyles(styles);

function FlightTicket(props) {
  const history = useHistory();
  const theme = useTheme();
  const pxToMm = (px) => {
    return Math.floor(px / document.getElementById("myMm").offsetHeight);
  };

  const mmToPx = (mm) => {
    return document.getElementById("myMm").offsetHeight * mm;
  };

  const range = (start, end) => {
    return Array(end - start)
      .join(0)
      .split(0)
      .map(function (val, id) {
        return id + start;
      });
  };

  let tabs = [
    { name: "flight", label: "Flight", url: "/admin/booking-report/flight" },
  ];

  let currentTab = "flight";
  const isMounted = React.useRef(false);
  const renderRef = React.useRef(null);

  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");

  const [data, setData] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [isUpdateMarkup, setIsUpdateMarkup] = useState(false);
  const [markup, setMarkup] = useState(0);
  const [markupTemp, setMarkupTemp] = useState(0);
  const [isSendEmail, setIsSendEmail] = useState(false);
  const [receiverEmail, setReceiverEmail] = useState("");
  const [isStatusLoading, seStatustLoading] = useState(false);
  const [loggedInUserId, setLoggedInUserId] = useState(0);
  const [state, setState] = useState({
    isAddressHide: false,
    isFareHide: false,
    isPerPassenger: false,
    isGSTHide: false,
  });
  const [flightTicketHtml, setFlightTicketHtml] = React.useState(null);
  const userTypeInfo = useSelector(state => state.usertype);
  //  const ticketId = getQueryVar({ ...props, q: "ticketId" });

  const ticketId = history?.location?.state?.tid ? history?.location?.state?.tid : getQueryVar({ ...props, q: 'ticketId' });

  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };

  const getTicketData = (transactionId) => {
    return new Promise((resolve, reject) => {
      WebApi.getTicketData(
        { transactionId: transactionId },
        (response) => {
          if (response !== null && response.success === true) {
            resolve(response.data);
          }
        },
        (error) => {
          reject([]);
          // console.log(error);
        }
      );
    });
  };

  function getIcon() {
    return !isSaving ? <Send /> : <CircularProgress size="24px" />;
  }

  function getIcon1() {
    return !isSaving ? <Save /> : <CircularProgress size="24px" />;
  }

  const getUserDetails = (mountStatus) => {
    localforageGetItem("user-details", function (err, value) {
      if (mountStatus && value) {
        setLoggedInUserId(value.userId);
      } else {
        console.log("error getting user details", err);
      }
    });
  };

  const getFlightTicketHtmlString = () => {
    Axios.post(DOWNLOAD_FLIGHT_TICKET_HTML, {
      currency: "ÏNR",
      email: "vincent@oari.co",
      flightDetailReq: {
        loggedInUserId: loggedInUserId,
        transactionId: ticketId,
        ...state
      }
    }).then(response => {
      if (response.status === 200) {
        setFlightTicketHtml(response.data);
      }
    }).catch(error => {
      console.error("Error", error);
    });
    // WebApi.getFlightTicketHtmlString({
    //   currency: "ÏNR",
    //   email: "vincent@oari.co",
    //   flightDetailReq: {
    //     loggedInUserId: loggedInUserId,
    //     transactionId: ticketId,
    //     ...state
    //   }
    // }, response => {
    //   if(response.success) {
    //     console.log("response", response.data);
    //     setFlightTicketHtml(response.data);
    //   } else {
    //     console.error("Failed to fetch flight ticket html");
    //   }
    // }, error => {
    //   console.error("Error", error);
    // });
  };

  useEffect(() => {
    isMounted.current = true;
    setLoading(true);

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getUserDetails(isMounted.current);
    if (ticketId > 0) {
      getTicketData(ticketId).then(function (data) {
        setData(data);
        setReceiverEmail(data.agentInfo ? data.agentInfo.agentEmail : "");
        setLoading(false);
        setMarkup(data.flightSummary.agentMarkup);
        setMarkupTemp(data.flightSummary.agentMarkup);

        let counter = 0;

        if (data.bookingStatus !== "SuccessTkd") {
          on();
        }
        const myInterval = setInterval(() => {
          counter++;

          if (data.bookingStatus !== "SuccessTkd") {
            seStatustLoading(true);
            getTicketData(ticketId).then(function (data) {
              if (data.bookingStatus === "SuccessTkd" || counter === 8) {
                off();
                setData(data);
                clearInterval(myInterval);
              }
              seStatustLoading(false);
            });
          } else {
            clearInterval(myInterval);
          }
        }, 3500);
      });
      // getFlightTicketHtmlString();
    }
  }, [isMounted.current]);

  function printClick() {
    history.push({
      pathname:
        "/flightticket/" +
        ticketId +
        "/" +
        state?.isAddressHide +
        "/" +
        state?.isFareHide +
        "/" +
        state?.isPerPassenger +
        "/" +
        state?.isGSTHide,
    });
  }

  const classes = useStyles();

  const { isAddressHide, isFareHide, isPerPassenger, isGSTHide } = state;

  const flightTicketDownloadRequest = {
    flightDetailReq: {
      loggedInUserId: "" + loggedInUserId,
      transactionId: ticketId
    },
    currency: "INR",
    isAddressHide: isAddressHide,
    isFareHide: isFareHide,
    isGSTHide: isGSTHide,
    isPerPassenger: isPerPassenger
  };

  const handleMarkupSave = () => {
    setIsSaving(true);
    const markup1 = markup == "" ? 0 : markup;
    setMarkupTemp(markup1);
    apiCall(WebApi.doMarkupUpdate, { markup: markup1, tid: ticketId }, function (
      response
    ) {
      if (response.success === true) {
        setIsError(false);
        setIsSuccess(true);
        setIsSaving(false);
        setInfoText(response.message);
      } else {
        setIsError(true);
        setIsSaving(false);
        setInfoText(response.message);
      }
    });
  };

  function downloadETicket(e) {
    e.target.setAttribute("disabled", "disabled");
    pdfDownload("section-to-print", "p", "eticket-" + ticketId);
    e.target.removeAttribute("disabled");
  }

  function sendEmail() {
    setIsSaving(true);
    getImageData("section-to-print", "p", function (imageData) {
      apiCall(
        WebApi.emailETicket,
        {
          email: receiverEmail,
          body:
            "<!DOCTYPE html><html><body><img src='" +
            imageData +
            "'/></body></html> ",
        },
        function (response) {
          if (response.success === true) {
            setIsError(false);
            setIsSuccess(true);
            setIsSaving(false);
            setInfoText("Send email request registered");
          } else {
            setIsError(true);
            setIsSaving(false);
            setInfoText("Email server busy try after sometime");
          }
        }
      );
    });
  }

  function on() {
    document.getElementById("overlay").style.display = "flex";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
  }

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "-webkit-fill-available",
          }}
        >
          <CircularProgress disableShrink />
        </div>
      ) : (
        <>
          <div id="overlay">
            <div className="loader-text">Updating booking status</div>
            <div className="loader-dots">
              <div className="dot1"> </div>
              <div className="dot2"> </div>
              <div className="dot3"> </div>
            </div>
          </div>
          <OaCard className={"oa-card-primary"}>
            <OaFormAlerts
              isSaving={isSaving}
              isSuccess={isSuccess}
              isError={isError}
              infoText={infoText}
              setIsError={setIsError}
              setIsSuccess={setIsSuccess}
            />
            <OaCard>
              <div>
                <OaCard className="section-not-to-print">
                  {loggedInUserId != 0 ? (
                    <GridContainer>
                      <GridItem xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={isAddressHide}
                              onChange={handleChange("isAddressHide")}
                              value="isAddressHide"
                            />
                          }
                          label="Hide Address"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={isFareHide}
                              onChange={handleChange("isFareHide")}
                              value="isFareHide"
                            />
                          }
                          label="Hide Fare"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={isGSTHide}
                              onChange={handleChange("isGSTHide")}
                              value="isGSTHide"
                            />
                          }
                          label="Hide GST"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={isPerPassenger}
                              onChange={handleChange("isPerPassenger")}
                              value="isPerPassenger"
                            />
                          }
                          label="Passenger Wise"
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        {/* <Button color="primary" onClick={printClick}>
                        Print ETicket
                      </Button> */}

                        {/* <Button color="primary" onClick={downloadETicket}>
                        Download ETicket
                      </Button> */}
                        <OaButtonDownloadPDF
                          btnLabel="Download ETicket"
                          api={DOWNLOAD_FLIGHT_TICKET_PDF}
                          searchParams={flightTicketDownloadRequest}
                          defaultFileName="flight-eticket"
                        />

                        {/* <a href="mailto:dev@oari.co?Subject=Booking%20Ticket%20Confirmation" target="_top"> */}
                        <Button
                          color="primary"
                          onClick={() => {
                            setIsSendEmail(!isSendEmail);
                            setIsUpdateMarkup(false);
                          }}
                        >
                          Email
                        </Button>
                        {/* </a> */}

                        {/* <Button color="primary" onClick={printClick} className={classes.registerButton}>Invoice</Button> */}
                        {(userTypeInfo && userTypeInfo.corporateType != "corporate" &&
                          userTypeInfo.userTypeAbv != "G" && userTypeInfo.userTypeAbv != "R") &&
                          <Button
                            color="primary"
                            onClick={() => {
                              setIsUpdateMarkup(!isUpdateMarkup);
                              setIsSendEmail(false);
                            }}
                          >
                            Update Markup
                          </Button>}
                        {isUpdateMarkup && (
                          <GridContainer>
                            <GridContainer justifyContent="center">
                              <GridItem>
                                <h4 style={{ color: theme.palette.secondary.lightIndigo }}>
                                  UPDATE MARKUP
                                </h4>
                              </GridItem>
                            </GridContainer>
                            <GridContainer justifyContent="center">
                              <GridItem>
                                <FormControl fullWidth={true}>
                                  <TextField
                                    variant="outlined"
                                    name="markup"
                                    label="Markup Value"
                                    md={4}
                                    value={markup}
                                    onChange={(event) => {
                                      const { value } = event.target;
                                      setMarkup(value);
                                    }}
                                  />
                                </FormControl>
                              </GridItem>
                              <GridItem>
                                <Button
                                  color="primary"
                                  className={classes.registerButton}
                                  endIcon={getIcon1()}
                                  disabled={isSaving}
                                  onClick={handleMarkupSave}
                                >
                                  UPDATE
                                </Button>
                              </GridItem>
                            </GridContainer>
                          </GridContainer>
                        )}

                        {isSendEmail && (
                          <GridContainer>
                            <GridContainer justifyContent="center">
                              <GridItem>
                                <h4 style={{ color: theme.palette.secondary.lightIndigo }}>SEND EMAIL</h4>
                              </GridItem>
                            </GridContainer>
                            <GridContainer justifyContent="center">
                              <GridItem>
                                <FormControl fullWidth={true}>
                                  <TextField
                                    variant="outlined"
                                    name="receiverEmail"
                                    label="Receiver Email"
                                    md={4}
                                    value={receiverEmail}
                                    onChange={(event) => {
                                      const { value } = event.target;
                                      setReceiverEmail(value);
                                    }}
                                  />
                                </FormControl>
                              </GridItem>
                              <GridItem>
                                <Button
                                  color="primary"
                                  onClick={sendEmail}
                                  className={classes.registerButton}
                                  endIcon={getIcon()}
                                  disabled={isSaving}
                                >
                                  SEND
                                </Button>
                              </GridItem>
                            </GridContainer>
                          </GridContainer>
                        )}
                      </GridItem>
                    </GridContainer>
                  ) : (
                    <>
                      {/* <Button
                      color="primary"
                      onClick={printClick}
                      className={classes.registerButton}
                    >
                      Print ETicket
                    </Button> */}

                      {/* <Button
                      color="primary"
                      onClick={downloadETicket}
                      className={classes.registerButton}
                    >
                      Download ETicket
                    </Button> */}
                      <OaButtonDownloadPDF
                        btnLabel="Download ETicket"
                        api={DOWNLOAD_FLIGHT_TICKET_PDF}
                        searchParams={flightTicketDownloadRequest}
                        defaultFileName="flight-eticket"
                      />
                    </>
                  )}
                </OaCard>
                {/* <OaCard>
                    {flightTicketHtml &&
                      <div ref={renderRef} className="flight-ticket-render-div" dangerouslySetInnerHTML={{
                        __html: flightTicketHtml,
                      }} />}
                  </OaCard> */}
                {/* <GridContainer justifyContent="center"  id="section-to-print" className="section-to-print" style={{maxWidth:900}}>
            <GridItem col={6}>
                    <OaCard  >
                    {
                        isPerPassenger ? (data.passengers && data.passengers.map((passenger, passengerindex) => {
                            return (
                            <FlightTicketResult data={data} state={state} passenger={passenger} passengerindex={passengerindex} key={passengerindex} markup={markupTemp}></FlightTicketResult>);
                        })) :<FlightTicketResult data={data} state={state} markup={markupTemp}></FlightTicketResult>
                    }
                    </OaCard>
                    </GridItem>
            </GridContainer> */}

                <GridContainer
                  justifyContent="center"
                  id="section-to-print"
                  className="section-to-print"
                  style={{ maxWidth: 1050 }}
                >
                  <GridItem>
                    <OaCard>
                      {data.onwardJourney && (
                        <>
                          {isPerPassenger ? (
                            data.passengers &&
                            data.passengers.map((passenger, passengerindex) => {
                              return (
                                <FlightTicketResult
                                  data={data}
                                  state={state}
                                  passenger={passenger}
                                  passengerindex={passengerindex}
                                  key={passengerindex}
                                  markup={markupTemp}
                                  journey={data.onwardJourney}
                                  journeyType={"onward"}
                                  isStatusLoading={isStatusLoading}
                                  companylogo={props.companylogo}
                                ></FlightTicketResult>
                              );
                            })
                          ) : (
                            <FlightTicketResult
                              data={data}
                              state={state}
                              markup={markupTemp}
                              journey={data.onwardJourney}
                              journeyType={"onward"}
                              isStatusLoading={isStatusLoading}
                              companylogo={props.companylogo}
                            ></FlightTicketResult>
                          )}
                        </>
                      )}
                      <p className="page-break"></p>

                      {data.returnJourney.length > 0 && (
                        <>
                          {isPerPassenger ? (
                            data.passengers &&
                            data.passengers.map((passenger, passengerindex) => {
                              return (
                                <FlightTicketResult
                                  data={data}
                                  state={state}
                                  passenger={passenger}
                                  passengerindex={passengerindex}
                                  key={passengerindex}
                                  markup={markupTemp}
                                  journey={data.returnJourney}
                                  journeyType={"return"}
                                  isStatusLoading={isStatusLoading}
                                  companylogo={props.companylogo}
                                ></FlightTicketResult>
                              );
                            })
                          ) : (
                            <FlightTicketResult
                              data={data}
                              state={state}
                              markup={markupTemp}
                              journey={data.returnJourney}
                              journeyType={"return"}
                              isStatusLoading={isStatusLoading}
                              companylogo={props.companylogo}
                            ></FlightTicketResult>
                          )}
                        </>
                      )}
                    </OaCard>
                  </GridItem>
                </GridContainer>
              </div>
            </OaCard>
            <div className="floating-home-nav">
              <a className="floating-home-nav-button"
                onClick={() => history.push("/flights")}
              >
                <Home />
              </a>
            </div>
            <style>
              {`
            .oa-card-primary {
              position: relative;
            }
            .floating-home-nav {
              position: fixed;
              bottom: 10px;
              right: 15px;
            }
            .floating-home-nav-button {
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              background: ${theme.palette.buttons.secondary};
              width: 50px;
              height: 50px;
              border-radius: 50%;
              -webkit-box-shadow: 0px 0px 10px 0px rgba(138,136,138,1);
              -moz-box-shadow: 0px 0px 10px 0px rgba(138,136,138,1);
              box-shadow: 0px 0px 10px 0px rgba(138,136,138,1);
            }
            .floating-home-nav-button .MuiSvgIcon-root {
              color: ${theme.palette.buttons.secondaryContrastText};
            }
            `}
            </style>
          </OaCard>
        </>
      )}
    </>
  );
}

export default FlightTicket;
