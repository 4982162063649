import React from "react";
import { useState, useEffect } from "react";
import { AppStateContext } from "layouts/AppStateProvider";
import { connect } from "react-redux";
import { setTimerInitialMinute, setTimerInitialSecond } from "redux/action";
import { getTabName } from "oautils/oaCommonUtils.js";
import { withRouter } from "react-router-dom";
import OaTimerDestroyer from "oahoc/OaTimerDestroyer";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import "../assets/css/oa-timer.css";
import { useHistory } from "react-router-dom";
import { setBusBookingInitialTime } from "redux/action";
import { setFlightBookingInitialTime } from "redux/action";
import { setHotelBookingInitialTime } from "redux/action";
import { setInsuranceBookingInitialTime } from "redux/action";
import OaTimerAlert from "./OaTimerAlert";
import OaAlert from "oahoc/OaAlert";

const OaTimer = (props) => {
  const [minutes, setMinutes] = useState(15);
  const [seconds, setSeconds] = useState(0);
  const [open, setOpen] = useState(false);
  const [service, setService] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [sessionStartTime, setSessionStartTime] = useState(countDownDate);
  const [timerActive, setTimerActive] = React.useState(false);
  const [isError, setIsError] = useState(false);

  const [isAlertPrompt, setIsAlertPrompt] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState("");
  const [primaryButtonText, setPrimaryButtonText] = React.useState("");
  const [secondaryButtonText, setSecondaryButtonText] = React.useState("");
  const [alertTitle, setAlertTitle] = React.useState("");

  // const {
  //   SetMessage,
  //   SetError,
  //   isConfirmed,
  //   setIsConfirmed,
  // } = React.useContext(AppStateContext);

  let currentTab = getTabName();
  const classes = useStyles();
  const history = useHistory();

  let countDownDate = null;

  let servicePath = "/";
  /* switch (service) {
    case "hotel": {
      countDownDate = props.bookingInitialTimeHotel;
      console.log("countdown", countDownDate);
      break;
    }
    case "bus": {
      countDownDate = props.bookingInitialTimeBus;
      break;
    }
    case "flight": {
      countDownDate = props.bookingInitialTimeFlight;
      break;
    }
    case "insurance": {
      countDownDate = props.bookingInitialTimeInsurance;
      break;
    }
  } */

  const handleClick = () => {
    setOpen(false);
    history.push("/");
    window.sessionStorage.removeItem("service");
    if (service === "hotel") {
      props.setHotelBookingInitialTime(null);
    }
    if (service === "bus") {
      props.setBusBookingInitialTime(null);
    }
    if (service === "flight") {
      props.setFlightBookingInitialTime(null);
    }
    if (service === "insurance") {
      props.setInsuranceBookingInitialTime(null);
    }
  };

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(false);
  };

  useEffect(() => {
    //console.log("history", history)
    const serviceValue = window.sessionStorage.getItem("service");
    setService(serviceValue);
    setTimerActive(checkRoute(history, serviceValue, props));
  });

  useEffect(() => {
    switch (service) {
      case "hotel": {
        countDownDate = props.hotelBookingInitialTime;
        servicePath = "/hotels";
        // console.log("countdown", countDownDate);
        break;
      }
      case "bus": {
        countDownDate = props.busBookingInitialTime;
        servicePath = "/buses";
        break;
      }
      case "flight": {
        countDownDate = props.flightBookingInitialTime;
        servicePath = "/flights";
        break;
      }
      case "insurance": {
        countDownDate = props.insuranceBookingInitialTime;
        servicePath = "/insurances";
        break;
      }
    }
    setSessionStartTime(countDownDate);
  }, [
    props.busBookingInitialTime,
    props.flightBookingInitialTime,
    props.hotelBookingInitialTime,
    props.insuranceBookingInitialTime,
    service,
  ]);

  useEffect(() => {
    let x = null;

    if (timerActive) {
      x = setInterval(() => {
        if (sessionStartTime) {
          let now = new Date().getTime();
          let distance = sessionStartTime - now;

          let minute = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          let second = Math.floor((distance % (1000 * 60)) / 1000);
          if (minute >= 0 && second >= 0) {
            if (minute === 5 && second === 0) {
              setAlertMsg("Session will be expires in 5 Mins ");
              //setAlertTitle("Fields Mandatory");
              setIsError(true);
              setPrimaryButtonText("OK");
              setIsAlertPrompt(true);
            } else if (minute === 1 && second === 0) {
              setAlertMsg("Session will be expires in 1 Mins ");
              // setAlertTitle("Fields Mandatory");
              setIsError(true);
              setPrimaryButtonText("OK");
              setIsAlertPrompt(true);
            }
            setMinutes(minute);
            setSeconds(second);
          }
          if (minute === 0 && second === 0) {
            setOpen(true);

            clearInterval(x);
          }
          if (distance < 0) {
            clearInterval(x);
          }
        }
      }, 1000);
    }

    return () => {
      if (timerActive) {
        clearInterval(x);
      }
      setTimerActive(false);
      setMinutes(0);
      setSeconds(0);
    };
  }, [sessionStartTime, timerActive]);

  return (
    timerActive && (
      <div className={classes.root}>
        {minutes >= 0 && seconds >= 0 && service ? (
          <div
            className={"oatimer "}
            // style={{ opacity: service === "flight" ? 0 : 1 }}
            style={{ opacity: 1 }}
          >
            <div className="oatimer-clock-card">
              <div className="oatimer-time">
                <h6 style={{ fontSize: isMobile && 9 }}>
                  {`${minutes} min`} :{" "}
                  {seconds < 10 ? `0${seconds} sec` : `${seconds} sec`}
                </h6>
                {/* <span style={{ fontSize: 12, color: isMobile ? "#000" : "#fff", paddingLeft: isMobile && 10, paddingTop: isMobile && 7 }}>
                Booking
              </span> */}
              </div>
            </div>
          </div>
        ) : (
          <OaTimerDestroyer />
        )}
        <OaTimerAlert
          open={open}
          handleClick={handleClick}
          handleClose={handleClose}
        />
        {isError && (
          <OaAlert
            isPrompt={isAlertPrompt}
            msg={alertMsg}
            title={alertTitle}
            isError={isError}
            primaryButtonText={primaryButtonText}
            secondaryButtonText={secondaryButtonText}
            togglePrompt={() => {
              setIsAlertPrompt(!isAlertPrompt);
            }}
          ></OaAlert>
        )}
      </div>
    )
  );
};

const mapStateToProps = (state, props) => {
  return {
    busBookingInitialTime: state.hotel.busBookingInitialTime,
    flightBookingInitialTime: state.hotel.flightBookingInitialTime,
    hotelBookingInitialTime: state.hotel.hotelBookingInitialTime,
    insuranceBookingInitialTime: state.hotel.insuranceBookingInitialTime,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setBusBookingInitialTime: (bookingInitialTimeBus) =>
      dispatch(setBusBookingInitialTime(bookingInitialTimeBus)),
    setFlightBookingInitialTime: (bookingInitialTimeFlight) =>
      dispatch(setFlightBookingInitialTime(bookingInitialTimeFlight)),
    setHotelBookingInitialTime: (bookingInitialTimeHotel) =>
      dispatch(setHotelBookingInitialTime(bookingInitialTimeHotel)),
    setInsuranceBookingInitialTime: (bookingInitialTimeInsurance) =>
      dispatch(setInsuranceBookingInitialTime(bookingInitialTimeInsurance)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OaTimer);

const checkRoute = (history, service, props) => {
  let timerStatus = false;
  if (history) {
    switch (service) {
      case "flight": {
        if (
          history.location.pathname.includes("/flight/search-progress") ||
          history.location.pathname.includes("/flight/search-result") ||
          history.location.pathname.includes(
            "/flight/booking/passenger-info"
          ) ||
          history.location.pathname.includes(
            "/flight/booking/review-details"
          ) ||
          history.location.pathname.includes("/flight/booking/payment-details")
        ) {
          timerStatus = true;
        }
        break;
      }

      case "hotel": {
        if (
          (history.location.pathname.includes("/hotel/search") ||
          history.location.pathname.includes("/hotel/searchResult") ||
          history.location.pathname.includes("/hotel/HotelDetail") ||
          history.location.pathname.includes("/hotel/bookingForm") ||
          history.location.pathname.includes("/hotel/reviewbooking") ||
          history.location.pathname.includes("/hotel/hotelpaymentprogress")) && 
          (!props.match?.params)
        ) {
          timerStatus = true;
        }
        break;
      }

      case "bus": {
        if (
          (history.location.pathname.includes("/bus/search-progress") ||
          history.location.pathname.includes("/bus/bus-result") ||
          history.location.pathname.includes("/bus/passenger-details") ||
          history.location.pathname.includes("/bus/passenger-review") ||
          history.location.pathname.includes("/bus/bus-payment-progress")) &&
          (!props.match?.params)
        ) {
          timerStatus = true;
        }
        break;
      }

      case "insurance": {
        if (
          history.location.pathname.includes("/insurance/search") ||
          history.location.pathname.includes("/insurance/result") ||
          history.location.pathname.includes("/insurance/details") ||
          history.location.pathname.includes("/insurance/booking") ||
          history.location.pathname.includes("/insurance/review") ||
          history.location.pathname.includes(
            "/insurance/insurancepaymentprogress"
          )
        ) {
          timerStatus = true;
        }
        break;
      }
    }
  }
  return timerStatus;
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .oatimer": {
      background: theme.palette.background.default,
      color: theme.palette.background.defaultContrast,
    },
    "& .oatimer-clock-card": {
      // background: " #c1272d",
      //  padding: "0 11px !important",
      // textAlign: "center",
      // borderRadius: 8,
      // border: "3px solid #e4666b",
      // boxShadow: "0 1px 30px rgba(0, 0, 0, 0.2)",
      // [theme.breakpoints.down(730)]: {
      //   // background:'rgb(202, 49, 49) !important',
      //   // color:'#fff',
      "& .oatimer-time": {
        [theme.breakpoints.down(400)]: {
          fontSize: 9,
        },
      },
      // },
    },
    // [theme.breakpoints.down(730)]: {
    //   "& .oatimer": {
    //     left: "29% !important",
    //     top: "-43% !important",
    //   },
    // },
    // [theme.breakpoints.down(1286)]: {
    //   "& .oatimer": {
    //     left: "46% !important",
    //     top: "9% !important",
    //   },
    // },

    // [theme.breakpoints.down(586)]: {
    //   "& .oatimer": {
    //     left: "6% !important",
    //     top: "0% !important",
    //   },
    // },
    // [theme.breakpoints.down(383)]: {
    //   "& .oatimer": {
    //     left: "-9% !important",
    //     top: "0% !important",
    //   },
    // }
  },
}));

// import React from "react";

// const OaTimer = () => {
//   return <div>OaTimer</div>;
// };

// export default OaTimer;
