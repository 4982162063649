import React from "react";
import PassengerCount from "./PassengerCounter";
import WebApi from "api/ApiConstants";
import _ from "lodash";
import dateFnsFormat from "date-fns/format";
// import swiperIcon from "assets/BuzzHolidays/images/icons/swipe_icon.svg";
import swiperIcon from "assets/BuzzHolidays/images/home/switchBtn.png";
import planeIcon from "assets/BuzzHolidays/images/home/plane.png";
import OaPopperAutocomplete from "oahoc/OaPopperAutocomplete";
import OaPopperDatepicker from "oahoc/OaPopperDatepicker";
import FLightMultiCitySection from "./FlightMultiCitySection";
import { ErrorMessage } from "formik";
import {
  Box,
  Grid,
  makeStyles,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  IconButton,
  FormControl,
  Button,
  Popper,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import OaOutlinedDatePicker from "oahoc/OaOutlinedDatepicker";

const FlightItinSection = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [isLoading, setIsLoading] = React.useState(true);
  const [domesticCarriers, setDomesticCarriers] = React.useState([]);
  const [internationalCarriers, setInternationalCarriers] = React.useState([]);
  const [fromCountry, setFromCountry] = React.useState("");
  const [toCountry, setToCountry] = React.useState("");
  const [carrier, setCarrier] = React.useState(domesticCarriers);
  const [dropdownTraveler, setDropdownTraveler] = React.useState(false);
  const [airportList, setAirportList] = React.useState(props.popularcities);
  const [fetchingAirports, setFetchingAirports] = React.useState(false);
  let totalTravelers = 0;

  const modifyPassengerCount = (type, val) => {
    if (type === "adult") {
      props.formikref.current.setFieldValue(
        "adultPax",
        Number(props.formikref.current.values.adultPax) + val
      );
    }
    if (type === "child") {
      props.formikref.current.setFieldValue(
        "childPax",
        Number(props.formikref.current.values.childPax) + val
      );
    }
    if (type === "infant") {
      props.formikref.current.setFieldValue(
        "infantPax",
        Number(props.formikref.current.values.infantPax) + val
      );
    }
  };

  const getAirlineData = () => {
    WebApi.getAirports(
      {},
      (response) => {
        if (response.success === true) {
          setDomesticCarriers(response.data.domCarriers);
          setInternationalCarriers(response.data.intlCarriers);
          setCarrier(response.data.domCarriers);
          setIsLoading(false);
        } else {
          props.setiserror(true);
        }
      },
      (error) => console.log("getAirports error", error)
    );
  };

  const fetchAirports = (searchParams) => {
    setFetchingAirports(true);
    if (searchParams.length >= 3) {
      WebApi.fetchAirportList(
        {
          airportCode: searchParams.trim(),
        },
        (response) => {
          if (response.success == true) {
            setAirportList(response.data.airports);
          }
        },
        (error) => console.log("fetchAirportList error", error)
      );
    }
  };

  function changeHandler(fromTo, countryCode) {
    if (fromTo === "from") {
      setFromCountry(countryCode ? countryCode : "IN");
    } else if (fromTo === "to") {
      setToCountry(countryCode ? countryCode : "IN");
    }
  }

  const renderOptionsComp = (optionsData, propes) => {
    return (
      <div selected={propes.selected} className="ac-options-list">
        <span>
          <p className="ac-option-left">
            {optionsData.cityName} ({optionsData.airportCode})
          </p>
          <p className="ac-option-right">{optionsData.countryCode}</p>
        </span>
        <p>{optionsData.airportDesc}</p>
      </div>
    );
  };

  const pushBtn = (array, index) => {
    const initObj = {
      // destination: props.popularcities[2].airportCode,
      // destinationObj: props.popularcities[2],
      // origin: props.popularcities[5].airportCode,
      // originObj: props.popularcities[5],
      // destination: props.values.sectors[props.values.sectors.length - 1].origin,
      // destinationObj: props.values.sectors[props.values.sectors.length - 1].originObj,
      origin: props.values.sectors[props.values.sectors.length - 1].destination,
      originObj:
        props.values.sectors[props.values.sectors.length - 1].destinationObj,
      destination: "",
      destinationObj: null,
      // origin:"",
      // originObj: "",
      departDate: dateFnsFormat(
        props.values.sectors[0].departDateDisplay,
        "yyyy-MM-dd"
      ),
      departDateDisplay: props.values.sectors[0].departDateDisplay,
    };
    array.push(initObj);
  };

  const onChangeReturnDateDisplay = (e, v) => {
    props.setfieldvalue(`returnDateDisplay`, e);
    props.setfieldvalue(`returnDate`, dateFnsFormat(e, "yyyy-MM-dd"));
  };

  const toggleRoundTrip = () => {
    if (props.values.mode === "ONE") {
      props.setfieldvalue("mode", "ROUND");
      props.settripmode("ROUND");
    } else {
      props.setfieldvalue("mode", "ONE");
      props.settripmode("ONE");
    }
  };

  React.useEffect(() => {
    if (!domesticCarriers || !internationalCarriers) {
      getAirlineData();
    }
  }, []);

  React.useEffect(() => {
    if (!isLoading) {
      if (fromCountry === "IN" && toCountry === "IN") {
        setCarrier(domesticCarriers);
      } else {
        setCarrier(internationalCarriers);
      }
    }
  }, [fromCountry, toCountry]);

  React.useEffect(() => {
    if (props.values.mode == "MULTICITY" && props.values.sectors.length === 1) {
      pushBtn(props.arrayhelpers, 0);
    }
  }, [props.values.mode]);

  React.useEffect(() => {
    // console.log("fetchingAirports",fetchingAirports)
    // console.log("airportList",airportList)
    if (airportList && airportList.length > 0) {
      setFetchingAirports(false);
    }
  }, [airportList]);

  return (
    <div className={classes.itinRoot}>
      <div className="itin-card">
        {props.values.mode !== "MULTICITY" && (
          <div className="itin-row">
            <div className="location-container">
              <div className="inner-row">
                <div className="itin-col-50 hover location-wrapper locationWrap">
                  <OaPopperAutocomplete
                    loading={fetchingAirports}
                    className="autocomplete"
                    name={`sectors[0].originObj`}
                    PopperComponent={customPopperTo}
                    options={airportList || []}
                    getOptionLabel={(o) =>
                      o ? `${o.cityName} ${o.airportCode}` : ""
                    }
                    value={props.values.sectors[0].originObj}
                    // value={''}
                    openOnFocus
                    getOptionSelected={(option, value) =>
                      option.cityName === value.cityName
                    }
                    onKeyUp={(event) => {
                      fetchAirports(event.target.value.trim());
                    }}
                    onChange={(event, newValue, reason) => {
                      if (reason === "clear") {
                        setAirportList(props.popularcities);
                      } else {
                        changeHandler("from", newValue?.countryCode);
                        props.setfieldvalue(`sectors[0].originObj`, newValue);
                        props.setfieldvalue(
                          `sectors[0].origin`,
                          newValue.airportCode
                        );
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        color="secondary"
                        inputProps={{
                          ...params.inputProps,
                          autocomplete: "new-password",
                        }}
                      />
                    )}
                    renderOption={(optionsData, propes) =>
                      renderOptionsComp(optionsData, propes)
                    }
                    disablePortal={true}
                    secondarytxt={
                      props.values.sectors[0].originObj &&
                      `[${props.values.sectors[0].originObj.airportCode}] ${props.values.sectors[0].originObj.airportDesc} ${props.values.sectors[0].originObj.countryCode}`
                    }
                    labeltxt="FROM"
                    inputtext={props.values.sectors[0].originObj?.cityName}
                    clearonfocus={"true"}
                  />
                </div>
                {props.values.mode !== "MULTICITY" && (
                  <div className="itin-swapper-col">
                    <IconButton
                      onClick={() => {
                        const originTemp = {
                          str: props.values.sectors[0].origin,
                          obj: props.values.sectors[0].originObj,
                        };
                        props.setfieldvalue(
                          `sectors[0].originObj`,
                          props.values.sectors[0].destinationObj
                        );
                        props.setfieldvalue(
                          `sectors[0].origin`,
                          props.values.sectors[0].destination
                        );
                        props.setfieldvalue(
                          `sectors[0].destinationObj`,
                          originTemp.obj
                        );
                        props.setfieldvalue(
                          `sectors[0].destination`,
                          originTemp.str
                        );
                      }}
                      aria-label="delete"
                      className="swap-icon-button"
                    >
                      <img src={swiperIcon} alt="" />
                    </IconButton>
                  </div>
                )}
                <div className="itin-col-50 hover location-wrapper ">
                  <OaPopperAutocomplete
                    loading={fetchingAirports}
                    className="autocomplete"
                    name={`sectors[0].destinationObj`}
                    PopperComponent={customPopperFrom}
                    options={airportList || []}
                    getOptionLabel={(o) =>
                      o ? `${o.cityName} ${o.airportCode}` : ""
                    }
                    value={props.values.sectors[0].destinationObj || ""}
                    openOnFocus
                    getOptionSelected={(option, value) =>
                      option.airportCode == value.airportCode
                    }
                    onKeyUp={(event) =>
                      fetchAirports(event.target.value.trim())
                    }
                    onChange={(event, newValue, reason) => {
                      if (reason === "clear") {
                        setAirportList(props.popularcities);
                      } else {
                        changeHandler("from", newValue?.countryCode);
                        props.setfieldvalue(
                          `sectors[0].destinationObj`,
                          newValue
                        );
                        props.setfieldvalue(
                          `sectors[0].destination`,
                          newValue.airportCode
                        );
                        if (props.values.mode === "MULTICITY") {
                          props.setfieldvalue(`sectors[1].originObj`, newValue);
                          props.setfieldvalue(
                            `sectors[1].origin`,
                            newValue.airportCode
                          );
                        }
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        color="secondary"
                        inputProps={{
                          ...params.inputProps,
                          autocomplete: "new-password",
                        }}
                      />
                    )}
                    renderOption={(optionsData, propes) =>
                      renderOptionsComp(optionsData, propes)
                    }
                    disablePortal={true}
                    secondarytxt={
                      props.values.sectors[0].destinationObj &&
                      `[${props.values.sectors[0].destinationObj.airportCode}] ${props.values.sectors[0].destinationObj.airportDesc} ${props.values.sectors[0].destinationObj.countryCode}`
                    }
                    labeltxt="TO"
                    inputtext={props.values.sectors[0].destinationObj?.cityName}
                    clearonfocus={"true"}
                  />
                </div>
              </div>
            </div>

            <div className="rest-container">
              <div className="inner-row">
                <div className="departureReturnContainer">
                  <div className="hover date-wrapper departure-date">
                    <OaOutlinedDatePicker
                      showDisabledMonthNavigation
                      className="date-picker"
                      name={`sectors[0].departDateDisplay`}
                      minDate={new Date()}
                      customInput={
                        <TextField
                          id="standard-basic"
                          variant="standard"
                          fullWidth={isMobileScreen}
                        />
                      }
                      selected={props.values.sectors[0].departDateDisplay}
                      onChange={(e, v) => {
                        //   console.log("props.values.mode " + props.values.mode);
                        if (props.values.mode === "MULTICITY") {
                          for (
                            let i = 0;
                            i < props.values.sectors.length;
                            i++
                          ) {
                            const t1 = new Date(e).getTime();
                            const t2 = new Date(
                              props.values.sectors[i].departDateDisplay
                            ).getTime();
                            if (t2 < t1) {
                              props.setfieldvalue(
                                `sectors[${i}].departDateDisplay`,
                                e
                              );
                              props.setfieldvalue(
                                `sectors[${i}].departDate`,
                                dateFnsFormat(e, "yyyy-MM-dd")
                              );
                            }
                          }
                        } else {
                          props.setfieldvalue(
                            `sectors[0].departDateDisplay`,
                            e
                          );
                          props.setfieldvalue(
                            `sectors[0].departDate`,
                            dateFnsFormat(e, "yyyy-MM-dd")
                          );
                        }
                        if (Math.abs(props.values.returnDateDisplay) < Math.abs(e)) {
                          props.setfieldvalue(`returnDateDisplay`, e);
                          props.setfieldvalue(
                            `returnDate`,
                            dateFnsFormat(e, "yyyy-MM-dd")
                          );
                        }
                      }}
                      monthsShown={2}
                      dateFormat="dd MMM yyyy"
                      labeltxt="DEPARTURE DATE"
                      secondarytxt={
                        days[props.values.sectors[0].departDateDisplay.getDay()]
                      }
                      showinput={false}
                    />
                  </div>
                  {props.values.mode !== "MULTICITY" && (
                    <div className="hover date-wrapper return-date  toggler-div">
                      <OaOutlinedDatePicker
                        showDisabledMonthNavigation
                        className="date-picker"
                        name={`returnDateDisplay`}
                        minDate={props.values.sectors[0].departDateDisplay}
                        customInput={
                          <TextField
                            id="standard-basic"
                            variant="standard"
                            fullWidth={isMobileScreen}
                          />
                        }
                        selected={props.values.returnDateDisplay}
                        onChange={onChangeReturnDateDisplay}
                        monthsShown={2}
                        dateFormat="dd MMM yyyy"
                        labeltxt="RETURN DATE"
                        secondarytxt={
                          props.values.mode == "ROUND" ||
                          props.values.mode == "ROUND-SP"
                            ? days[props.values.returnDateDisplay.getDay()]
                            : ""
                        }
                        showinput={false}
                        disabledtxt={null}
                      />
                      {props.values.mode === "ROUND" && (
                        <span
                          className="oa-form-label-btn"
                          onClick={toggleRoundTrip}
                        >
                          <Close />
                        </span>
                      )}
                      {props.values.mode === "ONE" && (
                        <div
                          className="return-date-overlay"
                          onClick={toggleRoundTrip}
                        ></div>
                      )}
                    </div>
                  )}
                </div>

                <div className="travellersSubmit">
                  <div className="itin-col-25 hover pax-wrapper">
                    <div className="pax-input-div">
                      <div
                        className="passenger_div"
                        onClick={() => setDropdownTraveler(!dropdownTraveler)}
                      >
                        <p className="trvl">TRAVELLER & CLASS</p>
                        <p className="trvl-count">
                          <span className="trval-tnum">
                            {
                              (totalTravelers =
                                Number(props.values.adultPax) +
                                Number(props.values.childPax) +
                                Number(props.values.infantPax))
                            }
                          </span>
                          <span className="trval-txt">Traveller(s)</span>{" "}
                          <i className="fas fa-chevron-down"></i>
                        </p>
                        <p className="trvl-class">
                          {props.values.preferredclass === "E"
                            ? "Economy"
                            : props.values.preferredclass === "ER"
                            ? "Premium Economy"
                            : props.values.preferredclass === "B"
                            ? "Business"
                            : ""}
                        </p>
                      </div>

                      {dropdownTraveler && (
                        <div className="passenger_div-show">
                          <Grid
                            item
                            sm={12}
                            className={classes.FlightSection_passdet}
                          >
                            <Grid
                              className={classes.gridRespColPassDet}
                              container
                              direction="column"
                              justifyContent="space-evenly"
                              alignItems="center"
                              spacing={2}
                            >
                              <Box className="counter-box">
                                <PassengerCount
                                  title="Adults"
                                  type="adult"
                                  val={props.values.adultPax}
                                  setVal={modifyPassengerCount}
                                  name="adultPax"
                                  totalPax={
                                    props.values.adultPax +
                                    props.values.childPax +
                                    props.values.infantPax
                                  }
                                />
                                <ErrorMessage
                                  name="adultPax"
                                  component="div"
                                  className="error"
                                />
                              </Box>
                              <Box className="counter-box">
                                <PassengerCount
                                  title="Children"
                                  type="child"
                                  val={props.values.childPax}
                                  setVal={modifyPassengerCount}
                                  name="childPax"
                                  totalPax={
                                    props.values.adultPax +
                                    props.values.childPax +
                                    props.values.infantPax
                                  }
                                />
                                <ErrorMessage
                                  name="childPax"
                                  component="div"
                                  className="error"
                                />
                              </Box>
                              <Box className="counter-box">
                                <PassengerCount
                                  title="Infants"
                                  type="infant"
                                  val={props.values.infantPax}
                                  setVal={modifyPassengerCount}
                                  name="infantPax"
                                  totalPax={
                                    props.values.adultPax +
                                    props.values.childPax +
                                    props.values.infantPax
                                  }
                                />
                                <ErrorMessage
                                  name="noInfant"
                                  component="div"
                                  className="error"
                                />
                              </Box>
                            </Grid>

                            <div className="cabinType-dev">
                              <FormControl component="fieldset">
                                <RadioGroup
                                  aria-label="cabinType"
                                  value={props.values.preferredclass}
                                  name="preferredclass"
                                  onChange={(e, v) =>
                                    v &&
                                    props.setfieldvalue("preferredclass", v)
                                  }
                                >
                                  <FormControlLabel
                                    value="E"
                                    control={<Radio />}
                                    label="Economy"
                                  />
                                  <FormControlLabel
                                    value="ER"
                                    control={<Radio />}
                                    label="Premium Economy"
                                  />
                                  <FormControlLabel
                                    value="B"
                                    control={<Radio />}
                                    label="Business"
                                  />
                                </RadioGroup>
                                <ErrorMessage
                                  name="preferredclass"
                                  component="div"
                                  className="error"
                                />
                              </FormControl>
                              <Button
                                onClick={() =>
                                  setDropdownTraveler(!dropdownTraveler)
                                }
                              >
                                Done
                              </Button>
                            </div>
                          </Grid>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="submitBtn hover non-muct-action-wrapper">
                    {props.values.mode !== "MULTICITY" && (
                      <Button className="search-button" type="submit">
                        {/* <img src={planeIcon} height={20} /> */}
                        Book ticket
                      </Button>
                    )}
                  </div>
                </div>

                {props.values.mode === "MULTICITY" && (
                  <div className="itin-col-25"></div>
                )}
              </div>
            </div>
          </div>
        )}

        {props.values.mode === "MULTICITY" && (
          <div className="itin-row maincontainer-mulitcity">
            <div className="location-container-multicity">
              <div className="inner-row">
                <div className="itin-col-50 hover location-wrapper">
                  <OaPopperAutocomplete
                    loading={fetchingAirports}
                    className="autocomplete"
                    name={`sectors[0].originObj`}
                    PopperComponent={customPopperTo}
                    options={airportList || []}
                    getOptionLabel={(o) =>
                      o ? `${o.cityName} ${o.airportCode}` : ""
                    }
                    value={props.values.sectors[0].originObj}
                    // value={''}
                    openOnFocus
                    getOptionSelected={(option, value) =>
                      option.cityName === value.cityName
                    }
                    onKeyUp={(event) => {
                      fetchAirports(event.target.value.trim());
                    }}
                    onChange={(event, newValue, reason) => {
                      if (reason === "clear") {
                        setAirportList(props.popularcities);
                      } else {
                        changeHandler("from", newValue?.countryCode);
                        props.setfieldvalue(`sectors[0].originObj`, newValue);
                        props.setfieldvalue(
                          `sectors[0].origin`,
                          newValue.airportCode
                        );
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        color="secondary"
                        inputProps={{
                          ...params.inputProps,
                          autocomplete: "new-password",
                        }}
                      />
                    )}
                    renderOption={(optionsData, propes) =>
                      renderOptionsComp(optionsData, propes)
                    }
                    disablePortal={true}
                    secondarytxt={
                      props.values.sectors[0].originObj &&
                      `[${props.values.sectors[0].originObj.airportCode}] ${props.values.sectors[0].originObj.airportDesc} ${props.values.sectors[0].originObj.countryCode}`
                    }
                    labeltxt="FROM"
                    inputtext={props.values.sectors[0].originObj?.cityName}
                    clearonfocus={"true"}
                  />
                </div>
                {props.values.mode !== "MULTICITY" && (
                  <div className="itin-swapper-col">
                    <IconButton
                      onClick={() => {
                        const originTemp = {
                          str: props.values.sectors[0].origin,
                          obj: props.values.sectors[0].originObj,
                        };
                        props.setfieldvalue(
                          `sectors[0].originObj`,
                          props.values.sectors[0].destinationObj
                        );
                        props.setfieldvalue(
                          `sectors[0].origin`,
                          props.values.sectors[0].destination
                        );
                        props.setfieldvalue(
                          `sectors[0].destinationObj`,
                          originTemp.obj
                        );
                        props.setfieldvalue(
                          `sectors[0].destination`,
                          originTemp.str
                        );
                      }}
                      aria-label="delete"
                      className="swap-icon-button"
                    >
                      <img src={swiperIcon} alt="" />
                    </IconButton>
                  </div>
                )}
                <div className="itin-col-50 hover location-wrapper destination-container">
                  <OaPopperAutocomplete
                    loading={fetchingAirports}
                    className="autocomplete"
                    name={`sectors[0].destinationObj`}
                    PopperComponent={customPopperFrom}
                    options={airportList || []}
                    getOptionLabel={(o) =>
                      o ? `${o.cityName} ${o.airportCode}` : ""
                    }
                    value={props.values.sectors[0].destinationObj || ""}
                    openOnFocus
                    getOptionSelected={(option, value) =>
                      option.airportCode == value.airportCode
                    }
                    onKeyUp={(event) =>
                      fetchAirports(event.target.value.trim())
                    }
                    onChange={(event, newValue, reason) => {
                      if (reason === "clear") {
                        setAirportList(props.popularcities);
                      } else {
                        changeHandler("from", newValue?.countryCode);
                        props.setfieldvalue(
                          `sectors[0].destinationObj`,
                          newValue
                        );
                        props.setfieldvalue(
                          `sectors[0].destination`,
                          newValue.airportCode
                        );
                        if (props.values.mode === "MULTICITY") {
                          props.setfieldvalue(`sectors[1].originObj`, newValue);
                          props.setfieldvalue(
                            `sectors[1].origin`,
                            newValue.airportCode
                          );
                        }
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        color="secondary"
                        inputProps={{
                          ...params.inputProps,
                          autocomplete: "new-password",
                        }}
                      />
                    )}
                    renderOption={(optionsData, propes) =>
                      renderOptionsComp(optionsData, propes)
                    }
                    disablePortal={true}
                    secondarytxt={
                      props.values.sectors[0].destinationObj &&
                      `[${props.values.sectors[0].destinationObj.airportCode}] ${props.values.sectors[0].destinationObj.airportDesc} ${props.values.sectors[0].destinationObj.countryCode}`
                    }
                    labeltxt="TO"
                    inputtext={props.values.sectors[0].destinationObj?.cityName}
                    clearonfocus={"true"}
                  />
                </div>
              </div>
            </div>

            <div className="rest-container-multicity">
              <div className="inner-row">
                <div className="departureReturnContainer">
                  <div className="hover date-wrapper departure-date">
                    <OaOutlinedDatePicker
                      showDisabledMonthNavigation
                      className="date-picker"
                      name={`sectors[0].departDateDisplay`}
                      minDate={new Date()}
                      customInput={
                        <TextField
                          id="standard-basic"
                          variant="standard"
                          fullWidth={isMobileScreen}
                        />
                      }
                      selected={props.values.sectors[0].departDateDisplay}
                      onChange={(e, v) => {
                        //   console.log("props.values.mode " + props.values.mode);
                        if (props.values.mode === "MULTICITY") {
                          for (
                            let i = 0;
                            i < props.values.sectors.length;
                            i++
                          ) {
                            const t1 = new Date(e).getTime();
                            const t2 = new Date(
                              props.values.sectors[i].departDateDisplay
                            ).getTime();
                            if (t2 < t1) {
                              props.setfieldvalue(
                                `sectors[${i}].departDateDisplay`,
                                e
                              );
                              props.setfieldvalue(
                                `sectors[${i}].departDate`,
                                dateFnsFormat(e, "yyyy-MM-dd")
                              );
                            }
                          }
                        } else {
                          props.setfieldvalue(
                            `sectors[0].departDateDisplay`,
                            e
                          );
                          props.setfieldvalue(
                            `sectors[0].departDate`,
                            dateFnsFormat(e, "yyyy-MM-dd")
                          );
                        }
                        props.setfieldvalue(`returnDateDisplay`, e);
                        props.setfieldvalue(
                          `returnDate`,
                          dateFnsFormat(e, "yyyy-MM-dd")
                        );
                      }}
                      monthsShown={2}
                      dateFormat="dd MMM yyyy"
                      labeltxt="DEPARTURE DATE"
                      secondarytxt={
                        days[props.values.sectors[0].departDateDisplay.getDay()]
                      }
                      showinput={false}
                    />
                  </div>
                  {props.values.mode !== "MULTICITY" && (
                    <div className="hover date-wrapper return-date  toggler-div">
                      <OaOutlinedDatePicker
                        showDisabledMonthNavigation
                        className="date-picker"
                        name={`returnDateDisplay`}
                        minDate={props.values.sectors[0].departDateDisplay}
                        customInput={
                          <TextField
                            id="standard-basic"
                            variant="standard"
                            fullWidth={isMobileScreen}
                          />
                        }
                        selected={props.values.returnDateDisplay}
                        onChange={onChangeReturnDateDisplay}
                        monthsShown={2}
                        dateFormat="dd MMM yyyy"
                        labeltxt="RETURN DATE"
                        secondarytxt={
                          props.values.mode == "ROUND" ||
                          props.values.mode == "ROUND-SP"
                            ? days[props.values.returnDateDisplay.getDay()]
                            : ""
                        }
                        showinput={false}
                        disabledtxt={null}
                      />
                      {props.values.mode === "ROUND" && (
                        <span
                          className="oa-form-label-btn"
                          onClick={toggleRoundTrip}
                        >
                          <Close />
                        </span>
                      )}
                      {props.values.mode === "ONE" && (
                        <div
                          className="return-date-overlay"
                          onClick={toggleRoundTrip}
                        ></div>
                      )}
                    </div>
                  )}
                </div>

                {props.values.mode !== "MULTICITY" && (
                  <div className="itin-col-25 hover pax-wrapper">
                    <div className="pax-input-div">
                      <div
                        className="passenger_div"
                        onClick={() => setDropdownTraveler(!dropdownTraveler)}
                      >
                        <p className="trvl">TRAVELLER & CLASS</p>
                        <p className="trvl-count">
                          <span className="trval-tnum">
                            {
                              (totalTravelers =
                                Number(props.values.adultPax) +
                                Number(props.values.childPax) +
                                Number(props.values.infantPax))
                            }
                          </span>
                          <span className="trval-txt">Traveller(s)</span>{" "}
                          <i className="fas fa-chevron-down"></i>
                        </p>
                        <p className="trvl-class">
                          {props.values.preferredclass === "E"
                            ? "Economy"
                            : props.values.preferredclass === "ER"
                            ? "Premium Economy"
                            : props.values.preferredclass === "B"
                            ? "Business"
                            : ""}
                        </p>
                      </div>

                      {dropdownTraveler && (
                        <div className="passenger_div-show">
                          <Grid
                            item
                            sm={12}
                            className={classes.FlightSection_passdet}
                          >
                            <Grid
                              className={classes.gridRespColPassDet}
                              container
                              direction="column"
                              justifyContent="space-evenly"
                              alignItems="center"
                              spacing={2}
                            >
                              <Box className="counter-box">
                                <PassengerCount
                                  title="Adults"
                                  type="adult"
                                  val={props.values.adultPax}
                                  setVal={modifyPassengerCount}
                                  name="adultPax"
                                  totalPax={
                                    props.values.adultPax +
                                    props.values.childPax +
                                    props.values.infantPax
                                  }
                                />
                                <ErrorMessage
                                  name="adultPax"
                                  component="div"
                                  className="error"
                                />
                              </Box>
                              <Box className="counter-box">
                                <PassengerCount
                                  title="Children"
                                  type="child"
                                  val={props.values.childPax}
                                  setVal={modifyPassengerCount}
                                  name="childPax"
                                  totalPax={
                                    props.values.adultPax +
                                    props.values.childPax +
                                    props.values.infantPax
                                  }
                                />
                                <ErrorMessage
                                  name="childPax"
                                  component="div"
                                  className="error"
                                />
                              </Box>
                              <Box className="counter-box">
                                <PassengerCount
                                  title="Infants"
                                  type="infant"
                                  val={props.values.infantPax}
                                  setVal={modifyPassengerCount}
                                  name="infantPax"
                                  totalPax={
                                    props.values.adultPax +
                                    props.values.childPax +
                                    props.values.infantPax
                                  }
                                />
                                <ErrorMessage
                                  name="noInfant"
                                  component="div"
                                  className="error"
                                />
                              </Box>
                            </Grid>

                            <div className="cabinType-dev">
                              <FormControl component="fieldset">
                                <RadioGroup
                                  aria-label="cabinType"
                                  value={props.values.preferredclass}
                                  name="preferredclass"
                                  onChange={(e, v) =>
                                    v &&
                                    props.setfieldvalue("preferredclass", v)
                                  }
                                >
                                  <FormControlLabel
                                    value="E"
                                    control={<Radio />}
                                    label="Economy"
                                  />
                                  <FormControlLabel
                                    value="ER"
                                    control={<Radio />}
                                    label="Premium Economy"
                                  />
                                  <FormControlLabel
                                    value="B"
                                    control={<Radio />}
                                    label="Business"
                                  />
                                </RadioGroup>
                                <ErrorMessage
                                  name="preferredclass"
                                  component="div"
                                  className="error"
                                />
                              </FormControl>
                              <Button
                                onClick={() =>
                                  setDropdownTraveler(!dropdownTraveler)
                                }
                              >
                                Done
                              </Button>
                            </div>
                          </Grid>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {props.values.mode === "MULTICITY" && (
                  <div className="itin-col-25 hover pax-wrapper pax-wrapper-multicity">
                    <div className="pax-input-div">
                      <div
                        className="passenger_div"
                        onClick={() => setDropdownTraveler(!dropdownTraveler)}
                      >
                        <p className="trvl">TRAVELLER & CLASS</p>
                        <p className="trvl-count">
                          <span className="trval-tnum">
                            {
                              (totalTravelers =
                                Number(props.values.adultPax) +
                                Number(props.values.childPax) +
                                Number(props.values.infantPax))
                            }
                          </span>
                          <span className="trval-txt">Traveller(s)</span>{" "}
                          <i className="fas fa-chevron-down"></i>
                        </p>
                        <p className="trvl-class">
                          {props.values.preferredclass === "E"
                            ? "Economy"
                            : props.values.preferredclass === "ER"
                            ? "Premium Economy"
                            : props.values.preferredclass === "B"
                            ? "Business"
                            : ""}
                        </p>
                      </div>

                      {dropdownTraveler && (
                        <div className="passenger_div-show">
                          <Grid
                            item
                            sm={12}
                            className={classes.FlightSection_passdet}
                          >
                            <Grid
                              className={classes.gridRespColPassDet}
                              container
                              direction="column"
                              justifyContent="space-evenly"
                              alignItems="center"
                              spacing={2}
                            >
                              <Box className="counter-box">
                                <PassengerCount
                                  title="Adults"
                                  type="adult"
                                  val={props.values.adultPax}
                                  setVal={modifyPassengerCount}
                                  name="adultPax"
                                  totalPax={
                                    props.values.adultPax +
                                    props.values.childPax +
                                    props.values.infantPax
                                  }
                                />
                                <ErrorMessage
                                  name="adultPax"
                                  component="div"
                                  className="error"
                                />
                              </Box>
                              <Box className="counter-box">
                                <PassengerCount
                                  title="Children"
                                  type="child"
                                  val={props.values.childPax}
                                  setVal={modifyPassengerCount}
                                  name="childPax"
                                  totalPax={
                                    props.values.adultPax +
                                    props.values.childPax +
                                    props.values.infantPax
                                  }
                                />
                                <ErrorMessage
                                  name="childPax"
                                  component="div"
                                  className="error"
                                />
                              </Box>
                              <Box className="counter-box">
                                <PassengerCount
                                  title="Infants"
                                  type="infant"
                                  val={props.values.infantPax}
                                  setVal={modifyPassengerCount}
                                  name="infantPax"
                                  totalPax={
                                    props.values.adultPax +
                                    props.values.childPax +
                                    props.values.infantPax
                                  }
                                />
                                <ErrorMessage
                                  name="noInfant"
                                  component="div"
                                  className="error"
                                />
                              </Box>
                            </Grid>

                            <div className="cabinType-dev">
                              <FormControl component="fieldset">
                                <RadioGroup
                                  aria-label="cabinType"
                                  value={props.values.preferredclass}
                                  name="preferredclass"
                                  onChange={(e, v) =>
                                    v &&
                                    props.setfieldvalue("preferredclass", v)
                                  }
                                >
                                  <FormControlLabel
                                    value="E"
                                    control={<Radio />}
                                    label="Economy"
                                  />
                                  <FormControlLabel
                                    value="ER"
                                    control={<Radio />}
                                    label="Premium Economy"
                                  />
                                  <FormControlLabel
                                    value="B"
                                    control={<Radio />}
                                    label="Business"
                                  />
                                </RadioGroup>
                                <ErrorMessage
                                  name="preferredclass"
                                  component="div"
                                  className="error"
                                />
                              </FormControl>
                              <Button
                                onClick={() =>
                                  setDropdownTraveler(!dropdownTraveler)
                                }
                              >
                                Done
                              </Button>
                            </div>
                          </Grid>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {props.values.mode !== "MULTICITY" && (
                  <div className="submitBtn hover non-muct-action-wrapper">
                    <Button className="search-button" type="submit">
                      <img src={planeIcon} height={20} />
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {props.values.mode === "MULTICITY" &&
          props.values.sectors
            .slice(1, props.values.sectors.length)
            .map((sector, indexs) => (
              <FLightMultiCitySection
                indx={indexs}
                arrayhelpers={props.arrayhelpers}
                setfieldvalue={props.setfieldvalue}
                values={props.values}
                popularcities={props.popularcities}
                ismobilescreen={isMobileScreen}
                days={days}
                key={indexs}
                startDate={props.startDate}
              />
            ))}
      </div>
    </div>
  );
};

export default FlightItinSection;

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const customPopperFrom = function(props) {
  // console.log("props", props);
  return (
    <Popper
      {...props}
      // anchorEl={departAutocompleteEl.current.children[0]}
      placement="bottom"
      style={{}}
    />
  );
};

const customPopperTo = function(props) {
  return <Popper {...props} placement="bottom" style={{}} />;
};

const useStyles = makeStyles((theme) => ({
  itinRoot: {
    "& .autocomplete-wrapper": {
      // width:'319.6px',
      marginTop: 50,
    },
    "& .location-container": {
      width: "100%",
      marginBottom: 5,
      // [theme.breakpoints.down(1050)]: {
      //   width: "40%",
      // },
      "& .oa-form-element-card": {
        borderRadius: 10,
        border: `2px solid ${theme.palette.secondary.main}`,
        paddingRight: 5,
      },
    },
    "& .location-container-multicity": {
      width: "50%",
      // paddingRight: 5,
      // marginRight: 5,
      "& .destination-container": {
        "& .oa-form-element-secondary": {
          maxWidth: 170,
          [theme.breakpoints.down(1500)]: {
            width: 130,
          },
          [theme.breakpoints.down(1370)]: {
            width: 110,
          },
          [theme.breakpoints.down(1250)]: {
            width: 100,
          },
          [theme.breakpoints.down(1180)]: {
            width: 80,
          },
          [theme.breakpoints.down(1040)]: {
            width: 50,
          },
        },
      },

      "& .oa-form-element-card": {
        borderRadius: 10,
        border: `2px solid ${theme.palette.secondary.main}`,
      },
    },
    "& .rest-container": {
      width: "100%",
      justifyContent: "space-between",
      // [theme.breakpoints.down(1050)]: {
      //   width: "60%",
      // },
      "& .inner-row": {
        flexDirection: "column",
      },
      "& .date-wrapper": {
        width: "50%",
      },
      "& .departureReturnContainer": {
        display: "flex",
        width: "100%",
        marginBottom: 5,
      },
    },
    "& .travellersSubmit": {
      display: "flex",
      alignItems: "center",
      width: "100%",
      "& .pax-wrapper": {
        width: "49.5%",
      },
      "& .submitBtn": {
        width: "50%",
      },
    },
    "& .rest-container-multicity": {
      width: "50%",
      "& .departureReturnContainer": {
        display: "flex",
        width: "50%",
        [theme.breakpoints.down(1400)]: {
          width: "70%",
        },
      },
      "& .pax-wrapper": {
        width: "50%",
      },
      "& .departure-date": {
        // border: `2px solid ${theme.palette.primary.disabled}`,
        // border: `2px solid ${theme.palette.background.primary}`,
        borderRight: "none",
        borderRadius: 10,
        borderTopRightRadius: 0,
        width: "100%",
        borderBottomRightRadius: 0,
        "& .oa-form-element-card": {
          paddingRight: 0,
          height: 100,
          // border: `2px solid ${theme.palette.secondary.main}`,
        },
        // borderTopRightRadius:
      },
    },
    "& .itin-card": {
      // boxShadow: "2px 2px 10px 0px rgba(133,131,133,1)",
      // MozBoxShadow: "2px 2px 10px 0px rgba(133,131,133,1)",
      // WebkitBoxShadow: "2px 2px 10px 0px rgba(133,131,133,1)",
      width: "95%",
      // backgroundColor: "rgba(255,255,255,0.5)",
      borderRadius: 5,
      position: "relative",
    },
    "& .multicitysection": {
      marginTop: 5,
      "& .datewrapperContainer": {
        [theme.breakpoints.down(1400)]: {
          width: "70%",
        },
      },
      "& .location-wrapper": {
        // paddingRight: 5,
        // marginRight: 5,
        "& .oa-form-element-secondary": {
          maxWidth: 170,
        },
      },
      // flexDirection: "row !important",
      "& .date-wrapper": {
        // border: `2px solid ${theme.palette.primary.disabled}`,
        // height: "unset",
        borderRadius: 5,
        "& .oa-form-element-card": {
          height: 100,
        },
      },
      "& .departure-date": {
        // border: `2px solid ${theme.palette.primary.disabled}`,
        // border: `2px solid ${theme.palette.background.primary}`,
        borderRight: "none",
        borderRadius: 10,
        borderTopRightRadius: 0,
        width: "100%",
        borderBottomRightRadius: 0,
        "& .oa-form-element-card": {
          paddingRight: 0,
        },
        // borderTopRightRadius:
      },
    },
    "& .itin-row": {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      flexDirection: "column",
      // height: 100,
    },
    "& .inner-row": {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    "& .itin-swapper-col": {
      position: "relative",
      zIndex: 2,
      height: "100%",
      "& .swap-icon-button": {
        position: "absolute",
        top: 30,
        left: -20,
        zIndex: 20,
        "& img": {
          height: 35,
          borderRadius: 50,
        },
        "& .MuiIconButton-label": {
          padding: 0,
        },
        "&.MuiIconButton-root": {
          borderRadius: 50,
          padding: 0,
        },
      },
    },
    "& .itin-col-25": {
      width: "25%",
    },
    "& .itin-col-40": {
      width: "40%",
    },
    "& .itin-col-50": {
      width: "50%",
    },
    "& .itin-col-60": {
      width: "60%",
    },
    "& .hover": {
      "&:hover": {
        background: theme.palette.secondary.main,
        borderRadius: "10px",
      },
    },
    "& .pdl-15": {
      marginLeft: 5,
      marginRight: 10,
    },
    "& .border-right": {
      borderRight: `solid 0.5px ${theme.palette.primary.disabled}`,
    },
    "& .border-top": {
      borderTop: `solid 0.5px ${theme.palette.primary.disabled}`,
    },
    "& .locationWrap": {
      "& .oa-form-element-secondary": {
        fontWeight: 400,
        fontSize: 11.5,
        [theme.breakpoints.down(1100)]: {
          width: 200,
        },
        [theme.breakpoints.down(1000)]: {
          width: 170,
        },
      },
    },
    "& .location-wrapper": {
      borderRadius: 10,
      marginRight: 5,
      "& .oa-form-element-card": {
        padding: 15,
        border: `2px solid ${theme.palette.secondary.main}`,
        height: 100,
        backgroundColor: "rgba(255,255,255,0.5)",

        // border: `2px solid ${theme.palette.background.primary}`,
      },
      "& .oa-form-element-label": {
        fontSize: 13,
        fontWeight: 400,
      },
      "& .oa-form-element-primary": {
        fontSize: 16,
        fontWeight: 600,
        margin: "4px 0 3px",
        [theme.breakpoints.down(1050)]: {
          fontSize: 12,
        },
      },
      "& .oa-form-element-secondary": {
        fontWeight: 400,
        fontSize: 11.5,
      },
    },
    "& .departure-date": {
      // border: `2px solid ${theme.palette.primary.disabled}`,
      // border: `2px solid ${theme.palette.background.primary}`,
      borderRight: "none",
      borderRadius: 10,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      "& .oa-form-element-card": {
        paddingRight: 2,
        border: `2px solid ${theme.palette.secondary.main}`,
      },
      // borderTopRightRadius:
    },
    "& .return-date": {
      // border: `2px solid ${theme.palette.primary.disabled}`,
      borderRadius: 10,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,

      // width: 140,
    },
    "& .date-wrapper": {
      marginRight: 5,
      "& .oa-form-element-card": {
        height: 95,
        padding: 15,
        backgroundColor: "rgba(255,255,255,0.5)",
        border: 0,
        border: `2px solid ${theme.palette.secondary.main}`,
        // border: `3px solid ${theme.palette.primary.disabled}`,
      },
      "& .oa-form-element-label": {
        fontSize: 13,
        fontWeight: 400,
      },
      "& .oa-form-element-primary": {
        fontSize: 12,
        fontWeight: 400,
        "& .react-datepicker-popper": {
          minWidth: 490,
        },
        margin: "0 0 3px",
        "& .big": {
          fontSize: 24,
          fontWeight: 600,
          marginRight: 4,
        },
        "& .icon-wrap": {
          "& .MuiSvgIcon-root": {
            color: theme.palette.primary.lightGray,
            fontSize: 19,
          },
        },
        "& .MuiInputBase-input": {
          fontWeight: 600,
          fontSize: "17px !important",
          color: theme.palette.primary.defaultText,
        },
      },
      "& .oa-form-element-secondary": {
        fontWeight: 400,
        fontSize: 11.5,
        marginTop: -3,
      },
    },
    "& .pax-wrapper-multicity": {
      position: "absolute",
      bottom: 0,
      left: 0,
    },
    "& .pax-wrapper": {
      borderRadius: 10,
      marginRight: 5,
      "& .pax-input-div": {
        height: 95,
        position: "relative",
        border: `2px solid ${theme.palette.secondary.main}`,
        backgroundColor: "rgba(255,255,255,0.5)",
        // border: `2px solid ${theme.palette.primary.disabled}`,
        borderRadius: 10,
        // marginRight: 5,
      },
      "& .passenger_div-show": {
        position: "absolute",
        zIndex: "9999",
        width: "190px",
        height: "auto",
        left: "0",
        top: "90px",
        borderRadius: "4px",
        padding: "10px",
        background: "#fff",
        boxSizing: "content-box",
        boxShadow: "0 0 20px 0 rgb(0 0 0 / 45%)",
        paddingTop: 20,

        "& .cabinType-dev": {
          marginTop: "13px",
          padding: "0 10px",
          borderTop: "solid 2px #eee",
          "& .MuiFormControlLabel-root": {
            margin: "5px 0",
          },
          "& .MuiFormControlLabel-label": {
            marginLeft: "6px",
            color: "#000",
            fontSize: "13px",
          },
          "& .MuiButton-root": {
            width: "100%",
            border: `1px solid ${theme.palette.secondary.main}`,
            color: theme.palette.secondary.main,
          },
          "& .Mui-checked": {
            color: theme.palette.secondary.main,
          },
        },
      },

      "& .passenger_div": {
        padding: "9px 12px 11px",
        display: "flex",
        width: "100%",
        flexDirection: "column",
        cursor: "pointer",
        "& p": {
          margin: 0,
          textTransform: "capitalize",
        },

        "& .trvl": {
          fontSize: "12px",
          fontWeight: "400",
          marginTop: "0.23rem",
          paddingBottom: "0.3rem",
          textTransform: "uppercase",
          [theme.breakpoints.down(1090)]: {
            fontSize: "10px",
          },
        },
        "& .trvl-count": {
          color: theme.palette.primary.defaultText,
          "& span": {
            margin: "0 0.1rem 0 0",
          },
          "& .trval-tnum": {
            fontSize: "25px",
            fontWeight: "700",
          },
          "& .trval-txt": {
            fontWeight: "600",
          },
        },
        "& .trvl-class": {
          fontSize: 12,
        },
      },
    },
    "& .submitBtn": {
      width: "150px !important",
      height: "40px !important",
    },
    "& .non-muct-action-wrapper": {
      position: "relative",
      height: 96,
      "& .search-button": {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        cursor: "pointer",
        background: theme.palette.buttons.primary,
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "1.1rem",
        fontWeight: 500,
        textTransform: "capitalize",
        color: theme.palette.secondary.contrastText,
        width: "100%",

        "&:hover": {
          background: theme.palette.secondary.sub,
        },
      },
    },
    "& .actionButtonContainer": {
      display: "flex",
      marginLeft: 10,
      width: 200,
      height: 100,
      alignItems: "center",
      position: "relative",
    },
    "& .upperContainer": {
      display: "flex",
      width: "100%",
      "& .departure-container": {
        "& .oa-form-element-secondary": {
          minWidth: 130,
          [theme.breakpoints.down(1300)]: {
            minWidth: 115,
          },
          [theme.breakpoints.down(1150)]: {
            minWidth: 105,
          },
          [theme.breakpoints.down(1100)]: {
            minWidth: 100,
          },
          [theme.breakpoints.down(1000)]: {
            minWidth: 90,
          },
        },
      },
    },
    "& .bottomContainer": {
      display: "flex",
      width: "100%",
    },

    "& .muct-action-wrapper": {
      position: "absolute",
      height: 100,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "15px 20px",
    },
    "& .multicity-search-btn": {
      background: theme.palette.background.primary,
      width: 230,
      height: 40,
      padding: "12px 0",
      color: theme.palette.secondary.contrastText,
      border: theme.palette.secondary.main,
      borderRadius: 10,
    },
    "& .multicity-sector-add": {
      height: 40,
      background: theme.palette.background.light,
      border: `solid 1px ${theme.palette.secondary.main}`,
      width: 210,
      fontSize: 14,
      padding: "12px 0",
      color: theme.palette.secondary.main,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: 5,
      borderRadius: 10,
    },
    "& .multicity-sector-remove": {
      cursor: "pointer",
      position: "absolute",
      right: -50,

      marginLeft: 20,
      height: 20,
      width: 40,
      background: theme.palette.secondary.main,
      color: theme.palette.primary.lightText,
      borderRadius: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .MuiSvgIcon-root": {
        fontSize: 14,
      },
    },
    "& .toggler-div": {
      position: "relative",
    },
    "& .oa-form-label-btn": {
      cursor: "pointer",
      position: "absolute",
      top: 15,
      right: 10,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      background: theme.palette.buttons.disabled,
      padding: 2,
      "& .MuiSvgIcon-root": {
        fontSize: "0.8rem",
        color: theme.palette.buttons.disabledContrastText,
      },
    },
    "& .return-date-overlay": {
      position: "absolute",
      background: "rgba(255,255,255,0.8)",
      backgroundColor: "rgba(255,255,255,0.7)",
      border: `2px solid ${theme.palette.primary.disabled}`,
      top: 0,
      border: 0,
      // borderRadius: 10,
      borderBottomRightRadius: 10,
      left: 0,
      right: 0,
      bottom: 0,
    },
    "& .maincontainer-mulitcity": {
      display: "flex",
      flexDirection: "row !important",
    },
    "& .date-picker": {
      width: "100%",
      "& .MuiInput-root": {
        height: 70,
        bottom: 25,
        position: "relative",
      },
    },
  },
}));
