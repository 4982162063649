import { Button, Divider, Grid, Typography,
    useMediaQuery } from "@material-ui/core";
  import GridContainer from "components/Grid/GridContainer";
  import React from "react";
  import { makeStyles , useTheme} from "@material-ui/core/styles";
  import Menu from "pages/b2c/component/Menu";
  import Profile from "pages/b2c/component/Profile";
  import CarrierImage from "assets/img/6E.png";
  import { withRouter } from "react-router";
  import moment from "moment";
  import { ArrowBack } from "@material-ui/icons";
  import { localforageGetItem } from "oautils/oaForageUtils";
  import { AppStateContext } from "layouts/AppStateProvider";
  
  const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: "100%",
      margin: "auto",
      paddingTop: "40px",
      paddingBottom: "40px",
    },
    typo1: {
      fontSize: "11px",
      fontWeight: 500,
      lineHeight: "1.5",
      color: "#212529",
      marginLeft: 5,
      letterSpacing: "0.5px !important",
    },
    typo2: {
      fontSize: "13px",
      paddingRight: "10px",
      paddingLeft: "10px",
      fontWeight: 700,
      color: "#212529",
      letterSpacing: "0.5px !important",
      borderRight: "1px solid",
    },
    typo3: {
      fontSize: "13px",
      fontWeight: 700,
      color: "#212529",
      letterSpacing: "0.5px !important",
      paddingLeft: "10px",
    },
    typo4: {
      fontSize: 12,
      color: "#000",
      fontWeight: 500,
    },
    typo5: {
      fontSize: 15,
      color: "#000",
      fontWeight: 500,
    },
    typo6: {
      fontSize: 16,
      color: "#000",
      fontWeight: 500,
    },
    typo7: {
      fontSize: 12,
      color: "grey",
      fontWeight: 400,
    },
    typo8: {
      fontSize: 15,
      fontWeight: 500,
      color: "grey",
    },
    typo9: {
      fontSize: 12,
      color: "grey",
      fontWeight: 500,
    },
    button: {
      background: "#2b5a85",
      color: "#fff",
      fontSize: 13,
      textAlign: "center",
      padding: 8,
      borderRadius: 10,
      textTransform: "capitalize",
      "&:hover": {
        background: "#2b5a85",
      },
    },
    backButton: {
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: theme.palette.primary.main,
      "& .MuiSvgIcon-root": {
        fontSize: 16,
        marginRight: 5,
      },
      "&:hover": {
        color: theme.palette.primary.sub,
      }
    }
  }));
  
  const FareDetailsB2B = (props) => {
    const classes = useStyles();
    const flightData = props?.flightData;
    const [userType, setUserType] = React.useState(null);
    const { setUserTypeb2c } = React.useContext(AppStateContext);
    const [userTypeAbv, setUserTypeAbv] = React.useState(null);

    // console.log(ticket)
  
    
  
    const getValuesFromLocalStorage = () => {
      localforageGetItem("user-details", function (err, value) {
        if (value) {
          setUserType(value.userType);
          setUserTypeb2c(value.userType);
          setUserTypeAbv(value.userTypeAbv);
        } else {
          console.log("err", err);
        }
      });
    };
    const theme = useTheme();
    
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  
    React.useEffect(() => {
      getValuesFromLocalStorage();
    }, []);
  
  
      //const rightPanelWidth = "9";
    //   console.log("Fare userType ", userType);
  
    return (
        <Grid container  justifyContent="center" style={{margin:"13px 0"}}>
        
            {(userType != "GUEST" && userType != "agent" && userType != "direct_agent") && userType!=null ?
          <>
          <Grid item md={3} style={{ padding: 0 }}>
            <Menu />
            <div style={{ marginLeft: 15, marginRight: 15, marginTop: 10 }}>
              <Profile />
            </div>
          </Grid>
            </>:""
            }
        
        
          <Grid
            item
            md={12}
            sx={12}
            sm={12}
            spacing={2}
            style={{
              background: "#fff",
              paddingBottom: "6px",
              textAlign: "center",
              border: "1px solid #bfb6b6",
              padding: "20px",
              borderRadius: "5px",
            }}
          >
            <Grid
              container
              item
              md={12}
              style={{
                background: "#fff",
                border: "1px solid #dcdcdc",
                borderRadius: "5px",
                boxShadow: "0px 0px 6px -1px #b3b3b3",
                marginBottom: 20,
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                style={{
                  background: "#e2e2e2",
                  padding: "10px",
                }}
              >
                <Grid item container md={4} xs={6}>
                  <Grid item>
                    <i
                      className="fa fa-plane"
                      aria-hidden="true"
                      style={{ opacity: 0.5, fontSize: 22 }}
                    ></i>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.typo1}>
                      Total Passenger(s):{" "}
                      {/* {ticket.onwardFlightDetails.summary.adult +
                        ticket.onwardFlightDetails.summary.child +
                        ticket.onwardFlightDetails.summary.infant} */}
                    </Typography>
                    <Typography
                      className={classes.typo1}
                      style={{ opacity: 0.7 }}
                    >
                        {`ADULT:${flightData?.flightSummary?.adultNo}  CHILD:${flightData?.flightSummary?.adultNo}  INFANT:${flightData?.flightSummary?.adultNo}`}
                      {/* {`ADULT:${ticket.onwardFlightDetails.summary.adult}  CHILD:${ticket.onwardFlightDetails.summary.child}  INFANT:${ticket.onwardFlightDetails.summary.infant}`} */}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container md={5} xs={6}>
                  <Grid item>
                    <Grid container justifyContent="flex-end">
                      <Typography className={classes.typo2}>
                        Departure Date:{" "}
                        {moment(
                                              flightData?.onwardJourney?.[0]?.departureDateTimeRaw

                        ).format("DD MMM'YY")}
                      </Typography>
                      <Typography className={classes.typo3}>
                        PNR No.
                        {flightData?.pnrNo}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item style={{display:"none"}}>
                  <a href="/b2c/mybookings" className={classes.backButton}>
                    <ArrowBack /> Back
                  </a>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  container
                  item
                  xs={12}
                  md={8}
                  style={{
                    marginTop: "15px",
                  }}
                >
                  <Grid
                    container
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                    style={{
                      borderRight: "1px solid #ababab",
                      paddingRight: 25,
                      paddingBottom: 25,
                    }}
                  >
                    <Grid item xs={5} md={3}>
                      <Typography className={classes.typo6}>
                      {flightData?.onwardJourney?.[0]?.originCityName}
                      </Typography>
                      <Typography className={classes.typo7}>
                      {moment(
                    flightData?.flightSummary?.onwardJourney?.[0]
                      ?.departureDateTimeRaw
                  ).format("DD MMM'YY")}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                        style={{ fontSize: "35px", color: "#4e4c4c" }}
                      ></i>
                    </Grid>
                    <Grid item xs={5} md={3}>
                      <Typography className={classes.typo6}>
                      {flightData?.onwardJourney?.[0]?.destinationCityName}
                      </Typography>
                      <Typography className={classes.typo7}>
                      {moment(
                    flightData?.onwardJourney?.[0]
                      ?.arrivalDateTimeRawarrivalDateTimeRaw
                  ).format("DD MMM'YY")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  continer
                  item
                  xs={12}
                  md={4}
                  alignItems="center"
                  style={{ marginTop: 15 }}
                >
                  <Grid item>
                    <Typography className={classes.typo4}>
                      PNR Status :{" "}
                      <span className={classes.typo7}>{flightData?.bookingStatus}</span>
                    </Typography>
                    <Typography className={classes.typo4}>
                      PNR : {flightData?.pnrNo}
                    </Typography>
                    <Typography className={classes.typo7}>
                      {/* {ticket.onwardFlightDetails.summary.isRefundable} */}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              md={12}
              style={{
                background: "#fff",
                border: "1px solid #dcdcdc",
                borderRadius: "5px",
                boxShadow: "0px 0px 6px -1px #b3b3b3",
                marginBottom: 20,
              }}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                  background: "#e2e2e2",
                  padding: "10px",
                }}
              >
                <Grid item>
                  <Typography className={classes.typo5}>
                    Onward Fare Details
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  container
                  item
                  xs={12}
                  style={{ padding: "15px 0px", maxWidth: "80%", margin: "auto" }}
                >
                  <Grid
                    container
                    item
                    justifyContent="flex-start"
                    alignItems="center"
                    xs={12}
                    md={6}
                  >
                    <Grid item xs={1}>
                      <i
                        className="fa fa-plane"
                        aria-hidden="true"
                        style={{ color: "grey", fontSize: 19 }}
                      ></i>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.typo8}>
                      {flightData?.onwardJourney?.[0]?.originCityName}
                      </Typography>
                    </Grid>
                    <Grid item style={{ paddingLeft: 10, paddingRight: 10 }}>
                      <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                        style={{ color: "grey", fontSize: 19 }}
                      ></i>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.typo8}>
                      {flightData?.onwardJourney?.[0]?.destinationCityName}
                      </Typography>
                    </Grid>
                  </Grid>
                  {flightData?.flightSummary?.adultNo  > 0 && (
                    <Grid
                      container
                      item
                      justifyContent="space-between"
                      alignItems="center"
                      xs={12}
                    >
                      <Grid item xs={6} style={{ textAlign: "left" }}>
                        <Typography className={classes.typo9}>
                          Adult Base Fare X{" "}
                          {flightData?.flightSummary?.adultNo}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "end" }}>
                        <Typography className={classes.typo9}>
                          Rs.{" "}
                          {(
                            Number(flightData?.adultFareBreakUpOneWay.airFare) *
                            flightData?.flightSummary?.adultNo
                          ).toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {flightData?.flightSummary?.childNo > 0 && (
                    <Grid
                      container
                      item
                      justifyContent="space-between"
                      alignItems="center"
                      xs={12}
                    >
                      <Grid item xs={6} style={{ textAlign: "left" }}>
                        <Typography className={classes.typo9}>
                          Child Base Fare X{" "}
                          {flightData?.flightSummary?.childNo}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "end" }}>
                        <Typography className={classes.typo9}>
                          Rs.{" "}
                          {(
                            Number(flightData?.childFareBreakUpOneWay.airFare) *
                            flightData?.flightSummary?.childNo
                          ).toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {flightData?.flightSummary?.infantNo > 0  && (
                    <Grid
                      container
                      item
                      justifyContent="space-between"
                      alignItems="center"
                      xs={12}
                    >
                      <Grid item xs={6} style={{ textAlign: "left" }}>
                        <Typography className={classes.typo9}>
                          Infant Base Fare X{" "}
                          {flightData.flightSummary.infantNo}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "end" }}>
                        <Typography className={classes.typo9}>
                          Rs.{" "}
                          {(
                            Number(flightData?.infantFareBreakUpOneWay.airFare) *
                            flightData?.flightSummary?.infantNo
                          ).toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    container
                    item
                    justifyContent="space-between"
                    alignItems="center"
                    xs={12}
                  >
                    <Grid item xs={6} style={{ textAlign: "left" }}>
                      <Typography className={classes.typo9}>
                        Fee & Surcharges (Incl. of Taxes)
                      </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "end" }}>
                      <Typography className={classes.typo9}>
                        Rs.{" "}
                        {(
                          Number(flightData?.flightSummary.onwardGST) +Number(flightData?.flightSummary.onwardMealBeggageSeatNMisc) + +Number(flightData?.flightSummary.onwardTaxNFees)
                        ).toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* <Grid
                    container
                    item
                    justifyContent="space-between"
                    alignItems="center"
                    xs={12}
                  >
                    <Grid item xs={6} style={{ textAlign: "left" }}>
                      <Typography className={classes.typo9}>
                        (-) Discount
                      </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "end" }}>
                      <Typography className={classes.typo9}>
                        (-) Rs. {Number(ticket.fareDetails[0].corpDisc)}
                      </Typography>
                    </Grid>
                  </Grid> */}
                  <hr
                    style={{
                      width: "100%",
                      marginTop: 7,
                      marginBottom: 7,
                    }}
                  />
                  <Grid
                    container
                    item
                    justifyContent="space-between"
                    alignItems="center"
                    xs={12}
                  >
                    <Grid item xs={6} style={{ textAlign: "left" }}>
                      <Typography
                        className={classes.typo4}
                        style={{ fontWeight: "bold" }}
                      >
                        Total
                      </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "end" }}>
                      <Typography
                        className={classes.typo4}
                        style={{ fontWeight: "bold" }}
                      >
                        Rs.{" "}
                        {(
                         Number(flightData?.flightSummary.onwardTotal)
                        ).toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <hr
                    style={{
                      width: "100%",
                      marginTop: 7,
                      marginBottom: 7,
                    }}
                  />
                  <Grid
                    container
                    item
                    justifyContent="space-between"
                    alignItems="center"
                    xs={12}
                  >
                    <Grid item xs={6} md={8} style={{ textAlign: "left" }}>
                      <Typography className={classes.typo9}>
                        {`Paid on ${moment(flightData?.bookingDate).format(
                          "DD MMM'YY"
                        )}
     
                         | Transaction Id:
                        ${flightData?.bookingRef}`}
                                            {/* Through ${ticket.payMode}  */}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={4} style={{ textAlign: "end" }}>
                      <Typography className={classes.typo9}>
                        Rs.{" "}
                        {(
                         Number(flightData?.flightSummary.onwardTotal)
                        ).toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* <hr
                    style={{
                      width: "100%",
                      marginTop: 7,
                      marginBottom: 7,
                    }}
                  /> */}
                  {/* <Grid
                    container
                    item
                    justifyContent="space-between"
                    alignItems="center"
                    xs={12}
                  >
                    <Grid item xs={6} style={{ textAlign: "left" }}>
                      <Typography
                        className={classes.typo4}
                        style={{ fontWeight: "bold" }}
                      >
                        AMOUNT DUE
                      </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "end" }}>
                      <Typography
                        className={classes.typo4}
                        style={{ fontWeight: "bold" }}
                      >
                        Rs. 0.00
                      </Typography>
                    </Grid>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
            {flightData?.flightSummary?.journeyType !== "Oneway" && (
                 <Grid
                 container
                 item
                 md={12}
                 style={{
                   background: "#fff",
                   border: "1px solid #dcdcdc",
                   borderRadius: "5px",
                   boxShadow: "0px 0px 6px -1px #b3b3b3",
                   marginBottom: 20,
                 }}
               >
                 <Grid
                   container
                   justifyContent="center"
                   alignItems="center"
                   style={{
                     background: "#e2e2e2",
                     padding: "10px",
                   }}
                 >
                   <Grid item>
                     <Typography className={classes.typo5}>
                       Return Fare Details
                     </Typography>
                   </Grid>
                 </Grid>
                 <Grid container>
                   <Grid
                     container
                     item
                     xs={12}
                     style={{ padding: "15px 0px", maxWidth: "80%", margin: "auto" }}
                   >
                     <Grid
                       container
                       item
                       justifyContent="flex-start"
                       alignItems="center"
                       xs={12}
                       md={6}
                     >
                       <Grid item xs={1}>
                         <i
                           className="fa fa-plane"
                           aria-hidden="true"
                           style={{ color: "grey", fontSize: 19 }}
                         ></i>
                       </Grid>
                       <Grid item>
                         <Typography className={classes.typo8}>
                         {flightData?.returnJourney?.[0]?.originCityName}
                         </Typography>
                       </Grid>
                       <Grid item style={{ paddingLeft: 10, paddingRight: 10 }}>
                         <i
                           className="fa fa-long-arrow-right"
                           aria-hidden="true"
                           style={{ color: "grey", fontSize: 19 }}
                         ></i>
                       </Grid>
                       <Grid item>
                         <Typography className={classes.typo8}>
                         {flightData?.returnJourney?.[0]?.destinationCityName}
                         </Typography>
                       </Grid>
                     </Grid>
                     {flightData?.flightSummary?.adultNo  > 0 && (
                       <Grid
                         container
                         item
                         justifyContent="space-between"
                         alignItems="center"
                         xs={12}
                       >
                         <Grid item xs={6} style={{ textAlign: "left" }}>
                           <Typography className={classes.typo9}>
                             Adult Base Fare X{" "}
                             {flightData?.flightSummary?.adultNo}
                           </Typography>
                         </Grid>
                         <Grid item xs={6} style={{ textAlign: "end" }}>
                           <Typography className={classes.typo9}>
                             Rs.{" "}
                             {(
                               Number(flightData?.adultFareBreakUpOneWay.airFare) *
                               flightData?.flightSummary?.adultNo
                             ).toFixed(2)}
                           </Typography>
                         </Grid>
                       </Grid>
                     )}
                     {flightData?.flightSummary?.childNo > 0 && (
                       <Grid
                         container
                         item
                         justifyContent="space-between"
                         alignItems="center"
                         xs={12}
                       >
                         <Grid item xs={6} style={{ textAlign: "left" }}>
                           <Typography className={classes.typo9}>
                             Child Base Fare X{" "}
                             {flightData?.flightSummary?.childNo}
                           </Typography>
                         </Grid>
                         <Grid item xs={6} style={{ textAlign: "end" }}>
                           <Typography className={classes.typo9}>
                             Rs.{" "}
                             {(
                               Number(flightData?.childFareBreakUpOneWay.airFare) *
                               flightData?.flightSummary?.childNo
                             ).toFixed(2)}
                           </Typography>
                         </Grid>
                       </Grid>
                     )}
                     {flightData?.flightSummary?.infantNo > 0  && (
                       <Grid
                         container
                         item
                         justifyContent="space-between"
                         alignItems="center"
                         xs={12}
                       >
                         <Grid item xs={6} style={{ textAlign: "left" }}>
                           <Typography className={classes.typo9}>
                             Infant Base Fare X{" "}
                             {flightData.flightSummary.infantNo}
                           </Typography>
                         </Grid>
                         <Grid item xs={6} style={{ textAlign: "end" }}>
                           <Typography className={classes.typo9}>
                             Rs.{" "}
                             {(
                               Number(flightData?.infantFareBreakUpOneWay.airFare) *
                               flightData?.flightSummary?.infantNo
                             ).toFixed(2)}
                           </Typography>
                         </Grid>
                       </Grid>
                     )}
                     <Grid
                       container
                       item
                       justifyContent="space-between"
                       alignItems="center"
                       xs={12}
                     >
                       <Grid item xs={6} style={{ textAlign: "left" }}>
                         <Typography className={classes.typo9}>
                           Fee & Surcharges (Incl. of Taxes)
                         </Typography>
                       </Grid>
                       <Grid item xs={6} style={{ textAlign: "end" }}>
                         <Typography className={classes.typo9}>
                           Rs.{" "}
                           {(
                             Number(flightData?.flightSummary.returnGST) +Number(flightData?.flightSummary.returnMealBeggageSeatNMisc) + +Number(flightData?.flightSummary.returnTaxNFees)
                           ).toFixed(2)}
                         </Typography>
                       </Grid>
                     </Grid>
                     {/* <Grid
                       container
                       item
                       justifyContent="space-between"
                       alignItems="center"
                       xs={12}
                     >
                       <Grid item xs={6} style={{ textAlign: "left" }}>
                         <Typography className={classes.typo9}>
                           (-) Discount
                         </Typography>
                       </Grid>
                       <Grid item xs={6} style={{ textAlign: "end" }}>
                         <Typography className={classes.typo9}>
                           (-) Rs. {Number(ticket.fareDetails[0].corpDisc)}
                         </Typography>
                       </Grid>
                     </Grid> */}
                     <hr
                       style={{
                         width: "100%",
                         marginTop: 7,
                         marginBottom: 7,
                       }}
                     />
                     <Grid
                       container
                       item
                       justifyContent="space-between"
                       alignItems="center"
                       xs={12}
                     >
                       <Grid item xs={6} style={{ textAlign: "left" }}>
                         <Typography
                           className={classes.typo4}
                           style={{ fontWeight: "bold" }}
                         >
                           Total
                         </Typography>
                       </Grid>
                       <Grid item xs={6} style={{ textAlign: "end" }}>
                         <Typography
                           className={classes.typo4}
                           style={{ fontWeight: "bold" }}
                         >
                           Rs.{" "}
                           {(
                            Number(flightData?.flightSummary.returnTotal)
                           ).toFixed(2)}
                         </Typography>
                       </Grid>
                     </Grid>
                     <hr
                       style={{
                         width: "100%",
                         marginTop: 7,
                         marginBottom: 7,
                       }}
                     />
                     <Grid
                       container
                       item
                       justifyContent="space-between"
                       alignItems="center"
                       xs={12}
                     >
                       <Grid item xs={6} md={8} style={{ textAlign: "left" }}>
                         <Typography className={classes.typo9}>
                           {`Paid on ${moment(flightData?.bookingDate).format(
                             "DD MMM'YY"
                           )}
        
                            | Transaction Id:
                           ${flightData?.bookingRef}`}
                                               {/* Through ${ticket.payMode}  */}
                         </Typography>
                       </Grid>
                       <Grid item xs={6} md={4} style={{ textAlign: "end" }}>
                         <Typography className={classes.typo9}>
                           Rs.{" "}
                           {(
                            Number(flightData?.flightSummary.returnTotal)
                           ).toFixed(2)}
                         </Typography>
                       </Grid>
                     </Grid>
                     {/* <hr
                       style={{
                         width: "100%",
                         marginTop: 7,
                         marginBottom: 7,
                       }}
                     /> */}
                     {/* <Grid
                       container
                       item
                       justifyContent="space-between"
                       alignItems="center"
                       xs={12}
                     >
                       <Grid item xs={6} style={{ textAlign: "left" }}>
                         <Typography
                           className={classes.typo4}
                           style={{ fontWeight: "bold" }}
                         >
                           AMOUNT DUE
                         </Typography>
                       </Grid>
                       <Grid item xs={6} style={{ textAlign: "end" }}>
                         <Typography
                           className={classes.typo4}
                           style={{ fontWeight: "bold" }}
                         >
                           Rs. 0.00
                         </Typography>
                       </Grid>
                     </Grid> */}
                   </Grid>
                 </Grid>
               </Grid>
            )}
          </Grid>
        </Grid>
        // <div>faredetails</div>
    );
  };
  export default withRouter(FareDetailsB2B);
  