import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import "assets/css/style.css";
import { Typography } from "@material-ui/core";
import companyLogo from "assets/BuzzHolidays/images/company_logo.png";

import Icon from "@material-ui/core/Icon";
import { AppConstant } from "appConstant";
import TopSection from "components/LandingPage/BuzzHolidays/TopSection";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "flex-start",
  },
  rootG: {
    marginTop: 20,
    "& .MuiTypography-root": {
      color: "#0c0c0c",
      fontSize: "12px",
      fontFamily: "Montserrat !important",
      fontWeight: 500,
      textTransform: "uppercase",
      "&:hover": {
        cursor: "pointer",
        color: "#2174ed !important",
      },
    },
    //
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  padding: {
    paddingRight: 30,
    cursor: "pointer",
  },
  logo: {
    padding: "4px 0px",
    margin: "0",
    display: "block",
    position: "relative",
    zIndex: "4",
    paddingTop: "13px",
    [theme.breakpoints.down(960)]: {
      paddingTop: 4,
    },
    "& a img": {
      height: 75,
      [theme.breakpoints.down(960)]: {
        height: 62,
      },
    },
  },
  topRow: {},
  topRowText: {
    display: "inline-block",
    position: "relative",
    margin: "0 10px",
    color: "#2D2D2D",
    textTransform: "uppercase",
    verticalAlign: "top",
    cursor: "pointer",
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "12px",
    "& span": {
      letterSpacing: "0.75px  !important",
    },
  },
  bottomRow: {
    paddingTop: "16px",
  },
  bottomRowText: {
    color: "#2D2D2D",
    transition: "all 0.5s",
    fontWeight: "bold",
    textTransform: "uppercase",
    cursor: "pointer",
    fontSize: "12px",
    padding: "10px 15px",
    "& span": {
      letterSpacing: "0.75px  !important",
    },
  },
  logoImage: {
    /*width: "215px",*/
    height: "75px",
  },

  logoImageScroll: {
    width: 151,
    paddingTop: 20,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  topbar: {
    float: "right",
    borderBottom: "1px solid #e5e5e515",
    padding: "8px 0",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  typo: {
    color: "#262626",
    fontWeight: "500",
    cursor: "pointer",
  },
  cachIcon: {
    color: "#262626",
    height: "20px",
    cursor: "pointer",
    fontWeight: "bold",
  },
  currency: {
    color: "#262626",
    marginLeft: 5,
    fontWeight: "500",
  },
  list: {
    width: 250,
    "& .MuiListItem-root": {
      // background: "#3e3535",
      // color: "white",
      // borderRadius: 15,
      // marginBottom:10
    },
  },
  fullList: {
    width: "auto",
  },
  verticalLine: {
    textAlign: "end",
    position: "relative",
    borderRight: "2px solid #F89605",
    marginRight: "-20px",
    paddingRight: "20px",
    height: "54px",
    paddingTop: "5px",
    marginTop: "-5px",
  },
  highlightText: {
    color: "#007A92 !important",
    "&:hover": {
      color: "#2174ED !important",
    },
  },
  appBarWrap: {
    background: "#fff",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 1px 0px, 0px 0px 0px",
    height: 110,
    [theme.breakpoints.down("sm")]: {
      height: 70,
    },
  },
  supportInfo: {
    paddingTop: 10,
    [theme.breakpoints.down(600)]: {
      display: "none",
    },
  },
}));

export default function PreLoginNavBar(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [accountAnchorEl, setAccountAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [corpName, setCorpName] = useState("");
  const [corpIdentity, setCorpIdentity] = useState("");
  const [accountBalance, setAccountBalance] = useState("");
  const [showServices, setShowServices] = useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const isAccountMenuOpen = Boolean(accountAnchorEl);
  const history = useHistory();
  const [anchorEll, setAnchorEll] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEll(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEll(null);
  };

  const open = Boolean(anchorEll);
  const id = open ? "simple-popover" : undefined;

  const handleSupportMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setAccountAnchorEl(null);
    handleMobileMenuClose();
  };

  const openFlightBooking = (event) => {
    // if (typeof window !== "undefined" && window.openPage) {
    //   window.openPage("flight");
    // }

    props.history.push("/flight");
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      id={menuId}
      keepMounted
      disableScrollLock={true}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        {AppConstant.name}: Phone: (+{AppConstant.countryDialCode}){" "}
        {AppConstant.supportContact}
      </MenuItem>
    </Menu>
  );

  const brand = (
    <div className={classes.logo} style={{ textAlign: "center" }}>
      <a onClick={openFlightBooking} href="#" style={{ textAlign: "center" }}>
        <img src={companyLogo} alt="logo" className={classes.logoImage} />
      </a>
    </div>
  );

  const Wrapper = ({ isSearchPage, children }) =>
    isSearchPage ? <Container fixed>{children}</Container> : children;

  return (
    <>
      <TopSection />

      <div className={classes.grow}>
        <AppBar position="fixed" className={classes.appBarWrap}>
          <Wrapper isSearchPage={false}>
            <Toolbar classes={{ root: classes.root }}>
              <div onClick={() => history.push("/")}>{brand}</div>
              <div className={classes.grow} />

              <GridContainer
                justifyContent="flex-end"
                className={classes.supportInfo}
                md={12}
              >
                <GridItem>
                  <Icon color={"disabled"} style={{ fontSize: 50 }}>
                    support_agent
                  </Icon>
                </GridItem>
                <GridItem>
                  <Typography className={classes.typo}>
                    24x7 Helpdesk: ({AppConstant.countryDialCode}){" "}
                    {AppConstant.supportContact}
                  </Typography>

                  <Typography className={classes.typo}>
                    Support Email: {AppConstant.supportEmail}
                  </Typography>
                </GridItem>

                {!(props.hideLogin && props.hideLogin === true) && (
                  <GridItem>
                    <button
                      className="orange2-btn"
                      type="button"
                      style={{ cursor: "pointer" }}
                      name="prtLog"
                      onClick={() => history.push("/auth/login-page")}
                    >
                      Partner Login
                    </button>
                  </GridItem>
                )}
              </GridContainer>
            </Toolbar>
          </Wrapper>
        </AppBar>
        {renderMenu}
      </div>
    </>
  );
}

PreLoginNavBar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  isSearchPage: PropTypes.bool,
  sidebarMinimize: PropTypes.func,
  hideLogin: PropTypes.bool,
};
