import React from "react";
import styled from 'styled-components';
import * as Yup from "yup";
import { Button, Popper, TextField, useTheme } from "@material-ui/core";
import { ErrorMessage, Form, Formik } from "formik";
import Autocomplete from "@material-ui/lab/Autocomplete";
import WebApi from "api/ApiConstants";

const ForexQueryRequestForm = props => {

    const theme = useTheme();
    const formikRef = React.useRef(null);
    const demoCurrencies = [
        {id: 1, code: "GBP", currencyName: "Great Britain Pound", country: "UK"},
        {id: 2, code: "INR", currencyName: "Indian Rupee", country: "IN"},
        {id: 3, code: "USD", currencyName: "United States Dollar", country: "US"}
    ]
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const [currencies, setCurrencies] = React.useState(demoCurrencies);

    const customPopper = function (propes) {
        return <Popper {...propes} placement="bottom" style={{}} />;
    };

    const initialValues = {
        customerName: "",
        mobileNumber: "",
        email: "",
        currency: "USD",
        currencyObj: {id: 3, code: "USD", currencyName: "United States Dollar", country: "US"}
    };

    const validationSchema = Yup.object().shape({
        customerName: Yup.string().required("Name is required"),
        mobileNumber: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .min(10, "too short")
        // .max(10, "too long")
        .required("Mobile Number is required"),
        email: Yup.string().required("Email is required"),
        currency: Yup.string().required("Currency is required"),
    });

    const onChangeCustomerName = (event) => {
        if(formikRef.current) {
            formikRef.current.setFieldValue("customerName", event.target.value);
        }
    };

    const onChangeMobileNumber = (event) => {
        if(formikRef.current) {
            formikRef.current.setFieldValue("mobileNumber", event.target.value);
        }
    };

    const onChangeEmail = (event) => {
        if(formikRef.current) {
            formikRef.current.setFieldValue("email", event.target.value);
        }
    };

    const onChangeCurrency = (event, newValue) => {
        console.log(newValue)
        if(formikRef.current) {
            formikRef.current.setFieldValue("currencyObj", newValue);
            formikRef.current.setFieldValue("currency", newValue.code);
        }
    };

    const onForexQuerySubmit = (searchParams) => {
        WebApi.sendForexQueryRequest(searchParams, response => {
            console.log("response", response);
        }, error => { console.error("error submiting query", error) });
    };

    return(
        <FormContainer theme={theme}>
            <Formik innerRef={formikRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onForexQuerySubmit}
            >
                {({ values, setFieldValue, enableReinitialize, resetForm }) => (
                    <Form>
                        <div className="form-wrapper">
                            <div className="form-title">
                                <h2>Forex Query</h2>
                            </div>
                            <div className="form-row">
                                <div className="form-column">
                                    <label>Name</label>
                                    <TextField value={values.customerName} onChange={onChangeCustomerName} variant="outlined" fullWidth/>
                                </div>
                                <div className="form-column">
                                    <ErrorMessage
                                        name="customerName"
                                        component="div"
                                        className="error"
                                    />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-column">
                                    <label>Mobile Number</label>
                                    <TextField value={values.mobileNumber} onChange={onChangeMobileNumber} variant="outlined" fullWidth/>
                                </div>
                                <div className="form-column">
                                    <ErrorMessage
                                        name="mobileNumber"
                                        component="div"
                                        className="error"
                                    />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-column">
                                    <label>Email</label>
                                    <TextField value={values.email} onChange={onChangeEmail} name="email" variant="outlined" fullWidth/>
                                </div>
                                <div className="form-column">
                                    <ErrorMessage
                                        name="email"
                                        component="div"
                                        className="error"
                                    />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-column">
                                    <label>Currency</label>
                                    <Autocomplete
                                        fullWidth
                                        PopperComponent={customPopper}
                                        options={currencies || []}
                                        getOptionLabel={(o) => (o?.code ? `${o.code} (${o.currencyName})` : "")}
                                        value={values.currencyObj}
                                        getOptionSelected={(option, value) =>
                                            option.code == value.code
                                        }
                                        onChange={onChangeCurrency}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                color="secondary"
                                                variant="outlined"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "new-password",
                                                }}
                                            />
                                        )}
                                        disablePortal={true}
                                    />
                                </div>
                                <div className="form-column">
                                    <ErrorMessage
                                        name="currency"
                                        component="div"
                                        className="error"
                                    />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-column submit-action-column">
                                    <Button type="submit">Submit</Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </FormContainer>
    );
};

export default ForexQueryRequestForm;

const FormContainer = styled.div`
    .form-wrapper {
        padding: 10px;
    }
    .form-title {
        text-align: center;
        color: ${props => props.theme.palette.text.dark};
        font-family: ${props => props.theme.palette.font.primary};
    }
    .form-row {
        display: flex;
        flex-direction: column;
        margin: 7px 0;
        width: 100%;
    }
    label {
        color: ${props => props.theme.palette.text.dark};
        font-family: ${props => props.theme.palette.font.primary};
        font-weight: 600;
    }
    .form-column {
        width: 100%;
        &.submit-action-column {
            width: 100%;
            text-align: center;
            margin-top: 10px;
            & button {
                width: 160px;
                height: 100%;
                border-radius: 10px;
                margin-left: 5px;
                height: 50px;
                border-radius: 10px;
                background: ${props => props.theme.palette.buttons.primary};
            }
        }
    }
    .MuiOutlinedInput-root {
        height: 52px;
    }
`;
