import React from "react";
import {
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { AppConstant } from "appConstant";
import { useHistory } from "react-router-dom";

const CancellationPolicy = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const history = useHistory();

  const goToHomePage = () => {
    history.push("/");
  };

  return (
    <div
      className={classes.root}
      style={{
        marginBottom: 60,
        paddingLeft: isMobile ? 10 : "6%",
        paddingRight: isMobile && 10,
      }}
    >
      <Grid
        container
        spacing={isMobile ? 0 : 3}
        style={{ width: "100%", marginTop: 30 }}
        justifyContent="center"
      >
        {/* <Grid item xs={12} md={10} style={{ paddingTop: "50px" }}>
                    <a onClick={goToHomePage} className={classes.breadcrumbLink}>
                        <i className="fa fa-home"></i> Home
                    </a>
                </Grid> */}
        <Grid item style={{ width: "100%" }}>
          <div className={classes.titleHeader}>CANCELLATION & VISA POLICY:</div>
        </Grid>

        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Cancellation Charges:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            <ul className={classes.listText}>
              <li>
                Buzz Holidayz implements a tiered cancellation policy where
                charges are determined by the time of cancellation before the
                departure date.
              </li>
              <li>
                Cancellations made well in advance may incur minimal charges,
                while charges may increase as the departure date approaches
              </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Non-Refundable Deposits:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Certain holiday packages offered by Buzz Holidayz may include
            non-refundable deposits. Even if cancellations occur within the
            permissible time frame, the initial deposit may not be refunded.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Time-Based Cancellation:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Cancellation policies are time-based, with different deadlines.
            Closer proximity to the departure date incurs higher cancellation
            charges. Categories may include deadlines like 30 days before
            departure, 15 days before departure, etc.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Refund Processing Time:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            The time required to process a refund may vary. Buzz Holidayz aims
            to process refunds within 10 working days after the cancellation is
            confirmed, but processing times may differ.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Partial Refunds:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Depending on the time of cancellation, travelers might receive
            partial refunds rather than a full refund of the total package cost.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Airfare and Hotel Policies:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Cancellation policies for flights and hotels are typically governed
            by the respective airline or hotel policies. Buzz Holidayz
            communicates these policies to the customers.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Special Promotions or Discounts:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Travelers who booked a holiday package with special promotions or
            discounts should be aware that cancellation may void those benefits,
            and additional charges may apply.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Force Majeure:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Buzz Holidayz's cancellation policies may include clauses related to
            unforeseen circumstances or events beyond the control of either
            party (force majeure). In such cases, cancellation terms may be more
            flexible.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Communication of Cancellation:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Specific channels or procedures for communicating a cancellation
            will be provided by Buzz Holidayz. Travelers should ensure they
            follow the specified process to initiate cancellation.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Insurance Coverage:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            <ul className={classes.listText}>
              <li>
                Travelers who purchased travel insurance through Buzz Holidayz
                should understand how it might impact the cancellation process.
                Refunds can be claimed through the insurance provider within 15
                working days of cancellation confirmation.
              </li>
              <li>
                Travelers are advised to thoroughly review the cancellation
                terms and conditions provided by Buzz Holidayz before making any
                bookings. If there are questions or concerns, clarification
                should be sought directly from Buzz Holidayz.
              </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Visa Processing Agreement between Buzz Holidayz and Travel Partner:
          </Typography>
          <Grid item md={12} className={classes.sectionTextGrid}>
            <Typography
              variant="subtitle1"
              className={classes.sectionSubhead}
              gutterBottom
            >
              Visa Application Process:
            </Typography>

            <Typography
              variant="body2"
              gutterBottom
              className={classes.sectionText}
            >
              <ul className={classes.listText}>
                <li>
                  Buzz Holidayz assures that all visa applications submitted are
                  genuine cases, taking full responsibility for the clients,
                  passengers, or travelers.
                </li>
                <li>
                  Adherence to all requirements imposed by Immigration and
                  consulates of respective countries is ensured through Buzz
                  Holidayz's office concerning visa documentation.
                </li>
              </ul>
            </Typography>
          </Grid>
          <Grid item md={12} className={classes.sectionTextGrid}>
            <Typography
              variant="subtitle1"
              className={classes.sectionSubhead}
              gutterBottom
            >
              Indemnification:
            </Typography>

            <Typography
              variant="body2"
              gutterBottom
              className={classes.sectionText}
            >
              Buzz Holidayz irrevocably indemnifies M/s Travel Partner, its
              officers, employees, and agents from any costs, charges, claims,
              damages arising from passengers defaulting in visa terms &
              conditions.
            </Typography>
          </Grid>
          <Grid item md={12} className={classes.sectionTextGrid}>
            <Typography
              variant="subtitle1"
              className={classes.sectionSubhead}
              gutterBottom
            >
              Limited Role of Buzz Holidayz:
            </Typography>

            <Typography
              variant="body2"
              gutterBottom
              className={classes.sectionText}
            >
              Buzz Holidayz's role is limited to accepting and forwarding visa
              applications, documentation to consulates and immigration
              authorities, and communicating on the application status.
            </Typography>
          </Grid>
          <Grid item md={12} className={classes.sectionTextGrid}>
            <Typography
              variant="subtitle1"
              className={classes.sectionSubhead}
              gutterBottom
            >
              Visa Approval:
            </Typography>

            <Typography
              variant="body2"
              gutterBottom
              className={classes.sectionText}
            >
              <ul className={classes.listText}>
                <li>
                  Visa applications are considered on individual merit, and
                  consulates have the right to seek additional information at
                  any processing stage.
                </li>
                <li>
                  The decision to approve or refuse a visa rests solely with the
                  Immigration Department and consulate, and Buzz Holidayz has no
                  influence on the decision.
                </li>
              </ul>
            </Typography>
          </Grid>
          <Grid item md={12} className={classes.sectionTextGrid}>
            <Typography
              variant="subtitle1"
              className={classes.sectionSubhead}
              gutterBottom
            >
              Visa Application Fees:
            </Typography>

            <Typography
              variant="body2"
              gutterBottom
              className={classes.sectionText}
            >
              <ul className={classes.listText}>
                <li>
                  Once a visa is applied, fees are chargeable irrespective of
                  visa grant or rejection.
                </li>
                <li>Visa fees are nonrefundable under any circumstances.</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item md={12} className={classes.sectionTextGrid}>
            <Typography
              variant="subtitle1"
              className={classes.sectionSubhead}
              gutterBottom
            >
              Document Verification:
            </Typography>

            <Typography
              variant="body2"
              gutterBottom
              className={classes.sectionText}
            >
              <ul className={classes.listText}>
                <li>
                  Travel Partners are responsible for verifying documents
                  correctly before submission, including passport details,
                  photographs, additional documents for minors, and any forms
                  required by consulates.
                </li>
              </ul>
            </Typography>
          </Grid>
          <Grid item md={12} className={classes.sectionTextGrid}>
            <Typography
              variant="subtitle1"
              className={classes.sectionSubhead}
              gutterBottom
            >
              OK to Board Message:
            </Typography>

            <Typography
              variant="body2"
              gutterBottom
              className={classes.sectionText}
            >
              <ul className={classes.listText}>
                <li>
                  Travel Partners must inform Buzz Holidayz of the PNR at least
                  72–48 hours before departure on normal working days and 96–72
                  hours before weekends.
                </li>
                <li>
                  Verification and reconfirmation of the message with airlines
                  must be done 24 hours before departure.
                </li>
              </ul>
            </Typography>
          </Grid>
          <Grid item md={12} className={classes.sectionTextGrid}>
            <Typography
              variant="subtitle1"
              className={classes.sectionSubhead}
              gutterBottom
            >
              Quality Check Norms:
            </Typography>

            <Typography
              variant="body2"
              gutterBottom
              className={classes.sectionText}
            >
              <ul className={classes.listText}>
                <li>
                  Buzz Holidayz retains the right to reject any application
                  based on quality check norms and passenger profiles.
                </li>
              </ul>
            </Typography>
          </Grid>
          <Grid item md={12} className={classes.sectionTextGrid}>
            <Typography
              variant="subtitle1"
              className={classes.sectionSubhead}
              gutterBottom
            >
              Confidentiality and Security:
            </Typography>

            <Typography
              variant="body2"
              gutterBottom
              className={classes.sectionText}
            >
              <ul className={classes.listText}>
                <li>
                  Buzz Holidayz will take reasonable measures to ensure the
                  confidentiality of information provided but is not liable for
                  unauthorized access.
                </li>
                <li>
                  Buzz Holidayz is not responsible for any fines resulting from
                  the passenger's involvement in criminal offenses.
                </li>
              </ul>
            </Typography>
          </Grid>
          <Grid item md={12} className={classes.sectionTextGrid}>
            <Typography
              variant="subtitle1"
              className={classes.sectionSubhead}
              gutterBottom
            >
              Government Regulations:
            </Typography>

            <Typography
              variant="body2"
              gutterBottom
              className={classes.sectionText}
            >
              <ul className={classes.listText}>
                <li>
                  Issuance and approval of a visa are solely regulated by the
                  government of the particular country, and Buzz Holidayz is not
                  involved in the assessment process.
                </li>
              </ul>
            </Typography>
          </Grid>
          <Grid item md={12} className={classes.sectionTextGrid}>
            <Typography
              variant="subtitle1"
              className={classes.sectionSubhead}
              gutterBottom
            >
              Penalties for Overstays:
            </Typography>

            <Typography
              variant="body2"
              gutterBottom
              className={classes.sectionText}
            >
              <ul className={classes.listText}>
                <li>
                  The Travel Partner ensures passengers adhere to the flight
                  schedule submitted during visa application.
                </li>
                <li>
                  The Travel Partner is liable to pay Buzz Holidayz stipulated
                  penalties if any passengers overstay or abscond on a sponsored
                  visa.
                </li>
              </ul>
            </Typography>
          </Grid>
          <Grid item md={12} className={classes.sectionTextGrid}>
            <Typography
              variant="subtitle1"
              className={classes.sectionSubhead}
              gutterBottom
            >
              Compliance with Laws:
            </Typography>

            <Typography
              variant="body2"
              gutterBottom
              className={classes.sectionText}
            >
              <ul className={classes.listText}>
                <li>
                  Travel Partners confirm that visitors will not work or violate
                  laws, especially UAE/Oman/Bahrain/Qatar and sponsored visas
                  for other countries.
                </li>
                <li>
                  The Travel Partner is liable to pay Buzz Holidayz stipulated
                  penalties if any passengers overstay or abscond on a sponsored
                  visa.
                </li>
              </ul>
            </Typography>
          </Grid>
          <Grid item md={12} className={classes.sectionTextGrid}>
            <Typography
              variant="subtitle1"
              className={classes.sectionSubhead}
              gutterBottom
            >
              Liability for Penalties:
            </Typography>

            <Typography
              variant="body2"
              gutterBottom
              className={classes.sectionText}
            >
              <ul className={classes.listText}>
                <li>
                  The Travel Partner bears penalties from consulate/immigration
                  authorities/suppliers/local authorities routed through third
                  parties.
                </li>
              </ul>
            </Typography>
          </Grid>
          <Grid item md={12} className={classes.sectionTextGrid}>
            <Typography
              variant="subtitle1"
              className={classes.sectionSubhead}
              gutterBottom
            >
              Additional Terms:
            </Typography>

            <Typography
              variant="body2"
              gutterBottom
              className={classes.sectionText}
            >
              <ul className={classes.listText}>
                <li>
                  Any additional terms and conditions agreed upon between Buzz
                  Holidayz and the Travel Partner are considered binding.
                </li>
                <li>
                  Both parties agree to all the terms and conditions stipulated
                  in this agreement. Buzz Holidayz maintains no role in the visa
                  assessment process and will not be liable for delays or
                  rejections.
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CancellationPolicy;

const Gap10 = () => {
  return <div className="gap-10" style={{ margin: 10, width: "100%" }}></div>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .highlight": {
      color: theme.palette.primary.darkOrange,
    },
  },
  titleHeader: {
    fontSize: 40,
    fontWeight: 500,
    textDecoration: "underline",
  },
  Grid: {
    background: theme.palette.background.default,
    marginTop: "20px",
    boxShadow: "0px 53px 99px -81px rgba(0,0,0,0.75)",
    padding: 30,
    borderRadius: 20,
  },
  sectionHeader: {
    color: `${theme.palette.primary.darkFadedText} !important`,
    fontWeight: 500,
    margin: "5px 0 30px",
  },
  sectionSubhead: {
    color: `${theme.palette.primary.defaultText} !important`,
    fontWeight: 400,
  },
  sectionText: {
    color: `${theme.palette.primary.davyGray} !important`,
    fontWeight: 400,
    lineHeight: "27px",
  },
  sectionTextGrid: {
    margin: "15px 0",
    flexDirection: "column",
  },
  listText: {
    "& li": {
      color: theme.palette.primary.davyGray,
      fontWeight: 400,
      lineHeight: "27px",
    },
  },
  sectionTextGrid: {
    margin: "15px 0",
    flexDirection: "column",
  },
  listText: {
    "& li": {
      color: theme.palette.primary.darkFadedText,
      fontWeight: 400,
      lineHeight: "27px",
    },
  },
  breadcrumbLink: {
    color: `${theme.palette.primary.active} !important`,
    cursor: "pointer",
    "&:hover": {
      color: `${theme.palette.primary.pomegranate} !important`,
    },
  },
}));
