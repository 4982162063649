import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";

import PlanYourTripMobile from "./forms/PlanYourTripMobile";
import {
  localforageGetItem,
} from "oautils/oaForageUtils";
import { useSelector, useDispatch } from "react-redux";
import { AppStateContext } from "layouts/AppStateProvider";
import { refreshUserSession } from "oautils/oaAuthUtils";
import TopFlightRoutes from "./TopFlightRoutes";
//import OffersSection from "./OffersSection";

const LandingPage = (props) => {
  const classes = useStyles();
 
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { setUserTypeb2c } = React.useContext(AppStateContext);
  const [usertype, setUsertype] = useState(null);
  const [showServices, setShowServices] = useState(
    props.hideServices ? props.hideServices : props.showServices
  );
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [corporateType, setCorporateType] = useState(null);
  const [routesItem, setRoutesItem] = React.useState([]);
  const [offers, setOffers] = React.useState([]);

  const getValuesFromLocalStorage = () => {
    setIsLoading(true);
    localforageGetItem("user-details", function(err, value) {
      if (value) {
        if (value.userTypeAbv == "R") {
          setIsLoggedIn(true);
        }

        setShowServices(
          value.userType == "distributor"
            ? false
            : showServices != null
            ? showServices
            : true
        );

        setServiceOptions(value.serviceOption);

        setUsertype(value.userType);
        setUserTypeb2c(value.userType);
        setCorporateType(value.corporateType);
        setIsLoading(false);
      }
    });
  };

  const callbackRefreshToken = (flag) => {
    setIsLoading(flag);
  };

  useEffect(() => {
    refreshUserSession(dispatch, callbackRefreshToken);
    getValuesFromLocalStorage();
  }, []);

  return (
    !isLoading && (
      <div className={classes.root}>
        <PlanYourTripMobile />
       
        {/* <OffersSection offers={offers} />
        <TopFlightRoutes routes={routesItem} /> */}
       
      </div>
      
    )
  );
};

export default LandingPage;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    margin: 0,
    "& .temp-container": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& img": {
        width: "100%",
        maxWidth: 1280,
      },
    }
  }}
));
