import React, { useState } from 'react';
import ReactTable from "react-table";
import WebApi from "api/ApiConstants";
import { useEffect } from 'react';
import { localforageGetItem } from "oautils/oaForageUtils";

/**
 * Opening balance = balance - credit + debit
 */
const CancellationSearchResult = (props) => {

    const cancellationPageUrl = '/admin/module/flights/trn/cancellation/';

    const [data, setData] = useState([]);
    const [tabledata, setTabledata] = useState([]);
    const [rowCount, setRowCount] = useState(4);
    const [loading, setLoading] = useState(true);
    const [finishedLoading, setFinishedLoading] = useState(false);
    const [noDataText, setNoDataText] = useState(false);

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'INR',
    })

   const  onRowClick = (state, rowInfo, column, instance) => {
        let cancellationStatus = rowInfo?.row?._original.cancellationStatus;
        // console.log("ccclllll",cancellationStatus);
        // switch (cancellationStatus) {
        //     case "Pending":
        //         "red"
        //     case "Processed":
        //         "green"
        //         break;
        //     default:
        //         null
        //         break;
        // };
        // return{
        // onClick: e => {
        //     console.log(rowInfo);
        // }}
        const bgColors = {
          pendingBg: "rgba(244, 3, 252, 0.2)",
          pendingColor: "#000",
          processedBg: "rgba(49, 252, 3, 0.2)",
          processedColor: "#000",
          rejectBg: "rgba(252, 40, 3, 0.2)",
          rejectColor: "#000",
          refundPendingBg: "rgba(252, 186, 3, 0.2)",
          refundPendingColor: "#000",
          refundProcessedBg: "rgba(252, 107, 3m 0.2)",
          refundProcessedColor: "#000",
        };
    
        return {
          style: {
            backgroundColor:
              cancellationStatus === "Pending"
                ? bgColors.pendingBg
                : cancellationStatus === "Processed"
                ? bgColors.processedBg
                : cancellationStatus === "Reject"
                ? bgColors.rejectBg
                : cancellationStatus === "refundPending"
                ? bgColors.refundPendingBg
                : cancellationStatus === "refundProcessed"
                ? bgColors.refundProcessedBg
                : null,
    
            color:
              cancellationStatus === "Pending"
                ? bgColors.pendingColor
                : cancellationStatus === "Processed"
                ? bgColors.processedColor
                : cancellationStatus === "Reject"
                ? bgColors.rejectColor
                : cancellationStatus === "refundPending"
                ? bgColors.refundPendingColor
                : cancellationStatus === "refundProcessed"
                ? bgColors.refundProcessedColor
                : null,
          },
        };
      };

    useEffect(() => {
        tableLoader();
    }, []);

    const tableLoader = () => {
        localforageGetItem("user-details", (err, details) => {
            if (details) {
                props.searchParams.agencyId = details.userId;
                props.searchParams.userType = details.userTypeAbv;

                WebApi.getCancellationRequestList(props.searchParams, (resp) => {
                    if (resp != null && resp.success) {
                        var tableData = [];
                        var data = [];
                        if (resp.data.hasOwnProperty("tableData")) {
                            tableData = resp.data.tableData;
                            data = resp.data;
                        } else {
                            tableData = resp.data;
                        }

                        setTabledata(tableData);
                        setData(data);
                        setRowCount(tableData.length === 0 ? 4 : tableData.length);
                        setNoDataText(tableData.length === 0 ? "No results" : tableData.length);
                        setLoading(false);
                        setFinishedLoading(true);
                    }
                }, (error) => {
                    setData(data);
                    setRowCount(4);
                    setNoDataText("ERROR ");
                    setLoading(false);
                    setFinishedLoading(true);
                });
            }
        });
        
    }

    return (
        <>
            {/* // /className="table table-bordered table-striped table-condensed" */}
            <ReactTable
                columns={[
                    {
                        Header: '#',
                        Cell: propes => <div>{propes.index + 1}</div>,
                        width: 35,
                        filterable: false
                    }, {
                        Header: "Can Id",
                        accessor: "canId",
                        width: 70,
                        filterable: false,
                        Cell: propes => (
                            <div >
                                {propes.value}
                            </div>
                        )
                    },
                    {
                        Header: "TID",
                        accessor: "transactionId",
                        width: 70,
                        filterable: false,
                        Cell: propes => (
                            <div>
                                <b>{propes.value}</b>
                            </div>
                        )
                    }, {
                        Header: "Trip",
                        accessor: "domesticInternational",
                        width: 50,
                        filterable: false,
                        Cell: propes => (
                            <div >{propes.value}</div>
                        ),
                    },

                    {
                        Header: "Agency Name",
                        accessor: "agentName",
                        width: 150,
                        Cell: propes => (
                            <div >{propes.value}</div>
                        ),
                    }, {
                        Header: "PNR",
                        accessor: "eTicketNO",
                        sortable: false,
                        Cell: propes => (
                            <div >{propes.value}</div>
                        )
                    }, {
                        Header: "Booking Date",
                        accessor: "transactionDate",
                        filterable: false,
                        width: 210,
                        sortable: false,
                        Cell: propes => (
                            <div >{propes.value}</div>
                        )
                    }, {
                        Header: "Travel Date",
                        accessor: "travelDateTime",
                        filterable: false,
                        sortable: false,
                        Cell: propes => (
                            <div >{propes.value}</div>
                        )
                    }, {
                        Header: "Can Request Date",
                        accessor: "cancellationDateTime",
                        filterable: false,
                        sortable: false,
                        Cell: propes => (
                            <div >{propes.value}</div>
                        )
                    }, {
                        Header: "Journey Type",
                        accessor: "journeyType",
                        filterable: false,
                        sortable: false,
                        Cell: propes => (
                            <div >{propes.value}</div>
                        )
                    }, {
                        Header: "Amount",
                        accessor: "flightFare",
                        filterable: false,
                        sortable: false,
                        Cell: propes => (
                            <div >{formatter.format(propes.value)}</div>
                        )
                    }, {
                        Header: "Status",
                        accessor: "transactionStatus",
                        filterable: false,
                        sortable: false,
                        Cell: propes => (
                            <div >{propes.value}</div>
                        )
                    }, {
                        Header: "Passenger Number",
                        accessor: "passengerMobileNumber",
                        filterable: false,
                        sortable: false,
                        Cell: propes => (
                            <div >{propes.value}</div>
                        )
                    }, {
                        Header: "Passenger Name",
                        accessor: "passengerName",
                        filterable: false,
                        sortable: false,
                        Cell: propes => (
                            <div >{propes.value}</div>
                        )
                    }, {
                        Header: "Cancellation Remark",
                        accessor: "cancellationRemark",
                        filterable: false,
                        sortable: false,
                        Cell: propes => (
                            <div >{propes.value}</div>
                        )
                    }, {
                        Header: "Group Booking",
                        accessor: "agentName",
                        filterable: false,
                        sortable: false,
                        Cell: propes => (
                            <div >N</div>
                        )
                    }, {
                        Header: "Airline Cancellation Charge",
                        accessor: "airlineCancellationCharge",
                        filterable: false,
                        sortable: false,
                        Cell: propes => (
                            <div >{formatter.format(propes.value)}</div>
                        )
                    }, {
                        Header: "Cancellation Charge",
                        accessor: "travlogyCancellationCharge",
                        filterable: false,
                        sortable: false,
                        Cell: propes => (
                            <div >{formatter.format(propes.value)}</div>
                        )
                    }, {
                        Header: "Refund Amount",
                        accessor: "finalRefundAmount",
                        filterable: false,
                        sortable: false,
                        Cell: propes => (
                            <div >{formatter.format(propes.value)}</div>
                        )
                    }, {
                        Header: "Cancellation Remarks",
                        width: 200,
                        accessor: "transactionId",
                        filterable: false,
                        sortable: false,
                        // Cell: propes => (<div style={{ height: 44, display: 'table-cell', verticalAlign: 'middle' }}>
                        //     <FlightCancellationRemarks trnId={propes.value}></FlightCancellationRemarks>
                        // </div>)
                    }
                ]}
                data={tabledata}
                defaultFilterMethod={props.filterMethod}
                filterable={true}
                showPagination={false}
                minRows={rowCount}
                loading={loading}
                pageSize={rowCount}
                noDataText={noDataText}
                className="-striped -highlight"
                getTdProps={onRowClick}

            />
        </>
    )
}

export default CancellationSearchResult;
