import React from "react";
import {
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { AppConstant } from "appConstant";
import { useHistory } from "react-router-dom";

const TermsAndConditions = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const history = useHistory();

  const goToHomePage = () => {
    history.push("/");
  };

  return (
    <div
      className={classes.root}
      style={{
        marginBottom: 60,
        paddingLeft: isMobile ? 10 : "6%",
        paddingRight: isMobile && 10,
      }}
    >
      <Grid
        container
        spacing={isMobile ? 0 : 3}
        style={{ width: "100%", marginTop: 30 }}
        justifyContent="center"
      >
        {/* <Grid item xs={12} md={10} style={{ paddingTop: "50px" }}>
                    <a onClick={goToHomePage} className={classes.breadcrumbLink}>
                        <i className="fa fa-home"></i> Home
                    </a>
                </Grid> */}
        <Grid item style={{ width: "100%" }}>
          <div className={classes.titleHeader}>TERMS & CONDITIONS:</div>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            General Terms and Conditions for Buzz Holidayz:
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Rates and Price Variations:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            We reserve the right to adjust prices and rates due to changes in
            exchange rates, price increases from wholesalers or other suppliers,
            or any other reasons. If there is an increase in the cost of a
            service, you are required to pay the difference upon notification or
            may choose to cancel the booking, subject to cancellation fees.
          </Typography>
        </Grid>{" "}
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Validity of Rates:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Prices are valid for the indicated dates.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Currency:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            All rates are quoted in Indian Rupees, or as otherwise indicated.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Payment Options:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Buzz Holidayz provides a secure server for credit card payments.
            Alternatively, customers can fax their credit card details, send a
            cheque, or make a direct deposit.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Accommodation:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Accommodation details are as specified on our website. All costs are
            displayed in Indian Rupees unless otherwise stated. Buzz Holidayz
            reserves the right to offer alternative accommodation of a similar
            standard in case of overbooking or other circumstances beyond our
            control. Rates are subject to change without notice. Availability
            for some properties may not be instant, and confirmation occurs upon
            receiving the booking request.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Flights:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Airfares show instant availability and fares at the time of quoting.
            Flight booking proceeds after confirming accommodation to avoid
            airline fees for amendments, which may result in fare and
            availability changes.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Check In / Check Out Times:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Check-in and check-out times vary by operator. Typically, check-in
            is at 2 pm, and check-out is at 10 am. Most properties require a
            credit card authorization at check-in and may request photo
            identification. If a credit card is unavailable, a cash bond may be
            required.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Single Supplement Costs:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            If not included in the selected accommodation cost, single
            supplement costs are available on application.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Accommodation Ratings:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Ratings are based on Buzz Holidayz inspections and, in some cases,
            in conjunction with hotel suppliers. Ratings are for guidance only
            and do not imply AAA endorsement.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Group And Incentive Travel:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Group and conference rates have specific terms and conditions
            different from standard holidays due to special contracts. Customers
            booking groups or incentives should ensure these conditions are
            explained.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Maps, Photographs, and Illustrations:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Maps, photographs, illustrations, and computer-based graphics are
            included for general purposes and may not always be indicative of
            the subject matter. Hotel room photographs may not be specific to
            the actual room occupied, and maps are not to scale.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Quotations:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            All quotations are subject to the availability of quoted services
            and are not guaranteed until the booking is confirmed and the
            deposit is received. Quotes are valid for 48 hours from the issue
            date.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Terms and Conditions of Booking for Buzz Holidayz:
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Rates and Price Variations:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            We reserve the right to adjust prices and rates due to changes in
            exchange rates, price increases from wholesalers or other suppliers,
            or any other reasons. If there is an increase in the cost of a
            service, you are required to pay the difference upon notification or
            may choose to cancel the booking, subject to cancellation fees.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Final Payment:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Final payment must be settled immediately upon request before the
            travel date. Vouchers will not be issued until final payment is
            received in our office. Conditions for final payment may vary for
            certain products, and these will be communicated in writing at the
            time of booking.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Card Fees:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            A card fee will be automatically applied to credit card payments to
            cover various costs associated with processing, including merchant
            fees, payment processing, administration, IT system maintenance for
            payment security, and addressing credit card fraud and chargebacks.
            Payments made by cheque or direct deposit do not attract any fees.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            ADM Policy:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Any Agent Debit Memo (ADM) arising from GDS misuse, fictitious
            names, duplicate bookings, or other specified reasons must be borne
            by the Travel Partner. Agents should release or issue hold bookings
            before the time limit to avoid ADM. If a booking is aborted during
            the hold or booking process, agents must contact the call center for
            guidance to avoid ADM charges.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Payment Processing Terms & Conditions:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            By providing your credit card details and accepting our Terms &
            Conditions, you authorize Buzz Holidayz to debit funds from your
            nominated credit card in accordance with the Direct Debit Request
            Service Agreement.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Standard Cancellation Policy:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Cancellation within 12 hours of the scheduled departure time must be
            handled directly with the airline, and Buzz Holidayz will not be
            liable for no-show situations. Charges levied by airlines may apply
            to canceled bookings.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Flight Cancellation Policy:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Airfares are governed by the terms and conditions of the purchased
            airfare and determined by the airline. Most airfares are
            non-refundable and non-transferable. Airline charges or cancellation
            fees may apply, and Buzz Holidayz will assist within these terms.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Special Cancellation Conditions:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Certain air, car, hotel, and tour products may have additional
            cancellation charges, clearly outlined on individual pages and
            communicated in writing at the time of booking.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Amendment Fees:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Amendments to confirmed bookings will incur fees, charged per
            amendment, in addition to any supplier or airline fees.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Change Of Itinerary After Booking Has Commenced:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Alterations or cancellations of services after the booking has
            commenced may incur penalties, with no refund for unused services.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Refunds:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            All refund requests must be in writing, directed to Buzz Holidayz or
            through the purchasing agent. Refunds are subject to
            airline/supplier approval and will be processed within 7 to 10
            working days. No refunds for inclement weather or illness; these
            should be claimed through travel insurance.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Reporting of Incidents:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Abnormal incidents, including injuries, service problems,
            cancellations, or dissatisfaction, must be reported to Buzz Holidayz
            during the event for timely resolution or assistance.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Terms and Conditions of Online Payments for Buzz Holidayz:
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Payments/Top Ups/Paid for Order:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Please be aware that any transaction conducted through any online
            payment method, whether by our registered agents or walk-in
            customers, is processed through an agent. In the event of a walk-in
            customer using a card for booking or wallet top-up, the cardholder
            assumes full responsibility. Any disputes or chargebacks will be
            managed by the registered Buzz Holidayz agent.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Wallet Update:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            In cases where a transaction through online payment is successful
            but the booking fails, the transaction amount (excluding payment
            gateway charges) will be credited to the Buzz Holidayz Agent ID
            wallet.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Refunds:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            For refund processing, Buzz Holidayz will follow the standing
            instructions provided by the agent. Refunds will be issued through
            the respective payment mode used during the transaction.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Payment Gateway Fees:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Please note that payment gateway fees will be automatically applied
            to the online payment option, and these charges are non-refundable
            under any circumstances.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Payment Processing Terms & Conditions:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            By providing your credit card details and accepting our Terms &
            Conditions, you authorize Buzz Holidayz to arrange for funds to be
            debited from your nominated credit card. This is in accordance with
            the terms and conditions of the Direct Debit Request Service
            Agreement, subject to amendments from time to time.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Currency Conversion Fees:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Your bank or credit card provider may apply currency conversion
            fees. Credit cards are required to secure bookings if you are
            traveling within 14 days.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Credit Card Chargeback Fees:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Any fees incurred by Buzz Holidayz from our credit card payment
            provider due to a chargeback or a disputed charge on the
            cardholder's credit card will be charged to the cardholder or the
            registered agency. This fee is non-refundable.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Outstanding Payments:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            In the case of outstanding payments remaining unpaid after several
            reminders, Buzz Holidayz reserves the right to recover the amount
            through future flight ticket bookings.
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.sectionTextGrid}>
          <Typography
            variant="subtitle1"
            className={classes.sectionSubhead}
            gutterBottom
          >
            Payment Options:
          </Typography>

          <Typography
            variant="body2"
            gutterBottom
            className={classes.sectionText}
          >
            Buzz Holidayz provides a secure server for credit card payments,
            accepting all international cards.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default TermsAndConditions;

const Gap10 = () => {
  return <div className="gap-10" style={{ margin: 10, width: "100%" }}></div>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .highlight": {
      color: theme.palette.primary.darkOrange,
    },
  },
  titleHeader: {
    fontSize: 40,
    fontWeight: 500,
    textDecoration: "underline",
  },
  Grid: {
    background: theme.palette.background.default,
    marginTop: "20px",
    boxShadow: "0px 53px 99px -81px rgba(0,0,0,0.75)",
    padding: 30,
    borderRadius: 20,
  },
  sectionHeader: {
    color: `${theme.palette.primary.darkFadedText} !important`,
    fontWeight: 500,
    margin: "5px 0 30px",
  },
  sectionSubhead: {
    color: `${theme.palette.primary.defaultText} !important`,
    fontWeight: 400,
  },
  sectionText: {
    color: `${theme.palette.primary.davyGray} !important`,
    fontWeight: 400,
    lineHeight: "27px",
  },
  sectionTextGrid: {
    margin: "15px 0",
    flexDirection: "column",
  },
  listText: {
    "& li": {
      color: theme.palette.primary.davyGray,
      fontWeight: 400,
      lineHeight: "27px",
    },
  },
  sectionTextGrid: {
    margin: "15px 0",
    flexDirection: "column",
  },
  listText: {
    "& li": {
      color: theme.palette.primary.darkFadedText,
      fontWeight: 400,
      lineHeight: "27px",
    },
  },
  breadcrumbLink: {
    color: `${theme.palette.primary.active} !important`,
    cursor: "pointer",
    "&:hover": {
      color: `${theme.palette.primary.pomegranate} !important`,
    },
  },
}));
