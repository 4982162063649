import clsx from "clsx";
import evening from "assets/icons/FilterTimings/cloudyNight.svg";
import dawn from "assets/icons/FilterTimings/dawn.svg";
import night from "assets/icons/FilterTimings/night.svg";
import morning from "assets/icons/FilterTimings/partlySunny.svg";
import afternoon from "assets/icons/FilterTimings/sunny.svg";
import dateFnsFormat from "date-fns/format";
import Collapse from "@material-ui/core/Collapse";
import React from "react";
import {
  Grid,
  IconButton,
  Slider,
  Input,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  FormGroup,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { BASE_URL_IMAGE_VIEW } from "api/ApiConstants";
import GEN from "assets/icons/all.png";

const FlightSearchFilter = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(true);
  const [MIN, setMIN] = React.useState(props.filters?.onwardMinFare);
  const [MAX, setMAX] = React.useState(props.filters?.onwardMaxFare);
  const [MINRT, setMINRT] = React.useState(props.filters?.returnMinFare);
  const [MAXRT, setMAXRT] = React.useState(props.filters?.returnMaxFare);
  const [currentFilterByStop, setCurrentFilterByStop] = React.useState([]);
  const [currentFilterByStopRt, setCurrentFilterByStopRt] = React.useState([]);

  const [openDiv, setOpenDiv] = React.useState({
    openPriceFilter: true,
    openStopsFilter: true,
    openDepartFilter: true,
    openArriveFilter: true,
    openAirlinesFilter: true,
    openSort: true,
  });

  const [sorted, setSorted] = React.useState({
    price: false,
    stops: false,
    departureTime: false,
    arrivalTime: false,
    airlines: false,
    priceRt: false,
    stopsRt: false,
    departureTimeRt: false,
    arrivalTimeRt: false,
    airlinesRt: false,
  });
  const image_base = BASE_URL_IMAGE_VIEW + "agency/view-file";

  const resetOnwardAirlinesList = props.filters?.onwardCarrierFilter?.map(
    (airline) => {
      return { ...airline, selected: false };
    }
  );
  const resetreturnAirlinesList = props.filters?.returnCarrierFilter?.map(
    (airline) => {
      return { ...airline, selected: false };
    }
  );
  const [airlinesList, setAirlinesList] = React.useState(
    resetOnwardAirlinesList
  );
  const [airlinesListRt, setAirlinesListRt] = React.useState(
    resetreturnAirlinesList
  );

  // Slider
  const [priceSliderValues, setPriceSliderValue] = React.useState([MIN, MAX]);
  const [priceSliderValuesRt, setPriceSliderValueRt] = React.useState([
    MINRT,
    MAXRT,
  ]);
  const initialDepartureTime = {
    dawn: false,
    morning: false,
    afternoon: false,
    evening: false,
    // night: false,
  };
  const initialArrivalTime = {
    dawn: false,
    morning: false,
    afternoon: false,
    evening: false,
    //  night: false,
  };
  const [departureTime, setDepartureTime] = React.useState(
    initialDepartureTime
  );

  const [departureTimeRt, setDepartureTimeRt] = React.useState(
    initialDepartureTime
  );

  const [arrivalTime, setArrivaleTime] = React.useState(initialArrivalTime);

  const [arrivalTimeRt, setArrivaleTimeRt] = React.useState(initialArrivalTime);
  const [stops, setStops] = React.useState([]);
  const [stopsRt, setStopsRt] = React.useState([]);
  // filter test

  const [onwardFilter, setOnwardFilter] = React.useState({
    price: false,
    stops: false,
    departureTime: false,
    arrivalTime: false,
    airlines: false,
  });
  const [returnFilter, setReturnFilter] = React.useState({
    price: false,
    stops: false,
    departureTime: false,
    arrivalTime: false,
    airlines: false,
  });

  const sortByPrice = (arrayData) => {
    return arrayData.sort(function (a, b) {
      if (a.fares.length > 1) {
        a.fares.sort((c, d) => {
          if (parseFloat(c.baseFare + c.tax) > parseFloat(d.baseFare + d.tax)) {
            return 1;
          }
          if (parseFloat(c.baseFare + c.tax) < parseFloat(d.baseFare + d.tax)) {
            return -1;
          } else {
            return 0;
          }
        });
      }

      if (b.fares.length > 1) {
        b.fares.sort((c, d) => {
          if (parseFloat(c.baseFare + c.tax) > parseFloat(d.baseFare + d.tax)) {
            return 1;
          }
          if (parseFloat(c.baseFare + c.tax) < parseFloat(d.baseFare + d.tax)) {
            return -1;
          } else {
            return 0;
          }
        });
      }

      let sortKeyA = a.fares[0].baseFare + a.fares[0].tax;
      let sortKeyB = b.fares[0].baseFare + b.fares[0].tax;

      if (parseFloat(sortKeyA) > parseFloat(sortKeyB)) {
        return 1;
      } else if (parseFloat(sortKeyA) < parseFloat(sortKeyB)) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  const sortByStops = (arrayData) => {
    return arrayData.slice().sort(function (a, b) {
      if (a.segment.length > b.segment.length) {
        return 1;
      } else if (a.segment.length < b.segment.length) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  const sortByDepTime = (arrayData) => {
    return arrayData.slice().sort(function (a, b) {
      let sortKeyA = new Date(a.segment[0].departDetails.dateTime).getTime();
      let sortKeyB = new Date(b.segment[0].departDetails.dateTime).getTime();

      if (sortKeyA > sortKeyB) {
        return 1;
      } else if (sortKeyA < sortKeyB) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  const sortByArrTime = (arrayData) => {
    return arrayData.slice().sort(function (a, b) {
      let sortKeyA = new Date(
        a.segment[a.segment.length - 1].arriveDetails.dateTime
      ).getTime();
      let sortKeyB = new Date(
        b.segment[b.segment.length - 1].arriveDetails.dateTime
      ).getTime();

      if (sortKeyA > sortKeyB) {
        return 1;
      } else if (sortKeyA < sortKeyB) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  const sortByAirlines = (arrayData) => {
    return arrayData.slice().sort(function (a, b) {
      let sortKeyA = a.segment[0].carrierDetails.carrierName;
      let sortKeyB = b.segment[0].carrierDetails.carrierName;

      if (sortKeyA > sortKeyB) {
        return 1;
      } else if (sortKeyA < sortKeyB) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  const sortData = (type, arrayData, tripMode) => {
    // console.log("arrayData", arrayData);
    let sortedData = [];
    switch (type) {
      case "price": {
        sortedData = sortByPrice(arrayData);
        tripMode == "ROUND"
          ? setSorted({ ...sorted, priceRt: true })
          : setSorted({ ...sorted, price: true });
        break;
      }

      case "stops": {
        sortedData = sortByStops(arrayData);
        tripMode == "ROUND"
          ? setSorted({ ...sorted, stopsRt: true })
          : setSorted({ ...sorted, stops: true });
        break;
      }

      case "dep-time": {
        sortedData = sortByDepTime(arrayData);
        tripMode == "ROUND"
          ? setSorted({ ...sorted, departureTimeRt: true })
          : setSorted({ ...sorted, departureTime: true });
        break;
      }

      case "arr-time": {
        sortedData = sortByArrTime(arrayData);
        tripMode == "ROUND"
          ? setSorted({ ...sorted, arrivalTimeRt: true })
          : setSorted({ ...sorted, arrivalTime: true });
        break;
      }

      case "airlines": {
        sortedData = sortByAirlines(arrayData);
        tripMode == "ROUND"
          ? setSorted({ ...sorted, airlinesRt: true })
          : setSorted({ ...sorted, airlines: true });
        break;
      }
    }

    // console.log("sortedData", sortedData);
    tripMode == "ROUND"
      ? sortedData && props.setModifiedResponseReturn(sortedData)
      : sortedData && props.setModifiedResponseDepart(sortedData);
  };

  const clearSort = (type, tripMode) => {
    // console.log("clear type", type);

    let resetData = null;

    if (tripMode == "ROUND") {
      resetData = props?.retdata;
      props.setModifiedResponseReturn(resetData);

      switch (type) {
        case "price": {
          setSorted({ ...sorted, priceRt: false });
          break;
        }

        case "stops": {
          setSorted({ ...sorted, stopsRt: false });
          break;
        }

        case "dep-time": {
          setSorted({ ...sorted, departureTimeRt: false });
          break;
        }

        case "arr-time": {
          setSorted({ ...sorted, arrivalTimeRt: false });
          break;
        }

        case "airlines": {
          setSorted({ ...sorted, airlinesRt: false });
          break;
        }

        case "all": {
          setSorted({
            ...sorted,
            priceRt: false,
            stopsRt: false,
            departureTimeRt: false,
            arrivalTimeRt: false,
            airlinesRt: false,
          });
        }
      }
    } else {
      resetData = props?.onwdata;
      props.setModifiedResponseDepart(resetData);

      switch (type) {
        case "price": {
          setSorted({ ...sorted, price: false });
          break;
        }

        case "stops": {
          setSorted({ ...sorted, stops: false });
          break;
        }

        case "dep-time": {
          setSorted({ ...sorted, departureTime: false });
          break;
        }

        case "arr-time": {
          setSorted({ ...sorted, arrivalTime: false });
          break;
        }

        case "airlines": {
          setSorted({ ...sorted, airlines: false });
          break;
        }

        case "all": {
          setSorted({
            ...sorted,
            price: false,
            stops: false,
            departureTime: false,
            arrivalTime: false,
            airlines: false,
          });
        }
      }
    }
  };

  const filterByPrice = (priceRange, arrayData, tripMode) => {
    const lowerLimit = parseFloat(priceRange[0]);
    const upperLimit = parseFloat(priceRange[1]);

    let filteredData = null;

    if (lowerLimit > 0 && upperLimit > 0) {
      tripMode === "ROUND"
        ? setPriceSliderValueRt(priceRange)
        : setPriceSliderValue(priceRange);
      filteredData = arrayData
        .map((data) => {
          let totalFareRef = [];
          let fareArray = [];
          for (let i = 0; i < data?.fares?.length; i++) {
            let fareObj = data?.fares[i];
            const totalFare =
              parseFloat(fareObj?.baseFare) + parseFloat(fareObj?.tax);
            if (totalFare >= lowerLimit && totalFare <= upperLimit) {
              if (!totalFareRef.includes(totalFare)) {
                totalFareRef = totalFareRef.concat(totalFare);
                fareArray = fareArray.concat(fareObj);
              }
            }
          }
          /* fareArray = fareArray.sort((fare1, fare2) => {
            const totalFare1 =
              parseFloat(fare1?.baseFare) + parseFloat(fare1?.tax);
            const totalFare2 =
              parseFloat(fare2?.baseFare) + parseFloat(fare2?.tax);
            return totalFare1 - totalFare2;
          }); */
          return fareArray.length > 0 ? { ...data, fares: fareArray } : 0;
        })
        .filter((data) => data !== 0);

      /* filteredData = arrayData.filter((data) => {
        return (
          parseFloat(data?.fares[0]?.baseFare) +
            parseFloat(data?.fares[0]?.tax) >=
            lowerLimit &&
          parseFloat(data?.fares[0].baseFare) +
            parseFloat(data?.fares[0].tax) <=
            upperLimit
        );
      }); */

      /*tripMode && (tripMode == "ROUND" || tripMode == "ROUND-SP")
        ? props.setModifiedResponseReturn(filteredData)
        : props.setModifiedResponseDepart(filteredData); */
    } else {
      tripMode === "ROUND"
        ? setPriceSliderValueRt([MINRT, MAXRT])
        : setPriceSliderValue([MIN, MAX]);
      //setPriceSliderValue([MIN, MAX]);
      /*filteredData =
        tripMode && (tripMode == "ROUND" || tripMode == "ROUND-SP")
          ? props?.retdata
          : props?.onwdata; */
      filteredData = arrayData;
    }

    /* tripMode && (tripMode == "ROUND" || tripMode == "ROUND-SP")
      ? props.setModifiedResponseReturn(filteredData)
      : props.setModifiedResponseDepart(filteredData); */
    return filteredData;
  };

  const filterByStops = (stop, arrayData, tripMode) => {
  
    let filteredData = null;
    if (stop.length > 0) {
      filteredData = arrayData.filter((data) => {
        let flag = false;
        for (let i = 0; i < stop.length; i++) {
          if (stop[i] > 0) {
            if (stop < 3) {
              flag = data.segment.length === stop[i];
              if (flag) break;
            } else {
              flag = data.segment.length >= stop[i];
              if (flag) break;
            }
          }
        }
        return flag;
      });
    } else {
      filteredData = arrayData;
    }

    return filteredData;

    /* if (stop > 0) {
      if (stop < 3) {
        filteredData = arrayData.filter((data) => {
          return data.segment.length == stop;
        });
      } else {
        filteredData = arrayData.filter((data) => {
          return data.segment.length >= stop;
        });
      }
    } else {
      /*filteredData =
        tripMode && (tripMode == "ROUND" || tripMode == "ROUND-SP")
          ? props?.retdata
          : props?.onwdata; 
      filteredData = arrayData;
    }

    /* tripMode && (tripMode == "ROUND" || tripMode == "ROUND-SP")
      ? props.setModifiedResponseReturn(filteredData)
      : props.setModifiedResponseDepart(filteredData); 
    return filteredData; */
  };

  const filterByDepartureTime = (timeSpan, arrayData, tripMode) => {
    if (timeSpan != null) {
      if (
        timeSpan.dawn == false &&
        timeSpan.morning == false &&
        timeSpan.afternoon == false &&
        timeSpan.evening == false
        //&& timeSpan.night == false
      ) {
        /* return tripMode && (tripMode == "ROUND" || tripMode == "ROUND-SP")
          ? props?.retdata
          : props?.onwdata; */
        /* tripMode && (tripMode == "ROUND" || tripMode == "ROUND-SP")
          ? props.setModifiedResponseReturn(props?.retdata)
          : props.setModifiedResponseDepart(props?.onwdata);
        return; */
        return arrayData;
      }

      const filteredData = arrayData.filter((data) => {
        const departureTime = dateFnsFormat(
          new Date(data.segment[0].departDetails.dateTime),
          "HH:MM:SS"
        );
        const departureDate = new Date(data.segment[0].departDetails.dateTime);
        const upperLimitNight = new Date(
          departureDate.getFullYear(),
          departureDate.getMonth(),
          departureDate.getDate() + 1,
          3,
          1
        ).getTime();
        const lowerLimitNight = new Date(
          departureDate.getFullYear(),
          departureDate.getMonth(),
          departureDate.getDate(),
          20,
          59
        ).getTime();

        const timeBucket = [
          {
            name: "dawn",
            upperLimit: "06:00:00",
            lowerLimit: "00:00:00",
            isEnabled: timeSpan.dawn,
          },
          {
            name: "morning",
            upperLimit: "12:00:00",
            lowerLimit: "06:00:00",
            isEnabled: timeSpan.morning,
          },
          {
            name: "afternoon",
            upperLimit: "18:00:00",
            lowerLimit: "12:00:00",
            isEnabled: timeSpan.afternoon,
          },
          {
            name: "evening",
            upperLimit: "23:59:59",
            lowerLimit: "18:00:00",
            isEnabled: timeSpan.evening,
          },
          // {
          //   name: "night",
          //   upperLimit: upperLimitNight,
          //   lowerLimit: lowerLimitNight,
          //   isEnabled: timeSpan.night,
          // },
        ];

        let returnFlag = false;

        for (var i = 0; i < timeBucket.length; i++) {
          const tb = timeBucket[i];

          if (
            (tb.isEnabled == true &&
              tb.name == "night" &&
              departureDate.getTime() < tb.upperLimit &&
              departureDate.getTime() > tb.lowerLimit) ||
            (tb.isEnabled == true &&
              tb.name != "night" &&
              departureTime <= tb.upperLimit &&
              departureTime >= tb.lowerLimit)
          ) {
            returnFlag = true;
            break;
          }
        }

        return returnFlag;
      });

      /* tripMode && (tripMode == "ROUND" || tripMode == "ROUND-SP")
        ? props.setModifiedResponseReturn(filteredData)
        : props.setModifiedResponseDepart(filteredData); */
      return filteredData;
    } else {
      /* tripMode && (tripMode == "ROUND" || tripMode == "ROUND-SP")
        ? props.setModifiedResponseReturn(props?.retdata)
        : props.setModifiedResponseDepart(props?.onwdata); */
      /* return tripMode && (tripMode == "ROUND" || tripMode == "ROUND-SP")
        ? props?.retdata
        : props?.onwdata; */
      return arrayData;
    }
  };

  const filterByArrivalTime = (timeSpan, arrayData, tripMode) => {
    if (timeSpan != null) {
      if (
        timeSpan.dawn == false &&
        timeSpan.morning == false &&
        timeSpan.afternoon == false &&
        timeSpan.evening == false
        // && timeSpan.night == false
      ) {
        /* return tripMode && (tripMode == "ROUND" || tripMode == "ROUND-SP")
          ? props?.retdata
          : props?.onwdata; */
        /* tripMode && (tripMode == "ROUND" || tripMode == "ROUND-SP")
          ? props.setModifiedResponseReturn(props?.retdata)
          : props.setModifiedResponseDepart(props?.onwdata);
        return; */
        return arrayData;
      }

      const filteredData = arrayData.filter((data) => {
        const lastIndex = data.segment.length - 1;
        const arrivalTime = dateFnsFormat(
          new Date(data.segment[lastIndex].arriveDetails.dateTime),
          "HH:MM:SS"
        );
        const arrivalDate = new Date(
          data.segment[lastIndex].arriveDetails.dateTime
        );
        const upperLimitNight = new Date(
          arrivalDate.getFullYear(),
          arrivalDate.getMonth(),
          arrivalDate.getDate() + 1,
          3,
          1
        ).getTime();
        const lowerLimitNight = new Date(
          arrivalDate.getFullYear(),
          arrivalDate.getMonth(),
          arrivalDate.getDate(),
          20,
          59
        ).getTime();

        const timeBucket = [
          {
            name: "dawn",
            upperLimit: "06:00:00",
            lowerLimit: "00:00:00",
            isEnabled: timeSpan.dawn,
          },
          {
            name: "morning",
            upperLimit: "12:00:00",
            lowerLimit: "06:00:00",
            isEnabled: timeSpan.morning,
          },
          {
            name: "afternoon",
            upperLimit: "18:00:00",
            lowerLimit: "12:00:00",
            isEnabled: timeSpan.afternoon,
          },
          {
            name: "evening",
            upperLimit: "23:59:59",
            lowerLimit: "18:00:00",
            isEnabled: timeSpan.evening,
          },
          // {
          //   name: "night",
          //   upperLimit: upperLimitNight,
          //   lowerLimit: lowerLimitNight,
          //   isEnabled: timeSpan.night,
          // },
        ];

        let returnFlag = false;

        for (var i = 0; i < timeBucket.length; i++) {
          const tb = timeBucket[i];

          if (
            (tb.isEnabled == true &&
              tb.name == "night" &&
              arrivalDate.getTime() < tb.upperLimit &&
              arrivalDate.getTime() > tb.lowerLimit) ||
            (tb.isEnabled == true &&
              tb.name != "night" &&
              arrivalTime <= tb.upperLimit &&
              arrivalTime >= tb.lowerLimit)
          ) {
            returnFlag = true;
            break;
          }
        }

        return returnFlag;
      });

      /*tripMode && (tripMode == "ROUND" || tripMode == "ROUND-SP")
        ? props.setModifiedResponseReturn(filteredData)
        : props.setModifiedResponseDepart(filteredData);*/
      return filteredData;
    } else {
      /* tripMode && (tripMode == "ROUND" || tripMode == "ROUND-SP")
        ? props.setModifiedResponseReturn(props?.retdata)
        : props.setModifiedResponseDepart(props?.onwdata);*/
      /* return tripMode && (tripMode == "ROUND" || tripMode == "ROUND-SP")
        ? props?.retdata
        : props?.onwdata; */
      return arrayData;
    }
  };

  const filterByAirlines = (airlineList, arrayData, tripMode) => {
    if (airlineList != null) {
      let filterFlag = false;
      const filteredData = arrayData.filter((data) => {
        const operatingAirlineCode = data.segment[0].carrierDetails.carrierCode;

        let returnFlag = false;

        /*  let one_selected = false;
        for (var i = 0; i < airlineList.length; i++) {
          const airlinesObj = airlineList[i];

          if (airlinesObj.isSelected == true) {
            one_selected = true;
            break;
          }
        } 
        // console.log (one_selected);
        if (!one_selected) {
          airlineList[0].checked = true;
        } */

        for (var i = 0; i < airlineList.length; i++) {
          const airlinesObj = airlineList[i];
          if (airlinesObj.selected) {
            filterFlag = true;
            if (airlinesObj.value === "all") {
              returnFlag = true;
              break;
            }
            returnFlag = airlinesObj.carriers?.includes(operatingAirlineCode);
            if (returnFlag) break;
          }
          // if (one_selected) {
          /* if (airlinesObj.value === "all" && airlinesObj.selected === true) {
            returnFlag = true;
            break;
          }

          if (airlinesObj.value !== "all" && airlinesObj.selected === true) {
            console.log(airlinesObj.value);
            console.log("airline code", operatingAirlineCode);

            returnFlag = airlinesObj.carriers.includes(operatingAirlineCode);
            console.log("return flag", returnFlag);
            break;
          } else {
            returnFlag = false;
            // break;
          } */
        }

        return returnFlag;
      });

      /*tripMode && (tripMode == "ROUND" || tripMode == "ROUND-SP")
        ? props.setModifiedResponseReturn(filteredData)
        : props.setModifiedResponseDepart(filteredData);*/
      return filterFlag ? filteredData : arrayData;
    } else {
      /* tripMode && (tripMode == "ROUND" || tripMode == "ROUND-SP")
        ? props.setModifiedResponseReturn(props?.retdata)
        : props.setModifiedResponseDepart(props?.onwdata); */
      /* return tripMode && (tripMode == "ROUND" || tripMode == "ROUND-SP")
        ? props?.retdata
        : props?.onwdata; */
      return arrayData;
    }
  };

  // price filter handle
  const handlePriceSliderValuesChange = (e, v) => {
    setPriceSliderValue(v);
    setOnwardFilter({
      ...onwardFilter,
      price: true,
    });
    // filterByPrice(v, props?.onwdata, "ONE");
  };

  const handlePriceSliderValuesChangeRt = (e, v) => {
    setPriceSliderValueRt(v);
    setReturnFilter({
      ...returnFilter,
      price: true,
    });
    //filterByPrice(v, props?.retdata, props.tripMode);
  };

  const handlePriceSliderValueMinChange = (e) => {
    setPriceSliderValue([+e.target.value, priceSliderValues[1]]);
    setOnwardFilter({
      ...onwardFilter,
      price: true,
    });
    /* filterByPrice(
      [+e.target.value, priceSliderValues[1]],
      props?.onwdata,
      "ONE"
    ); */
  };

  const handlePriceSliderValueMaxChange = (e) => {
    setPriceSliderValue([priceSliderValues[0], +e.target.value]);
    setOnwardFilter({
      ...onwardFilter,
      price: true,
    });
    /* filterByPrice(
      [priceSliderValues[0], +e.target.value],
      props?.onwdata,
      "ONE"
    ); */
  };

  const handlePriceSliderValueMinChangeRt = (e) => {
    // console.log("min", e.target.value);
    setPriceSliderValueRt([+e.target.value, priceSliderValuesRt[1]]);
    setReturnFilter({
      ...returnFilter,
      price: true,
    });
    /*filterByPrice(
      [+e.target.value, priceSliderValuesRt[1]],
      props?.retdata,
      props.tripMode
    ); */
  };

  const handlePriceSliderValueMaxChangeRt = (e) => {
    // console.log("max", e.target.value);
    setPriceSliderValueRt([priceSliderValuesRt[0], +e.target.value]);
    setReturnFilter({
      ...returnFilter,
      price: true,
    });
    /* filterByPrice(
      [priceSliderValuesRt[0], +e.target.value],
      props?.retdata,
      props.tripMode
    ); */
  };

  const handleBlur = () => {
    [0, 1].forEach((i) => {
      if (priceSliderValues[i] < MIN) {
        setPriceSliderValue([MIN, priceSliderValues[1]]);
      } else if (priceSliderValues[i] > MAX) {
        setPriceSliderValue([priceSliderValues[0], MAX]);
      }
    });
  };

  const handleBlurRt = () => {
    [0, 1].forEach((i) => {
      if (priceSliderValuesRt[i] < MINRT) {
        setPriceSliderValueRt([MINRT, priceSliderValuesRt[1]]);
      } else if (priceSliderValuesRt[i] > MAXRT) {
        setPriceSliderValueRt([priceSliderValuesRt[0], MAXRT]);
      }
    });
  };

  const handleDepartureTimeChange = (e) => {
    const alteredTimeSpan = {
      ...departureTime,
      [e.target.name]: e.target.checked,
    };
    // filterByDepartureTime(alteredTimeSpan, props?.onwdata, "ONE");

    setDepartureTime(alteredTimeSpan);
    if (
      JSON.stringify(initialDepartureTime) === JSON.stringify(alteredTimeSpan)
    ) {
      setOnwardFilter({
        ...onwardFilter,
        departureTime: false,
      });
    } else {
      setOnwardFilter({
        ...onwardFilter,
        departureTime: true,
      });
    }
  };

  const handleDepartureTimeChangeRt = (e) => {
    const alteredTimeSpan = {
      ...departureTimeRt,
      [e.target.name]: e.target.checked,
    };
    // filterByDepartureTime(alteredTimeSpan, props?.retdata, "ROUND");
    setDepartureTimeRt(alteredTimeSpan);
    if (
      JSON.stringify(initialDepartureTime) === JSON.stringify(alteredTimeSpan)
    ) {
      setReturnFilter({
        ...returnFilter,
        departureTime: false,
      });
    } else {
      setReturnFilter({
        ...returnFilter,
        departureTime: true,
      });
    }
  };

  const handleArrivalTimeChange = (e) => {
    const alteredTimeSpan = {
      ...arrivalTime,
      [e.target.name]: e.target.checked,
    };
    // filterByArrivalTime(alteredTimeSpan, props?.onwdata, "ONE");
    setArrivaleTime(alteredTimeSpan);
    if (
      JSON.stringify(initialArrivalTime) === JSON.stringify(alteredTimeSpan)
    ) {
      setOnwardFilter({
        ...onwardFilter,
        arrivalTime: false,
      });
    } else {
      setOnwardFilter({
        ...onwardFilter,
        arrivalTime: true,
      });
    }
  };

  const handleArrivalTimeChangeRt = (e) => {
    const alteredTimeSpan = {
      ...arrivalTimeRt,
      [e.target.name]: e.target.checked,
    };
    // filterByArrivalTime(alteredTimeSpan, props?.retdata, "ROUND");
    setArrivaleTimeRt(alteredTimeSpan);
    if (
      JSON.stringify(initialArrivalTime) === JSON.stringify(alteredTimeSpan)
    ) {
      setReturnFilter({
        ...returnFilter,
        arrivalTime: false,
      });
    } else {
      setReturnFilter({
        ...returnFilter,
        arrivalTime: true,
      });
    }
  };

  const handleAirlinesListChange = (e) => {
    const alteredAirlineList = [...airlinesList];
    const selectedIndex = airlinesList.findIndex(
      (row) => row.value == e.target.name
    );

    alteredAirlineList[selectedIndex] = {
      ...alteredAirlineList[selectedIndex],
      selected: e.target.checked,
    };

    // filterByAirlines(alteredAirlineList, props?.onwdata, "ONE");
    setAirlinesList(alteredAirlineList);
    setOnwardFilter({
      ...onwardFilter,
      airlines: true,
    });
  };

  const handleAirlinesListChangeRt = (e) => {
    // console.log("e.target.name", e.target.name);

    const alteredAirlineList = [...airlinesListRt];
    const selectedIndex = airlinesListRt.findIndex(
      (row) => row.value == e.target.name
    );

    alteredAirlineList[selectedIndex] = {
      ...alteredAirlineList[selectedIndex],
      selected: e.target.checked,
    };

    // filterByAirlines(alteredAirlineList, props?.retdata, "ROUND");
    setAirlinesListRt(alteredAirlineList);
    setReturnFilter({
      ...returnFilter,
      airlines: true,
    });
  };

  // onward filter
  React.useEffect(() => {
    props.setIsLoading(true);
    let onwardData = props?.onwdata;
    // console.log(onwardData);
    // console.log("onward filter", onwardFilter);
    if (onwardFilter.price) {
      onwardData = filterByPrice(priceSliderValues, onwardData, "ONE");
    }
    if (onwardFilter.stops) {
      onwardData = filterByStops(stops, onwardData, "ONE");
    }
    if (onwardFilter.airlines) {
      onwardData = filterByAirlines(airlinesList, onwardData, "ONE");
    }
    if (onwardFilter.arrivalTime) {
      onwardData = filterByArrivalTime(arrivalTime, onwardData, "ONE");
    }
    if (onwardFilter.departureTime) {
      onwardData = filterByDepartureTime(departureTime, onwardData, "ONE");
    }
    props.setIsLoading(false);

    props.setModifiedResponseDepart(onwardData);
  }, [priceSliderValues, stops, airlinesList, arrivalTime, departureTime]);

  //return filter
  React.useEffect(() => {
    props.setIsLoading(true);
    let returnData = props?.retdata;
    if (returnFilter.price) {
      returnData = filterByPrice(
        priceSliderValuesRt,
        returnData,
        props.tripMode
      );
    }
    if (returnFilter.stops) {
      returnData = filterByStops(stopsRt, returnData, props.tripMode);
    }
    if (returnFilter.airlines) {
      returnData = filterByAirlines(airlinesListRt, returnData, props.tripMode);
    }
    if (returnFilter.arrivalTime) {
      returnData = filterByArrivalTime(
        arrivalTimeRt,
        returnData,
        props.tripMode
      );
    }
    if (returnFilter.departureTime) {
      returnData = filterByDepartureTime(
        departureTimeRt,
        returnData,
        props.tripMode
      );
    }
    const timeout = setTimeout(() => props.setIsLoading(false), 2000);
    props.setModifiedResponseReturn(returnData);
    return () => clearTimeout(timeout);
  }, [
    stopsRt,
    priceSliderValuesRt,
    airlinesListRt,
    arrivalTimeRt,
    departureTimeRt,
  ]);

  React.useEffect(() => {
    if (props?.onwdata != null) {
      const sortedByPrice = sortByPrice(props?.onwdata);
      const maxPriceArrInd =
        sortedByPrice[sortedByPrice.length - 1].fares.length - 1;

      setMIN(
        parseFloat(sortedByPrice[0].fares[0].baseFare) +
        parseFloat(sortedByPrice[0].fares[0].tax)
      );
      setMAX(
        parseFloat(
          sortedByPrice[sortedByPrice.length - 1].fares[maxPriceArrInd].baseFare
        ) +
        parseFloat(
          sortedByPrice[sortedByPrice.length - 1].fares[maxPriceArrInd].tax
        )
      );

      setPriceSliderValue([
        parseFloat(sortedByPrice[0].fares[0].baseFare) +
        parseFloat(sortedByPrice[0].fares[0].tax),
        parseFloat(
          sortedByPrice[sortedByPrice.length - 1].fares[maxPriceArrInd].baseFare
        ) +
        parseFloat(
          sortedByPrice[sortedByPrice.length - 1].fares[maxPriceArrInd].tax
        ),
      ]);

      props.setModifiedResponseDepart(sortedByPrice);
    }

    // return
    if (
      (props.tripMode == "ROUND" || props.tripMode == "ROUND-SP") &&
      props?.retdata != null
    ) {
      const sortedByPriceRt = sortByPrice(props?.retdata);
      const maxPriceArrIndRt =
        sortedByPriceRt[sortedByPriceRt.length - 1].fares.length - 1;
      setMINRT(
        parseFloat(sortedByPriceRt[0].fares[0].baseFare) +
        parseFloat(sortedByPriceRt[0].fares[0].tax)
      );
      setMAXRT(
        parseFloat(
          sortedByPriceRt[sortedByPriceRt.length - 1].fares[maxPriceArrIndRt]
            .baseFare
        ) +
        parseFloat(
          sortedByPriceRt[sortedByPriceRt.length - 1].fares[maxPriceArrIndRt]
            .tax
        )
      );
      setPriceSliderValueRt([
        parseFloat(sortedByPriceRt[0].fares[0].baseFare) +
        parseFloat(sortedByPriceRt[0].fares[0].tax),
        parseFloat(
          sortedByPriceRt[sortedByPriceRt.length - 1].fares[maxPriceArrIndRt]
            .baseFare
        ) +
        parseFloat(
          sortedByPriceRt[sortedByPriceRt.length - 1].fares[maxPriceArrIndRt]
            .tax
        ),
      ]);

      props.setModifiedResponseReturn(sortedByPriceRt);
    }

    setIsLoading(false);
  }, []);

  const [value, setValue] = React.useState("price");
  const [retvalue, setRetValue] = React.useState("price");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleChangeRet = (event) => {
    setRetValue(event.target.value);
  };

  return (
    !isLoading && (
      <div className={classes.root} style={{ height: "100vh" }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className="filter-container"
        >
          {/* <Grid item className={classes.filter_title_root}>
          <span>Filter</span>
        </Grid> */}

          {/* price filter start */}
          <Grid item className="filter-element-container">
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={1}
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item className="filter-element-title-main">
                    <span>Price</span>
                  </Grid>
                  <Grid item>
                    <IconButton
                      aria-label="clear price"
                      size="small"
                      onClick={() =>
                        setOpenDiv({
                          ...openDiv,
                          openPriceFilter: !openDiv?.openPriceFilter,
                        })
                      }
                      className="collapse-icon"
                    >
                      {openDiv?.openPriceFilter == true ? (
                        <ArrowDropUp />
                      ) : (
                        <ArrowDropDown />
                      )}
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Collapse in={openDiv?.openPriceFilter} timeout={0}>
                <>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={1}
                    className="tripmode-wrapper"
                  >
                    {(props.tripMode == "ROUND" ||
                      props.tripMode == "ROUND-SP") && (
                        <Grid item className="tripmode-header">
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item className="tripmode-title">
                              <span>Onward</span>
                            </Grid>
                            <Grid item>
                              <IconButton
                                aria-label="clear price"
                                size="small"
                                onClick={
                                  () => {
                                    setPriceSliderValue([0, 0]);
                                    setOnwardFilter({
                                      ...onwardFilter,
                                      price: false,
                                    });
                                  }
                                  // filterByPrice([0, 0], props?.onwdata, "ONE")
                                }
                                className="reset-filter"
                              >
                                Clear
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    <Grid item>
                      <PriceSlider
                        value={priceSliderValues}
                        onChange={handlePriceSliderValuesChange}
                        valueLabelDisplay="auto"
                        min={MIN}
                        max={MAX}
                        valueLabelFormat={(x) => `${Math.floor(x / 1000)}k`}
                        aria-labelledby="range-slider"
                      />
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-around"
                        alignItems="stretch"
                      >
                        <Grid item>
                          <Input
                            className="price-slider-value-input"
                            color="secondary"
                            value={priceSliderValues[0]}
                            onChange={handlePriceSliderValueMinChange}
                            onBlur={handleBlur}
                            inputProps={{
                              step: STEP,
                              min: MIN,
                              max: MAX,
                              type: "number",
                              "aria-labelledby": "input-slider",
                            }}
                          />
                        </Grid>
                        <Grid item>to</Grid>
                        <Grid item>
                          <Input
                            className="price-slider-value-input"
                            color="secondary"
                            value={priceSliderValues[1]}
                            onChange={handlePriceSliderValueMaxChange}
                            onBlur={handleBlur}
                            inputProps={{
                              step: STEP,
                              min: MIN,
                              max: MAX,
                              type: "number",
                              "aria-labelledby": "input-slider",
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* ROUND TRIP CODE */}
                  {(props.tripMode == "ROUND" ||
                    props.tripMode == "ROUND-SP") && (
                      <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={1}
                        className="tripmode-wrapper"
                      >
                        <Grid item className="tripmode-header">
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item className="tripmode-title">
                              <span>Return</span>
                            </Grid>
                            <Grid item>
                              <IconButton
                                aria-label="clear price"
                                size="small"
                                onClick={
                                  () => {
                                    setPriceSliderValueRt([0, 0]);
                                    setReturnFilter({
                                      ...returnFilter,
                                      price: false,
                                    });
                                  } /*filterByPrice(
                                  [0, 0],
                                  props?.retdata,
                                  props.tripMode
                                ) */
                                }
                                className="reset-filter"
                              >
                                Clear
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <PriceSlider
                            value={priceSliderValuesRt}
                            onChange={handlePriceSliderValuesChangeRt}
                            valueLabelDisplay="auto"
                            min={MINRT}
                            max={MAXRT}
                            valueLabelFormat={(x) => `${Math.floor(x / 1000)}k`}
                            aria-labelledby="range-slider"
                          />
                        </Grid>
                        <Grid item>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-around"
                            alignItems="stretch"
                          >
                            <Grid item>
                              <Input
                                className="price-slider-value-input"
                                color="secondary"
                                value={priceSliderValuesRt[0]}
                                onChange={handlePriceSliderValueMinChangeRt}
                                onBlur={handleBlurRt}
                                inputProps={{
                                  step: STEP,
                                  min: MINRT,
                                  max: MAXRT,
                                  type: "number",
                                  "aria-labelledby": "input-slider",
                                }}
                              />
                            </Grid>
                            <Grid item>to</Grid>
                            <Grid item>
                              <Input
                                className="price-slider-value-input"
                                color="secondary"
                                value={priceSliderValuesRt[1]}
                                onChange={handlePriceSliderValueMaxChangeRt}
                                onBlur={handleBlurRt}
                                inputProps={{
                                  step: STEP,
                                  min: MINRT,
                                  max: MAXRT,
                                  type: "number",
                                  "aria-labelledby": "input-slider",
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                </>
              </Collapse>
            </Grid>
          </Grid>
          {/* price filter end */}

          {/* stop filter start */}
          <Grid item className="filter-element-container">
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item className="filter-element-title-main">
                    <span>Stops</span>
                  </Grid>
                  <Grid item>
                    <IconButton
                      aria-label="clear price"
                      size="small"
                      onClick={() =>
                        setOpenDiv({
                          ...openDiv,
                          openStopsFilter: !openDiv?.openStopsFilter,
                        })
                      }
                      className="collapse-icon"
                    >
                      {openDiv?.openStopsFilter == true ? (
                        <ArrowDropUp />
                      ) : (
                        <ArrowDropDown />
                      )}
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Collapse in={openDiv?.openStopsFilter} timeout={0}>
                <>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={1}
                    className="tripmode-wrapper"
                  >
                    {(props.tripMode == "ROUND" ||
                      props.tripMode == "ROUND-SP") && (
                        <Grid item className="tripmode-header">
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item className="tripmode-title">
                              <span>Onward</span>
                            </Grid>
                            <Grid item>
                              <IconButton
                                aria-label="clear stops"
                                size="small"
                                onClick={() => {
                                  setCurrentFilterByStop([]);
                                  setStops([]);
                                  setOnwardFilter({
                                    ...onwardFilter,
                                    stops: false,
                                  });
                                  // filterByStops(-1, null, "ONE");
                                }}
                                className="reset-filter"
                              >
                                Clear
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    {/* STOP FILTER */}
                    <Grid item>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <FormGroup>
                          {/* {console.log("props.filters?.onwardStopsFilter",props.filters?.onwardStopsFilter)} */}
                          {props.filters?.onwardStopsFilter.map((s, i) => (
                            <FormControlLabel
                              key={i}
                              label={s.label}
                              control={
                                <Checkbox
                                  color="secondary"
                                  size="small"
                                  value={s.value}
                                  checked={currentFilterByStop.includes(
                                    s.value
                                  )}
                                  onClick={(event) => {
                                    if (event.target.checked) {
                                      setCurrentFilterByStop([
                                        ...currentFilterByStop,
                                        event.target.value,
                                      ]);
                                      setStops([
                                        ...stops,
                                        parseInt(event.target.value) + 1,
                                      ]);
                                      setOnwardFilter({
                                        ...onwardFilter,
                                        stops: true,
                                      });
                                      /* filterByStops(
                                        parseInt(event.target.value) + 1,
                                        props?.onwdata,
                                        "ONE"
                                      ); */
                                    } else {
                                      const checkedCurrentStops = currentFilterByStop.filter(
                                        (stop) => stop !== event.target.value
                                      );
                                      const checkedStops = stops.filter(
                                        (stop) =>
                                          stop !==
                                          parseInt(event.target.value) + 1
                                      );

                                      setCurrentFilterByStop(
                                        checkedCurrentStops
                                      );
                                      setStops(checkedStops);

                                      setOnwardFilter({
                                        ...onwardFilter,
                                        stops: true,
                                      });
                                      // filterByStops(-1, null, "ONE");
                                    }
                                  }}
                                  name="flight-stop-filter"
                                />
                              }
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {(props.tripMode == "ROUND" ||
                    props.tripMode == "ROUND-SP") && (
                      <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={1}
                        className="tripmode-wrapper"
                      >
                        <Grid item className="tripmode-header">
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item className="tripmode-title">
                              <span>Return</span>
                            </Grid>
                            <Grid item>
                              <IconButton
                                aria-label="clear stops"
                                size="small"
                                onClick={() => {
                                  setCurrentFilterByStopRt([]);
                                  setStopsRt([]);
                                  setReturnFilter({
                                    ...returnFilter,
                                    stops: false,
                                  });
                                  // filterByStops(-1, null, "ROUND");
                                }}
                                className="reset-filter"
                              >
                                Clear
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <FormControl component="fieldset">
                            <RadioGroup
                              defaultValue="non-stop-only"
                              aria-label="flight stops"
                              name="flight-stop-radios"
                            >
                              {props.filters?.returnStopsFilter.map((s, i) => (
                                <FormControlLabel
                                  key={i}
                                  label={s.label}
                                  control={
                                    <Checkbox
                                      color="secondary"
                                      size="small"
                                      value={s.value}
                                      checked={currentFilterByStopRt.includes(
                                        s.value
                                      )}
                                      onClick={(event) => {
                                        if (event.target.checked) {
                                          props.setModifiedFilter(true)
                                          setCurrentFilterByStopRt([
                                            ...currentFilterByStopRt,
                                            event.target.value,
                                          ]);
                                          setStopsRt([
                                            ...stopsRt,
                                            parseInt(event.target.value) + 1,
                                          ]);
                                          setReturnFilter({
                                            ...returnFilter,
                                            stops: true,
                                          });
                                          /* filterByStops(
                                            parseInt(event.target.value) + 1,
                                            props?.retdata,
                                            "ROUND"
                                          ); */
                                        } else {
                                          const checkedCurrentStopsRt = currentFilterByStopRt.filter(
                                            (stop) => stop !== event.target.value
                                          );
                                          const checkedStopsRt = stopsRt.filter(
                                            (stop) =>
                                              stop !==
                                              parseInt(event.target.value) + 1
                                          );
                                          setCurrentFilterByStopRt(
                                            checkedCurrentStopsRt
                                          );
                                          setStopsRt(checkedStopsRt);
                                          setReturnFilter({
                                            ...returnFilter,
                                            stops: true,
                                          });
                                          // filterByStops(-1, null, "ROUND");
                                        }
                                      }}
                                      name="flight-stop-filter-rt"
                                    />
                                  }
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}
                </>
              </Collapse>
            </Grid>
          </Grid>
          {/* stop filter end */}

          {/* depart time filter start */}
          <Grid item className="filter-element-container">
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item className="filter-element-title-main">
                    <span>Departure Time</span>
                  </Grid>
                  <Grid item>
                    <IconButton
                      aria-label="clear price"
                      size="small"
                      onClick={() =>
                        setOpenDiv({
                          ...openDiv,
                          openDepartFilter: !openDiv?.openDepartFilter,
                        })
                      }
                      className="collapse-icon"
                    >
                      {openDiv?.openDepartFilter == true ? (
                        <ArrowDropUp />
                      ) : (
                        <ArrowDropDown />
                      )}
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Collapse in={openDiv?.openDepartFilter} timeout={0}>
                <>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={1}
                    className="tripmode-wrapper"
                  >
                    {(props.tripMode == "ROUND" ||
                      props.tripMode == "ROUND-SP") && (
                        <Grid item className="tripmode-header">
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item className="tripmode-title">
                              <span>Onward</span>
                            </Grid>
                            <Grid item>
                              <IconButton
                                aria-label="clear departure time"
                                size="small"
                                onClick={() => {
                                  setDepartureTime({
                                    dawn: false,
                                    morning: false,
                                    afternoon: false,
                                    evening: false,
                                    night: false,
                                  });
                                  setOnwardFilter({
                                    ...onwardFilter,
                                    departureTime: false,
                                  });
                                  // filterByDepartureTime(null, null, "ONE");
                                }}
                                className="reset-filter"
                              >
                                Clear
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    <Grid item>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <FormGroup>
                          {props.filters?.onwardTimeFilter.map((v, i) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="secondary"
                                  size="small"
                                  checked={departureTime[v.name]}
                                  onChange={handleDepartureTimeChange}
                                  name={v.name}
                                />
                              }
                              label={
                                <>
                                  <span>
                                    <img
                                      src={getTimeSpanIcon(v.icon)}
                                      height={16}
                                      width={16}
                                      style={{ marginRight: "4px" }}
                                    />
                                  </span>
                                  <span>{v.label}</span>
                                </>
                              }
                              key={i}
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {(props.tripMode == "ROUND" ||
                    props.tripMode == "ROUND-SP") && (
                      <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={1}
                        className="tripmode-wrapper"
                      >
                        <Grid item className="tripmode-header">
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item className="tripmode-title">
                              <span>Return</span>
                            </Grid>
                            <Grid item>
                              <IconButton
                                aria-label="clear departure time"
                                size="small"
                                onClick={() => {
                                  setDepartureTimeRt({
                                    dawn: false,
                                    morning: false,
                                    afternoon: false,
                                    evening: false,
                                    night: false,
                                  });
                                  setReturnFilter({
                                    ...returnFilter,
                                    departureTime: false,
                                  });
                                  //filterByDepartureTime(null, null, "ROUND");
                                }}
                                className="reset-filter"
                              >
                                Clear
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <FormControl
                            component="fieldset"
                            className={classes.formControl}
                          >
                            <FormGroup>
                              {props.filters?.returnTimeFilter.map((v, i) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="secondary"
                                      size="small"
                                      checked={departureTimeRt[v.name]}
                                      onChange={handleDepartureTimeChangeRt}
                                      name={v.name}
                                    />
                                  }
                                  label={
                                    <>
                                      <span>
                                        <img
                                          src={getTimeSpanIcon(v.icon)}
                                          height={16}
                                          width={16}
                                          style={{ marginRight: "4px" }}
                                        />
                                      </span>
                                      <span>{v.label}</span>
                                    </>
                                  }
                                  key={i}
                                />
                              ))}
                            </FormGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}
                </>
              </Collapse>
            </Grid>
          </Grid>
          {/* depart time filter end */}

          {/* arrival time filter start */}
          <Grid item className="filter-element-container">
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item className="filter-element-title-main">
                    <span>Arrival Time</span>
                  </Grid>
                  <Grid item>
                    <IconButton
                      aria-label="clear price"
                      size="small"
                      onClick={() =>
                        setOpenDiv({
                          ...openDiv,
                          openArriveFilter: !openDiv?.openArriveFilter,
                        })
                      }
                      className="collapse-icon"
                    >
                      {openDiv?.openArriveFilter == true ? (
                        <ArrowDropUp />
                      ) : (
                        <ArrowDropDown />
                      )}
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Collapse in={openDiv?.openArriveFilter} timeout={0}>
                <>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={1}
                    className="tripmode-wrapper"
                  >
                    {(props.tripMode == "ROUND" ||
                      props.tripMode == "ROUND-SP") && (
                        <Grid item className="tripmode-header">
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item className="tripmode-title">
                              <span>Onward</span>
                            </Grid>
                            <Grid item>
                              <IconButton
                                aria-label="clear arrival time"
                                size="small"
                                onClick={() => {
                                  setArrivaleTime({
                                    dawn: false,
                                    morning: false,
                                    afternoon: false,
                                    evening: false,
                                    night: false,
                                  });
                                  setOnwardFilter({
                                    ...onwardFilter,
                                    arrivalTime: false,
                                  });
                                  // filterByArrivalTime(null, null, "ONE");
                                }}
                                className="reset-filter"
                              >
                                Clear
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    <Grid item>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <FormGroup>
                          {props.filters?.onwardTimeFilter.map((v, i) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="secondary"
                                  size="small"
                                  checked={arrivalTime[v.name]}
                                  onChange={handleArrivalTimeChange}
                                  name={v.name}
                                />
                              }
                              label={
                                <>
                                  <span>
                                    <img
                                      src={getTimeSpanIcon(v.icon)}
                                      height={16}
                                      width={16}
                                      style={{ marginRight: "4px" }}
                                    />
                                  </span>
                                  <span>{v.label}</span>
                                </>
                              }
                              key={i}
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {(props.tripMode == "ROUND" ||
                    props.tripMode == "ROUND-SP") && (
                      <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={1}
                        className="tripmode-wrapper"
                      >
                        <Grid item className="tripmode-header">
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item className="tripmode-title">
                              <span>Return</span>
                            </Grid>
                            <Grid item>
                              <IconButton
                                aria-label="clear arrival time"
                                size="small"
                                onClick={() => {
                                  setArrivaleTimeRt({
                                    dawn: false,
                                    morning: false,
                                    afternoon: false,
                                    evening: false,
                                    night: false,
                                  });
                                  setReturnFilter({
                                    ...returnFilter,
                                    arrivalTime: false,
                                  });
                                  // filterByArrivalTime(null, null, "ROUND");
                                }}
                                className="reset-filter"
                              >
                                Clear
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <FormControl
                            component="fieldset"
                            className={classes.formControl}
                          >
                            <FormGroup>
                              {props.filters?.returnTimeFilter.map((v, i) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="secondary"
                                      size="small"
                                      checked={arrivalTimeRt[v.name]}
                                      onChange={handleArrivalTimeChangeRt}
                                      name={v.name}
                                    />
                                  }
                                  label={
                                    <>
                                      <span>
                                        <img
                                          src={getTimeSpanIcon(v.icon)}
                                          height={16}
                                          width={16}
                                          style={{ marginRight: "4px" }}
                                        />
                                      </span>
                                      <span>{v.label}</span>
                                    </>
                                  }
                                  key={i}
                                />
                              ))}
                            </FormGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}
                </>
              </Collapse>
            </Grid>
          </Grid>
          {/* arrival time filter end */}

          {/* airlines filter start */}
          <Grid item className="filter-element-container">
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item className="filter-element-title-main">
                    <span>Airlines</span>
                  </Grid>
                  <Grid item>
                    <IconButton
                      aria-label="clear price"
                      size="small"
                      onClick={() =>
                        setOpenDiv({
                          ...openDiv,
                          openAirlinesFilter: !openDiv?.openAirlinesFilter,
                        })
                      }
                      className="collapse-icon"
                    >
                      {openDiv?.openAirlinesFilter == true ? (
                        <ArrowDropUp />
                      ) : (
                        <ArrowDropDown />
                      )}
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Collapse in={openDiv?.openAirlinesFilter} timeout={0}>
                <>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={1}
                    className="tripmode-wrapper"
                  >
                    {(props.tripMode == "ROUND" ||
                      props.tripMode == "ROUND-SP") && (
                        <Grid item className="tripmode-header">
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item className="tripmode-title">
                              <span>Onward</span>
                            </Grid>
                            <Grid item>
                              <IconButton
                                aria-label="clear airline"
                                size="small"
                                onClick={() => {
                                  setAirlinesList(resetOnwardAirlinesList);
                                  setOnwardFilter({
                                    ...onwardFilter,
                                    airlines: false,
                                  });
                                  // filterByArrivalTime(null, null, "ONE");
                                }}
                                className="reset-filter"
                              >
                                Clear
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    <Grid item>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <FormGroup>
                          {airlinesList &&
                            airlinesList.map((v, i) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="secondary"
                                    size="small"
                                    checked={v.selected}
                                    onChange={handleAirlinesListChange}
                                    name={v.value}
                                  />
                                }
                                label={
                                  <span className="airline-filter-label">
                                    <img
                                      src={
                                        v.value !== "gds_only" &&
                                          v.value !== "lcc_only" &&
                                          v.value !== "all"
                                          ? image_base +
                                          "?fileName=" +
                                          v.value +
                                          ".png&type=AIRLINE_IMAGES"
                                          : GEN
                                      }
                                    />
                                    <span>{v.label}</span>
                                  </span>
                                }
                                key={i}
                              />
                            ))}
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {(props.tripMode == "ROUND" ||
                    props.tripMode == "ROUND-SP") && (
                      <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={1}
                        className="tripmode-wrapper"
                      >
                        <Grid item className="tripmode-header">
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item className="tripmode-title">
                              <span>Return</span>
                            </Grid>
                            <Grid item>
                              <IconButton
                                aria-label="clear airline"
                                size="small"
                                onClick={() => {
                                  setAirlinesListRt(resetreturnAirlinesList);
                                  setReturnFilter({
                                    ...returnFilter,
                                    airlines: false,
                                  });
                                  // filterByArrivalTime(null, null, "ROUND");
                                }}
                                className="reset-filter"
                              >
                                Clear
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <FormControl
                            component="fieldset"
                            className={classes.formControl}
                          >
                            <FormGroup>
                              {airlinesListRt &&
                                airlinesListRt.map((v, i) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color="secondary"
                                        size="small"
                                        checked={v.selected}
                                        onChange={handleAirlinesListChangeRt}
                                        name={v.value}
                                      />
                                    }
                                    label={
                                      <span className="airline-filter-label">
                                        <img
                                          src={
                                            v.value !== "gds_only" &&
                                              v.value !== "lcc_only" &&
                                              v.value !== "all"
                                              ? image_base +
                                              "?fileName=" +
                                              v.value +
                                              ".png&type=AIRLINE_IMAGES"
                                              : GEN
                                          }
                                        />
                                        <span>{v.label}</span>
                                      </span>
                                    }
                                    key={i}
                                  />
                                ))}
                            </FormGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}
                </>
              </Collapse>
            </Grid>
          </Grid>
          {/* airlines filter end */}

          {/* airlines sort start */}
          {/*<Grid item className="filter-element-container">
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item className="filter-element-title-main">
                    <span>Sort</span>
                  </Grid>
                  <Grid item>
                    <IconButton
                      aria-label="clear price"
                      size="small"
                      onClick={() =>
                        setOpenDiv({ ...openDiv, openSort: !openDiv?.openSort })
                      }
                      className="collapse-icon"
                    >
                      {openDiv?.openSort == true ? (
                        <ArrowDropUp />
                      ) : (
                        <ArrowDropDown />
                      )}
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Collapse in={openDiv?.openSort} timeout={0}>
                <>
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={1}
                    className="tripmode-wrapper"
                  >
                    {(props.tripMode == "ROUND" ||
                      props.tripMode == "ROUND-SP") && (
                      <Grid item className="tripmode-header">
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item className="tripmode-title">
                            <span>Onward</span>
                          </Grid>
                          <Grid item>
                            <IconButton
                              aria-label="clear airline"
                              size="small"
                              onClick={() => clearSort("all", "ONE")}
                              className="reset-filter"
                            >
                              Clear
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {/* SORT RADIOS }
                    <Grid item>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <RadioGroup value={value} onChange={handleChange}>
                          <FormControlLabel
                            value="price"
                            control={
                              <Radio
                                color="secondary"
                                size="small"
                                // checked={sorted.price == true}
                                onChange={(event) => {
                                  event.target.checked
                                    ? sortData("price", props?.onwdata, "ONE")
                                    : clearSort("price", "ONE");
                                }}
                              />
                            }
                            label="Price"
                          />

                          <FormControlLabel
                            value="stops"
                            control={
                              <Radio
                                color="secondary"
                                size="small"
                                // checked={sorted.stops == true}
                                onChange={(event) => {
                                  event.target.checked
                                    ? sortData("stops", props?.onwdata, "ONE")
                                    : clearSort("stops", "ONE");
                                }}
                              />
                            }
                            label="Stops"
                          />

                          <FormControlLabel
                            value="dep-time"
                            control={
                              <Radio
                                color="secondary"
                                size="small"
                                // checked={sorted.departureTime == true}
                                onChange={(event) => {
                                  event.target.checked
                                    ? sortData(
                                        "dep-time",
                                        props?.onwdata,
                                        "ONE"
                                      )
                                    : clearSort("dep-time", "ONE");
                                }}
                              />
                            }
                            label="Departure Time"
                          />

                          <FormControlLabel
                            value="arr-time"
                            control={
                              <Radio
                                color="secondary"
                                size="small"
                                // checked={sorted.arrivalTime == true}
                                onChange={(event) => {
                                  event.target.checked
                                    ? sortData(
                                        "arr-time",
                                        props?.onwdata,
                                        "ONE"
                                      )
                                    : clearSort("arr-time", "ONE");
                                }}
                              />
                            }
                            label="Arrival Time"
                          />

                          <FormControlLabel
                            value="airlines"
                            control={
                              <Radio
                                color="secondary"
                                size="small"
                                // checked={sorted.airlines == true}
                                onChange={(event) => {
                                  event.target.checked
                                    ? sortData(
                                        "airlines",
                                        props?.onwdata,
                                        "ONE"
                                      )
                                    : clearSort("airlines", "ONE");
                                }}
                              />
                            }
                            label="Airlines"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {(props.tripMode == "ROUND" ||
                    props.tripMode == "ROUND-SP") && (
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      spacing={1}
                      className="tripmode-wrapper"
                    >
                      <Grid item className="tripmode-header">
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item className="tripmode-title">
                            <span>Return</span>
                          </Grid>
                          <Grid item>
                            <IconButton
                              aria-label="clear airline"
                              size="small"
                              onClick={() => clearSort("all", "ROUND")}
                              className="reset-filter"
                            >
                              Clear
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                        >
                          <RadioGroup
                            value={retvalue}
                            onChange={handleChangeRet}
                          >
                            <FormControlLabel
                              value="price"
                              control={
                                <Radio
                                  color="secondary"
                                  size="small"
                                  // checked={sorted.priceRt == true}
                                  onChange={(event) => {
                                    event.target.checked
                                      ? sortData(
                                          "price",
                                          props?.retdata,
                                          "ROUND"
                                        )
                                      : clearSort("price", "ROUND");
                                  }}
                                />
                              }
                              label="Price"
                            />

                            <FormControlLabel
                              value="stops"
                              control={
                                <Radio
                                  color="secondary"
                                  size="small"
                                  // checked={sorted.stopsRt == true}
                                  onChange={(event) => {
                                    event.target.checked
                                      ? sortData(
                                          "stops",
                                          props?.retdata,
                                          "ROUND"
                                        )
                                      : clearSort("stops", "ROUND");
                                  }}
                                />
                              }
                              label="Stops"
                            />

                            <FormControlLabel
                              value="dep-time"
                              control={
                                <Radio
                                  color="secondary"
                                  size="small"
                                  // checked={sorted.departureTimeRt == true}
                                  onChange={(event) => {
                                    event.target.checked
                                      ? sortData(
                                          "dep-time",
                                          props?.retdata,
                                          "ROUND"
                                        )
                                      : clearSort("dep-time", "ROUND");
                                  }}
                                />
                              }
                              label="Departure Time"
                            />

                            <FormControlLabel
                              value="arr-time"
                              control={
                                <Radio
                                  color="secondary"
                                  size="small"
                                  // checked={sorted.arrivalTimeRt == true}
                                  onChange={(event) => {
                                    event.target.checked
                                      ? sortData(
                                          "arr-time",
                                          props?.retdata,
                                          "ROUND"
                                        )
                                      : clearSort("arr-time", "ROUND");
                                  }}
                                />
                              }
                              label="Arrival Time"
                            />

                            <FormControlLabel
                              value="airlines"
                              control={
                                <Radio
                                  color="secondary"
                                  size="small"
                                  // checked={sorted.airlinesRt == true}
                                  onChange={(event) => {
                                    event.target.checked
                                      ? sortData(
                                          "airlines",
                                          props?.retdata,
                                          "ROUND"
                                        )
                                      : clearSort("airlines", "ROUND");
                                  }}
                                />
                              }
                              label="Airlines"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                </>
              </Collapse>
            </Grid>
          </Grid>*/}
          {/* airlines sort end */}
        </Grid>
      </div>
    )
  );
};

export default FlightSearchFilter;

const STEP = 100;

// const STOPS = [
//   { value: 0, label: "Non-stop only" },
//   { value: 1, label: "One stop or fewer" },
//   { value: 2, label: "Two stops or fewer" },
//   { value: 3, label: "Three stops or more" },
// ];

// const TIME_OF_DAY = [
//   { value: "dawn", label: "Dawn (3AM - 7AM)", icon: dawn },
//   { value: "morning", label: "Morning (7AM - 12PM)", icon: morning },
//   { value: "afternoon", label: "Afternoon (12PM - 4PM)", icon: afternoon },
//   { value: "evening", label: "Evening (4PM - 9PM)", icon: evening },
//   { value: "night", label: "Night (9AM - 3AM)", icon: night },
// ];

// const AIRLINES_LIST1 = [
//   { value: "all", label: "All Airlines", carriers: null, isSelected: true },
//   {
//     value: "lcc_only",
//     label: "LCC Only",
//     carriers: ["I5", "IX", "OP", "GOW", "G8", "6E", "S2", "IT", "SG", "2T"],
//     isSelected: false,
//   },
//   { value: "gds_only", label: "GDS Only", carriers: ["AI"], isSelected: false },
//   { value: "AI", label: "Air India", carriers: ["AI"], isSelected: false },
//   { value: "G8", label: "Go Air", carriers: ["G8"], isSelected: false },
//   { value: "SG", label: "Spicejet", carriers: ["SG"], isSelected: false },
//   { value: "6E", label: "Indigo", carriers: ["6E"], isSelected: false },
//   { value: "UK", label: "Vistara", carriers: ["UK"], isSelected: false },
// ];

const getTimeSpanIcon = (timeSpan) => {
  switch (timeSpan) {
    case "dawn": {
      return dawn;
    }

    case "morning": {
      return morning;
    }

    case "afternoon": {
      return afternoon;
    }

    case "evening": {
      return evening;
    }

    case "night": {
      return night;
    }
  }
};

function FilterRadio(props) {
  const radioStyle = radioStyles();

  return (
    <Radio
      className={radioStyle.root}
      disableRipple
      color="default"
      checkedIcon={
        <span className={clsx(radioStyle.icon, radioStyle.checkedIcon)} />
      }
      icon={<span className={radioStyle.icon} />}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => {
  // console.log(theme);
  return {
    root: {
      // width: "300px",
      width: "100%",
      // marginTop: 20,
      backgroundColor: theme.palette.background.light,
      position: "sticky",
      top: 75,
      // top: 135,
      boxShadow: `0px 0px 5px 0px rgba(145,143,145,0.6)`,
      // maxHeight: "88vh",
      borderRadius: "5px",
      scrollbarWidth: "thin",
      overflowY: "auto",
      paddingBottom: 600,
      [theme.breakpoints.down(600)]: {
        paddingBottom: 60,
      },
      "& .loader-view": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        flexDirection: "column",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 103,
        background: "rgba(0,0,0,0.5)",
        "& img": {
          height: "7rem",
        },
        "& .loader-text": {
          //  color: theme.palette.primary.main,
          color: theme.palette.buttons.default,
          fontWeight: 600,
          textAlign: "center",
          marginLeft: 10,
        },
      },
      "& .PrivateSwitchBase-root-66": {
        padding: "6px",
      },
      "& .MuiCheckbox-colorSecondary.Mui-checked": {
        color: theme.palette.primary.main,
      },
      "& .reset-filter": {
        fontSize: "0.7rem",
      },
      "& .MuiRadio-colorSecondary.Mui-checked": {
        color: theme.palette.primary.main,
      },
      "& .filter-container": {
        // flexWrap: "nowrap",
        paddingBottom: 20,
        [theme.breakpoints.down(1280)]: {
          flexWrap: "wrap",
        },
      },
      "& .filter-element-title-main": {
        fontSize: "14px",
        fontWeight: "600",
        textAlign: "center",
        margin: "0",
        "& span": {
          color: theme.palette.primary.darkText,
        },
      },
      "& .applyBtn": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.default,
        fontWeight: 700,
        height: 50,
        alignItems: "center",
      },
      "& .filter-element-container": {
        width: "100%",
        padding: "12px",
        backgroundColor: theme.palette.background.light,
        //"&(:last-child)": {
        //borderBottom: `solid 0.5px ${theme.palette.primary.disabled}`,
        border: `solid 0.5px ${theme.palette.primary.disabled}`,
        borderRadius: "5px",
        margin: "3px",
        //},
        "& .MuiFormControlLabel-root": {
          color: theme.palette.primary.darkText,
        },
        "& .MuiFormControlLabel-label": {
          fontSize: "12px",
        },
        "& .MuiFormControl-root": {
          marginTop: 0,
          display: "flex",
        },
        "& *": {
          transition: "0.2s ease all",
        },
        "& .MuiCheckbox-root": {
          padding: "4px",
        },
        "& .collapse-icon": {
          marginTop: -5,
        },
      },
      "& .tripmode-header": {
        marginTop: 10,
        border: `solid 0.5px ${theme.palette.primary.main}`,
        "& span": {
          color: theme.palette.primary.darkText,
        },
      },
      "& .tripmode-title": {
        height: "20px",
        maxWidth: "200px",
        "& span": {
          fontSize: "14px",
          fontWeight: "700",
          color: theme.palette.primary.darkText,
        },
      },
      "& .tripmode-wrapper": {
        padding: "0 20px 10px 10px",
      },
      "& .price-slider-value-input": {
        "& input, & input:active, & input:focus": {
          outline: "3px solid black",
          borderRadius: 3,
          padding: "6px",
          fontSize: 12,
          width: 70,
        },
      },
      "& .airline-filter-label": {
        display: "flex",
        // alignItems: "center",
        "& img": {
          height: 20,
          width: 20,
          marginRight: 5,
        },
      },
    },
    filter_title_root: {
      fontSize: "20px",
      fontWeight: "600",
      textAlign: "center",
      margin: "12px 0",
      "& span": {
        color: theme.palette.primary.darkText,
      },
    },
  };
});

const PriceSlider = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    height: 6,
  },
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: theme.palette.background.light,
    border: `4px solid ${theme.palette.primary.main}`,
    color: theme.palette.secondary.contrastText,
    marginTop: -6,
    marginLeft: -6,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  // valueLabel: {
  //   left: "calc(-50% + 4px)",
  // },
  track: {
    height: 6,
    borderRadius: 3,
  },
  rail: {
    height: 6,
    borderRadius: 3,
  },
}))(Slider);

const radioStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: `${theme.palette.primary.main}40`,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: `2px auto ${theme.palette.primary.main}`,
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: `${theme.palette.primary.main}90`,
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: `${theme.palette.primary.main}10`,
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: `${theme.palette.primary.main}90`,
    },
  },
  applyBtn: {
    position: "absolute",
    bottom: 0,
  },
}));
