import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  getQueryVar,
  getCurrentTab,
  getAgencyId,
} from "oautils/oaCommonUtils.js";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PostLoginNavBar from "components/Navbars/PostLoginNavBar.BuzzHolidays";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
// import { FooterSection } from "components/LandingPage/buzzHolidays/FooterSection";
import Container from "@material-ui/core/Container";
import Voucher from "pages/voucher/Voucher";
import HotelVoucher from "pages/module/hotel/HotelVoucher";
import companylogo from "assets/BuzzHolidays/images/logo/logo.png";
import BusTicket from "pages/module/bus/BusTicket";

import FlightTicket from "pages/report/flight/FlightTicketNoHeader";
import InsuranceTicket from "pages/report/insurance/InsuranceTicket";
import MyBookingsFlight from "pages/b2c/MyBookingsFlight";
import { localforageGetItem } from "oautils/oaForageUtils";
import MyBookingsHotel from "pages/b2c/MyBookingsHotel";
import MyBookingsBus from "pages/b2c/MyBookingsBus";
import FooterSection from "components/LandingPage/BuzzHolidays/FooterSection";

const useStyles = makeStyles(styles);

export default function PrintLayout(props) {
  const { ...rest } = props;
  const classes = useStyles();

  const [agencyId, setAgencyId] = useState(undefined);

  const theme = useTheme();
  const [userType, setUserType] = React.useState(null);
  const [userTypeAbv, setUserTypeAbv] = React.useState(null);

  React.useEffect(() => {
    document.body.style.overflow = "unset";
    let qAgencyId = getAgencyId(props);
    setAgencyId(qAgencyId);
    return function cleanup() { };
  }, [agencyId, props]);

  const getValuesFromLocalStorage = () => {
    localforageGetItem("user-details", function (err, value) {
      if (value) {
        // setUserType(value.userType);
        // setUserTypeb2c(value.userType);
        setUserTypeAbv(value.userTypeAbv);
      } else {
        console.log("err", err);
      }
    });
  };

  useEffect(() => {
    getValuesFromLocalStorage();
    console.log("companylogo", companylogo)
  }, []);

  return (
    <div
      className={classes.wrapper}
      style={{
        backgroundColor: theme.palette.background.default,
        width: `100%`,
        height: `100%`,
      }}
    >
      <PostLoginNavBar {...rest} isSearchPage={true} showServices={true} />
      <Container fixed style={{ padding: 0 }}>
        <div
          className={classes.container}
          style={{ margin: "30px 0", minHeight: "52vh" }}
        >
          <Switch>
            <Route
              path="/print/flightticket/:ticketId"
              render={(prop) => (
                <FlightTicket companylogo={companylogo} {...prop} />
              )}
            />
            <Route
              path="/print/flight-details/"
              render={(prop) => (
                <MyBookingsFlight {...prop} usertype={userTypeAbv} />
              )}
            />
            <Route
              path="/print/hotel-details"
              render={(prop) => (
                <MyBookingsHotel {...prop} usertype={userTypeAbv} />
              )}
            />
            <Route
              path="/print/bus-details"
              render={(prop) => (
                <MyBookingsBus {...prop} usertype={userTypeAbv} />
              )}
            />
            <Route
              path="/print/busticket/:ticketId"
              render={(prop) => (
                <BusTicket companylogo={companylogo} {...prop} />
              )}
            />
            <Route
              path="/print/hotelticket/:ticketId"
              render={(prop) => (
                <HotelVoucher companylogo={companylogo} {...prop} />
              )}
            />
            <Route
              path="/print/insuranceticket/:ticketId"
              render={(prop) => (
                <InsuranceTicket companylogo={companylogo} {...prop} />
              )}
            />
            <Route
              path="/print/voucher/:type/:ref"
              companylogo={companylogo}
              render={(prop) => <Voucher companylogo={companylogo} {...prop} />}
            />
          </Switch>
        </div>
      </Container>
      {/* <PageFooter white {...props} /> */}
      <FooterSection />
    </div>
  );
}
