import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import {
  BrowserRouter,
  Switch,
  Redirect,
  MemoryRouter,
  Route,
  Router,
} from "react-router-dom";
import "index.css";
import AuthLayout from "layouts/BuzzHolidays/Auth";
import AdminLayout from "layouts/BuzzHolidays/Admin.js";
import BusLayout from "layouts/BuzzHolidays/BusLayout.js";
import HotelLayout from "layouts/BuzzHolidays/HotelLayout.js";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import LandingPageLayout from "layouts/BuzzHolidays/LandingPageLayout.js";

import AgentRegistration from "pages/agent/AgentRegistration";
import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import "assets/css/custom-table.css";
import FlightTicket from "pages/report/flight/FlightTicketNoHeader";
import FlightTicketPrint from "pages/report/flight/print/FlightTicketPrint";
import GroupBooking from "pages/groupBooking/GroupBooking";
import "assets/css/css-variable.css";
import AppStateProvider from "layouts/AppStateProvider";
import OaScrollToTop from "components/OaScrollToTop";
import PackagesLayout from "layouts/BuzzHolidays/PackagesLayout";

import { Provider } from "react-redux";
import { store, persistor } from "redux/store";
import { PersistGate } from "redux-persist/integration/react";
import LinearProgress from "@material-ui/core/LinearProgress";
import FlightLayout from "layouts/BuzzHolidays/FlightLayout";
import PrintLayout from "layouts/BuzzHolidays/PrintLayout.js";
import PreLoginLayout from "layouts/BuzzHolidays/PreLoginLayout.js";
import B2clayout from "layouts/BuzzHolidays/B2Clayout";
import CorporatePages from "layouts/BuzzHolidays/CorporateLayout";
import LoginPage from "pages/corporate/LoginPage";
import { colors } from "assets/BuzzHolidays/js/ColorPalette";
import SpecialOffersLayout from "layouts/BuzzHolidays/SpecialOffersLayout";
import { Helmet } from "react-helmet";
import { AppConstant } from "appConstant";
import OaLogout from "pages/components/OaLogout";
import ExclusiveOffersLayout from "layouts/BuzzHolidays/ExclusiveOffersLayout";

const hist = createBrowserHistory();
window.appNameContext = "psa";

const theme = createTheme(colors);

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Helmet>
      <title>{AppConstant.title}</title>
    </Helmet>
    <AppStateProvider>
      <Provider store={store}>
        <PersistGate loading={<LinearProgress />} persistor={persistor}>
          <BrowserRouter history={hist} basename="/">
            <OaScrollToTop>
              <Switch>
                <Route path="/prelogin" component={PreLoginLayout} />
                <Route path="/print" component={PrintLayout} />
                <Route path="/auth" component={AuthLayout} />
                <Route path="/admin" component={AdminLayout} />
                <Route path="/hotel" component={HotelLayout} />
                <Route path="/bus" component={BusLayout} />
                <Route path="/flight" component={FlightLayout} />
                <Route path="/registration" component={AgentRegistration} />
                <Route path="/packages" component={PackagesLayout} />
                <Route
                  path="/flightticket/:ticketId/:isAddress/:isFare/:isPerPassenger/:isGST"
                  component={FlightTicketPrint}
                />
                <Route
                  path="/flightticket/:ticketId"
                  component={FlightTicket}
                />
                <Route
                  path="/exclusive-offers"
                  component={ExclusiveOffersLayout}
                />
                <Route path="/groupbooking" component={GroupBooking} />
                <Route path="/b2c" component={B2clayout} />
                <Route path="/corporate" component={CorporatePages} />
                <Route path="/corporate_login" component={LoginPage} />
                <Route path="/fd" component={SpecialOffersLayout} />
                <Route path="/logout" component={OaLogout} />
                <Route path="/" component={LandingPageLayout} />

                <Redirect from="/" to="/auth/loading-page" />
              </Switch>
            </OaScrollToTop>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </AppStateProvider>
  </MuiThemeProvider>,
  document.getElementById("root")
);
