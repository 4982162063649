import React, { useState, useEffect, useCallback } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import dateFnsFormat from "date-fns/format";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, useMediaQuery } from "@material-ui/core";
// import OaTypeahead from "oahoc/OaTypeahead";
// import OaFormDateField from "oahoc/OaFormDateFieldNew";
// import "react-bootstrap-typeahead/css/Typeahead.css";
import "assets/css/bus-main.css";
//import Avatar from "@material-ui/coCommissionAndChargere/Avatar";
//import exchange from "assets/img/exchange.png";
import DateFnsUtils from "@date-io/date-fns";
import { AppStateContext } from "layouts/AppStateProvider";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import { useHistory, withRouter } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
//import OaFormDateField from "oahoc/OaFormDateFieldNew";
import { connect } from "react-redux";
import { setBusCities } from "redux/action";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Button from "components/CustomButtons/Button";
import { Hidden, Typography } from "@material-ui/core";

//import { InputAdornment, FormControl } from "@material-ui/core";
//import Icon from "@material-ui/core/Icon";
//import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import Drawer from "@material-ui/core/Drawer";
import BusLocationInput from "components/CommonSearchComponents/Bus/BusLocationInput"
import BusDateInput from "../../../components/CommonSearchComponents/Bus/BusDateInput"

//import { de } from "date-fns/locale";

const BusSearch = (props) => {
  // console.log(new Date(props.location.state.selectedDate));
  const classes = useStyles();
  const history = useHistory();

  //const [isSaving, setIsSaving] = useState(false);
  //const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [isRefresh, setIsRefresh] = useState(false);

  const [departure, setDeparture] = useState({ CityId: props.location.state.searchParams.departureCityId, CityName: props.location.state.searchParams.departureCityName });
  const [destination, setDestination] = useState({ CityId: props.location.state.searchParams.destinationCityId, CityName: props.location.state.searchParams.destinationCityName });

  const [busCity, setBusCity] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    new Date(props.location.state.selectedDate)
  );
  const { SetMessage } = React.useContext(AppStateContext);
  const [open, setOpen] = React.useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // const loading = open && busCity.length === 0;
  console.log(props.location.state.searchParams.departureCityId)

  const handleDateChange = (date, value) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    if (isLoading) {
      const length = props.busCities ? Object.keys(props.busCities).length : 0;
      if (length > 0) {
        setIsLoading(false);
      } else {
        WebApi.getBusCity(
          {},
          (response) => {
            if (response.success === true) {
              props.setBusCities(response.data.BusCities);
              setIsLoading(false);
            } else {
              setIsError(response.success);
              setInfoText(response.message);
            }
          },
          (error) => console.log("error geting bus city", error)
        );
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (isLoading) {
      WebApi.getBusCity(
        {},
        (response) => {
          if (response.success === true) {
            let initdeparture = response.data.BusCities.filter(
              (busCity) =>
                busCity.CityName === props.location.state.BusSearchResult.Origin
            );
            console.log(initdeparture[0]);
            if (initdeparture[0]) {
              setDeparture(initdeparture[0]);
            }

            let initDestinataion = response.data.BusCities.filter(
              (busCity) =>
                busCity.CityName ===
                props.location.state.BusSearchResult.Destination
            );
            if (initDestinataion[0]) {
              setDestination(initDestinataion[0]);
            }

            console.log(initDestinataion[0]);

            setIsLoading(false);
          } else {
            setIsError(response.success);
            setInfoText(response.message);
          }
        },
        (error) => {
          console.log("error getBusCity", error);
        }
      );
    }
  }, [isLoading]);

  const onClick = () => {
    if (departure.CityId > 0 && destination.CityId > 0) {
      var params = {
        departureCityId: departure.CityId,
        departureCityName: departure.CityName,
        destinationCityId: destination.CityId,
        destinationCityName: destination.CityName,
        selectedDate: dateFnsFormat(selectedDate, "yyyy-MM-dd"),
      };

      history.push({
        pathname: "/bus/search-progress",
        state: params,
      });
    } else {
      // alert('Please Add Departure/Destination')
      SetMessage("Please Add Departure/Destination");
    }
  };

  const exchangeOnClick = () => {
    const city = departure;
    setDeparture(destination);
    setDestination(city);
    setIsRefresh(true);

    setTimeout(() => {
      setIsRefresh(false);
    }, 1000);
  };

  const fetchMatchingCities = (searchParams) => {
    if (searchParams.length >= 3) {
      apiCall(
        WebApi.getMatchingBusCity,
        {
          cityId: "0",
          cityName: searchParams,
        },
        (response) => {
          if (response.success === true) {
            props.setBusCities(response.data.BusCities);
            // console.log("props", props);
            // console.log(response.data.BusCities);
            // setIsLoading(false);
          } else {
            setIsError(response.success);
            setInfoText(response.message);
          }
        }
      );
    }
  };
  // console.log("destinat", destination);
  // console.log("departure", departure);

  return (
    <div
      {...props}
      style={{
        paddingLeft: "20px",
        paddingRight: isMobile && "20px",
        display: "flex",
        alignItems: "center",
        paddingTop: 10,
        border: "1px solid #b1b1b1",
        borderRadius: "10px",
      }}
      className={classes.root}
    >
      <GridContainer
        style={{
          justifyContent: "space-around",
        }}
      >
        <GridItem md={3} xs={12} sm={12}>
          {!isLoading && !isRefresh ? (
            <>
              <Grid style={{width: "100%"}}>
                <Typography className={classes.Typography}>
                  DEPARTURE
                </Typography>
                <Grid style={{width: "100%"}}>
                  {/* <Autocomplete
                    style={{ width: "100%", paddingRight: 0 }}
                    // style={{ width: 300 }}
                    id="departure"
                    disableClearable
                    options={props.busCities}
                    getOptionLabel={(option) => option.CityName}
                    onKeyUp={(event) => fetchMatchingCities(event.target.value)}
                    value={departure}
                    defaultValue={departure}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        // placeholder={
                        //   props.location.state.BusSearchResult.Origin
                        // }

                        placeholder={departure.CityName}
                        margin="normal"
                        // variant="outlined"
                        classes={{ notchedOutline: classes.input }}
                        InputProps={{
                          disableUnderline: true,
                          ...params.InputProps,
                          name: "departure",
                          // startAdornment: (
                          //   <InputAdornment position={props.iconPos || "start"}>
                          //     <LocationOnOutlinedIcon
                          //       style={{ color: "red" }}
                          //     />
                          //   </InputAdornment>
                          // ),
                          endAdornment: (
                            <LocationOnOutlinedIcon
                              style={{
                                color: theme.palette.primary.defaultText,
                              }}
                            />
                          ),
                          // classes: { notchedOutline: classes.noBorder },
                          style: {
                            color: theme.palette.primary.defaultText,
                            // backgroundColor: "white",
                            // boxShadow: "rgb(0 0 0 / 7%) 0px 2px 13px 0px",
                            borderRadius: 0,
                            // paddingRight: 5,
                            border: `1px solid ${theme.palette.primary.contrastText}`,
                            outline: 0,
                          },
                        }}
                        // style={{

                        //   backgroundColor: "white",
                        //   boxShadow:"rgb(0 0 0 / 7%) 0px 2px 13px 0px",
                        //   borderRadius: "10px",
                        //   borderBottom: "none",
                        //   border: "none",
                        // }}
                      />
                    )}
                    onChange={(e, value) => {
                      setDeparture(value);
                    }}
                  /> */}
                  <BusLocationInput props={{props, value:departure, setField: setDeparture}} type={"Origin"} />
                  
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <span className="search-div-span">DEPARTURE loading...</span>
              {/* <LocationOnOutlinedIcon style={{color:'#d52731',marginRight:100}}/>*/}
            </>
          )}
        </GridItem>

        {!isMobile && (
          <GridItem md={1} xs={12} sm={12}>
            <GridContainer
              justifyContent="center"
              style={{ paddingTop: isMobile ? 0 : "30px" }}
            >
              <SwapHorizIcon
                onClick={exchangeOnClick}
                style={{
                  // color: theme.palette.secondary.darkBlue,
                  fontSize: 30,
                  cursor: "pointer",
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.lightText,
                  // background:theme.palette.quarternary.main,
                  // textTransform: "uppercase",
                  // paddingRight: 50,
                  borderRadius: 50,
                }}
              ></SwapHorizIcon>
            </GridContainer>
          </GridItem>
        )}

        <GridItem md={3} xs={12} sm={12}>
          {!isLoading && !isRefresh ? (
            <>
              <Grid style={{paddingTop: 10}}>
                <Typography className={classes.Typography}>
                  DESTINATION
                </Typography>
                {/* <Autocomplete
                  style={{ width: "100%", paddingRight: 0 }}
                  id="destination"
                  disableClearable
                  options={props.busCities}
                  value={destination}
                  defaultValue={destination}
                  getOptionLabel={(option) => option.CityName}
                  onKeyUp={(event) => fetchMatchingCities(event.target.value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={destination.CityName}
                      margin="normal"
                      InputProps={{
                        disableUnderline: true,
                        ...params.InputProps,
                        name: "destination",
                        endAdornment: (
                          <LocationOnOutlinedIcon
                            style={{ color: theme.palette.primary.defaultText }}
                          />
                        ),
                        // classes: { notchedOutline: classes.noBorder },
                        style: {
                          color: theme.palette.primary.defaultText,
                          // backgroundColor: "white",
                          // boxShadow: "rgb(0 0 0 / 7%) 0px 2px 13px 0px",
                          borderRadius: 0,
                          // paddingRight: 5,
                          border: `1px solid ${theme.palette.primary.contrastText}`,
                          outline: 0,
                        },
                      }}
                    />
                  )}
                  onChange={(e, value) => {
                    setDestination(value);
                  }}
                /> */}
                <BusLocationInput props={{props, value:destination, setField: setDestination}}  type={"Destination"} />
              </Grid>
            </>
          ) : (
            <>
              <span className="search-div-span">DESTINATION loading...</span>
              {/* <LocationOnOutlinedIcon
                style={{ color: "#007A92ee", paddingRight: "30px" }}
              /> */}
            </>
          )}
        </GridItem>

        <GridItem md={3} xs={12} className="date-picker-bus">
          {/* style={{ marginTop: isMobile ? 10 : 20 }} */}
          <Typography className={classes.Typography} style={{marginBottom: 5, margintop: 5}}>DATE</Typography>
          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              style={{ width: "100%", paddingRight: 0 }}
              onClick={() => setOpen(true)}
              onClose={() => setOpen(false)}
              open={open}
              fullWidth
              disableToolbar
              disablePast
              autoOk={true}
              inputVariant="outlined"
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="bus-date-picker-inline"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <LocationOnOutlinedIcon
                    style={{
                      color: theme.palette.primary.lightText,
                    }}
                  />
                ),
                //classes: { notchedOutline: classes.noBorder },
                style: {
                  color: theme.palette.primary.defaultText,
                  // backgroundColor: "white",
                  // boxShadow: "rgb(0 0 0 / 7%) 0px 2px 13px 0px",
                  borderRadius: 0,
                  // paddingRight: 5,
                  border: `1px solid ${theme.palette.primary.contrastText}`,
                  outline: 0,
                },
              }}
            />
          </MuiPickersUtilsProvider> */}
          <BusDateInput props={{props,  value:selectedDate, setField:  setSelectedDate}}  />
        </GridItem>

        <GridItem md={2} xs={12}>
          <GridContainer
            style={{
              paddingTop: "20px",
              paddingBottom: "20px",
              justifyContent: "space-between",
            }}
          >
            <GridItem>
              <Button
                variant="contained"
                style={{
                  // fontSize: 30,
                  fontWeight: 600,
                  cursor: "pointer",
                  color: theme.palette.buttons.tertiaryContrastText,
                  background: theme.palette.buttons.tertiary,
                  textTransform: "uppercase",
                }}
                onClick={onClick}
              >
                Search
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
};

const mapDispatchToProp = (dispatch) => {
  return {
    setBusCities: (busCities) => dispatch(setBusCities(busCities)),
  };
};

const mapStateToProp = (state, prop) => {
  return {
    busCities: state.bus.busCities,
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProp
)(BusSearch);

const useStyles = makeStyles((theme) => ({
  root: {

    "& .bus-location-input":{
      marginTop: 5,
      border: "1px solid #D8D8D8",
      borderRadius: 4,
      color: "#000",
      fontWeight: 400,
      fontFamily: theme.palette.font.secondary,
      
    },
    "& .date-picker-bus":{
      marginTop: 5,
      
    },
    "& .react-datepicker-wrapper":{
      "& .MuiInputBase-input":{

        fontSize: 19,
      }
    },
    // backgroundColor: theme.palette.primary.main,
    background: theme.palette.buttons.default,

    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.defaultText,
    },
    "& .search-div-span": {
      color: theme.palette.primary.defaultText,
    },
  },

  margin: {
    margin: theme.spacing(1),
  },
  input: {
    outline: 0,
    borderWidth: "0 0 2px",
    borderColor: "blue",
  },
  button: {
    "&:hover": {
      background: theme.palette.primary.peach,
    },
    background: theme.palette.primary.peach,
    borderRadius: 50,
    border: 0,
    color: "white",
    height: 40,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    height: "29px",
    width: "96px",

    // marginLeft:"26px"
  },
  Typography: {
    color: theme.palette.primary.defaultText,
    fontSize: "12px",
    fontWeight: "500",
    textAlign: "left",
    // marginLeft: "16px",
  },
  date: {
    border: `2px solid ${theme.palette.secondary.gray}`,
    outline: 0,
    width: "92%",
    [theme.breakpoints.down("sm")]: {
      width: "125%",
    },
  },
  noBorder: {
    border: "none",
  },
}));
