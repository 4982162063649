import React from "react";
import logo from "assets/BuzzHolidays/images/logo/logo.png";
import facebook from "assets/BuzzHolidays/images/icons/facebook.png";
import twitter from "assets/BuzzHolidays/images/icons/twitter.png";
import instagram from "assets/BuzzHolidays/images/icons/instagram.png";

// import TAAFI from "assets/BuzzHolidays/images/taafi_logo.png";

import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { AppConstant } from "appConstant";
import { useHistory } from "react-router-dom";

export const FooterSection = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down(760));

  return (
    <div className={classes.root}>
      <div className="footerContainer">
        <img src={logo} className="footerLogo" />

        <div className="footerLinksContainer">
          <div className="footerWrapper">
            <div className="footerLinkTitle">Products</div>

            <div
              onClick={() => history.push("/flights")}
              className="footerLinkItem"
            >
              Search Flights
            </div>
            <div
              onClick={() => history.push("/hotels")}
              className="footerLinkItem"
            >
              Search Hotels
            </div>
            <div
              onClick={() => history.push("/buses")}
              className="footerLinkItem"
            >
              Search Buses
            </div>
          </div>
          <div className="footerWrapper">
            <div className="footerLinkTitle">Info</div>
            <div
              className="footerLinkItem"
              onClick={() => history.push("/termsandconditions")}
            >
              Terms & Condition
            </div>
            <div
              className="footerLinkItem"
              onClick={() => history.push("/privacypolicy")}
            >
              Privacy Policy
            </div>
            <div
              className="footerLinkItem"
              onClick={() => history.push("/aboutus")}
            >
              About Us
            </div>
            <div
              className="footerLinkItem"
              onClick={() => history.push("/cancellationVisaPolicy")}
            >
              Cancellation & Visa Policy
            </div>
          </div>

          <div className="footerWrapper">
            <div className="footerLinkTitle">Contact Info</div>
            <div className="footerLinkItem">
              {" "}
              <a href={`mailto:${AppConstant.supportEmail}`}>
                {AppConstant.supportEmail}
              </a>
            </div>
            <div className="footerLinkItem">
              {" "}
              <a
                href={`tel:${AppConstant.countryDialCode}${AppConstant.supportContact}`}
              >
                +{AppConstant.countryDialCode} {AppConstant.supportContactView}
              </a>
            </div>
            <div className="footerLinkItem">
              {" "}
              <a
                href={`tel:${AppConstant.countryDialCode}${AppConstant.supportContact}`}
              >
                +{AppConstant.countryDialCode} {AppConstant.infoContactView}
              </a>
            </div>
          </div>

          <div className="footerWrapper">
            <div className="footerLinkTitle">Follow us</div>
            <div className="footerLinkSocialMedia">
              <img src={facebook} className="socialMediaIcon" />
              <img src={instagram} className="socialMediaIcon" />
              <img src={twitter} className="socialMediaIcon" />
            </div>
          </div>
        </div>
      </div>
      <div className="footerCopyRight">
        ©2023 by STK FOREX & LEISURE PRIVATE LIMITED - All Rights Reserved
      </div>
    </div>
  );
};

export default FooterSection;

const footerLinks = [
  {
    linkHeader: "OUR PRODUCTS",
    links: [
      {
        title: "Book Flight",
        url: "/flights",
      },
      // {
      //     title: "Book Flight",
      //     url: "/flights",
      // },
      {
        title: "Search Hotels",
        url: "/hotels",
      },
      {
        title: "Search Buses",
        url: "/buses",
      },
      // {
      //     title: "Hotel",
      //     url: "/flights",
      // },
      // {
      //     title: "Holiday Packages",
      //     url: "",
      // },
    ],
  },
  {
    linkHeader: "LMT INFO",
    links: [
      {
        title: "Terms & Conditions",
        url: "/termsandconditions",
      },
      {
        title: "Privacy Policy",
        url: "/privacypolicy",
      },
      {
        title: "About Us",
        url: "/aboutus",
      },
      {
        title: "Contact Us",
        url: "/contactus",
      },
    ],
  },
  // {
  //     linkHeader: "Contact Info",
  //     links: [
  //         {
  //             title: 'info@triumphhtravel.comy',
  //             url: "",
  //         },
  //         {
  //             title: '+91 1141 611 366',
  //             url: "",
  //         },
  //         {
  //             title: '+91 1141 611 366',
  //             url: "",
  //         },
  //         {
  //             title: '609, Vishwa Sadan Building, Janakpuri',
  //             url: "",
  //         },
  //         {
  //             title: 'District Centre, New Delhi - 110058',
  //             url: "",
  //         },
  //     ]
  // },
];

const useStyles = makeStyles((theme) => ({
  root: {
    // height: 250,
    [theme.breakpoints.down(500)]: {
      height: "100%",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0px 0px 0px 1px rgba(232,232,232,232)",
    "& .contactInfoColumn": {
      [theme.breakpoints.down(700)]: {
        display: "none",
      },
    },
    "& .contactInfoRow": {
      display: "none",
      [theme.breakpoints.down(700)]: {
        display: "flex",
        padding: "10px 0px",
      },
      "& .footerLinkItem": {
        [theme.breakpoints.down(700)]: {
          padding: "10px 5px",
        },
        "& a": {
          color: "#000",
        },
      },
    },
    "& .footerContainer": {
      width: "95%",
      display: "flex",
      marginTop: 40,
      justifyContent: "space-between",
      "& .footerLinkItem": {
        // [theme.breakpoints.down(700)]: {
        //   padding: "10px 5px",
        // },
        "& a": {
          color: "#000",
        },
      },
      [theme.breakpoints.down(950)]: {
        width: "100%",
        padding: "0px 20px",
      },
      "& .footerLinks": {
        width: "15%",
        [theme.breakpoints.down(950)]: {
          width: "15%",
        },
        [theme.breakpoints.down(700)]: {
          textAlign: "center",
        },
        [theme.breakpoints.down(500)]: {
          padding: "20px 0px",
          flexDirection: "column",
          width: "80%",
        },
      },
      [theme.breakpoints.down(700)]: {
        padding: 20,
        flexDirection: "column",
        marginTop: 10,
        alignItems: "center",
      },
      [theme.breakpoints.down(500)]: {
        padding: "20px 0px",
      },
    },
    "& .footerCopyRight": {
      // background: `linear-gradient(90.13deg, #9B1111 0.09%, #FF5959 99.86%)`,
      width: "100%",
      color: theme.palette.primary.defaultText,
      border: "2px solid #eee",
      fontSize: 14,
      fontWeight: 500,
      textAlign: "center",
      height: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .footerLogo": {
      //width: 400,
      height: 130,
      [theme.breakpoints.down(1300)]: {
        //width: 300,
        height: 70,
      },
      [theme.breakpoints.down(950)]: {
        //width: 250,
        height: 50,
      },
      [theme.breakpoints.down(500)]: {
        //width: 250,
        height: 50,
      },
    },
    "& .footerLinkTitle": {
      fontSize: 18,
      fontWeight: 700,
      marginBottom: 16,
    },
    "& .footerLinkItem": {
      fontSize: 14,
      fontWeight: 500,
      marginBottom: 10,
      textDecoration: "underline",
      cursor: "pointer",
      wordWrap: "break-word",
      [theme.breakpoints.down(500)]: {
        fontSize: 12,
      },
    },
    "& .footerLinkSocialMedia": {
      display: "flex",
      [theme.breakpoints.down(700)]: {
        justifyContent: "center",
      },
    },
    "& .socialMediaIcon": {
      height: 20,
      width: 20,
      marginRight: 20,
      [theme.breakpoints.down(700)]: {
        margin: "0px 10px",
      },
    },
    "& .footerLinksContainer": {
      display: "flex",
      justifyContent: "space-between",
      width: "60%",
      flexDirection: "row",

      [theme.breakpoints.down(960)]: {
        width: 300,
        paddingTop: "20",

        textAlign: "center",
        flexDirection: "column",
      },
    },
    "& .footerWrapper": {
      width: "25% !important",
      [theme.breakpoints.down(960)]: {
        paddingTop: "20",
        width: "100% !important",
      },
    },
  },
}));
