import React from "react";
import CustomDatePicker from "oahoc/CustomDatePicker";
import styled from "styled-components";
import { useTheme } from "@material-ui/core";

const LeftAdornedDatePicker = (props) => {
  const theme = useTheme();
  return (
    <RootContainer className="date-input-container" theme={theme}>
      <div className="adornment-icon">
        <img src={props.adnicon} />
      </div>
      <div className="input-wrapper">
        {props.hidedate ? (
          <div
            className="toggler-alt"
            onClick={props.togglerOnClick}
            title={props.toggleTitle || ""}
          ></div>
        ) : (
          <CustomDatePicker {...props} />
        )}
      </div>
    </RootContainer>
  );
};

export default LeftAdornedDatePicker;

const RootContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    background: ${(prop) => prop.theme.palette.background.default};
    border-radius: 8px;
    padding: 6px 8px;
    height: 45px;
    .adornment-icon {
        width: 40px;
        text-align: center;
        & img {
            height: 20px;
        }
    }
    .input-wrapper {
        flex-grow: 1;
    }
    .MuiInput-input {
        color: ${(prop) => prop.theme.palette.background.defaultContrast};
        font-family: ${(prop) => prop.theme.palette.font.secondary};
        font-weight: 600;
    }
    .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before, .MuiInput-underline:before {
        border-bottom: none;
    }
    .MuiInput-colorSecondary.MuiInput-underline:after {
        border-bottom-color: unset;
    }
    .toggler-alt {
        width: 100%;
        height: 50px;
        cursor: pointer;
    }
    .react-datepicker {
        display: flex;
    }
    // @media all and (max-width: 1279px) {
    //     border-radius: 25px;
    //     border: solid 1px ${(prop) => prop.theme.palette.background.primary};
    // }
    @media all and (max-width: 768px) {
        .react-datepicker {
            flex-direction: column;
        }
    }
`;
