import { makeStyles } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import {
  localforageSetItem,
  localforageClear,
  localforageGetItemAsync,
  localforageGetItem,
} from "oautils/oaForageUtils";
import { useSelector, useDispatch } from "react-redux";
import { setUserType } from "redux/action";
import { AppStateContext } from "layouts/AppStateProvider";
import WebApi from "api/ApiConstants";
import LoginSection from "../LoginSection";
import HomePageNavbar from "../HomePageNavbar";
import leftCircle from "assets/BuzzHolidays/images/home/LeftCircle.png";
import dots from "assets/BuzzHolidays/images/home/Dots.png";
import girl from "assets/BuzzHolidays/images/home/girl.png";
import companyLogo from "assets/BuzzHolidays/images/home/logo.png";
import landingBg from "assets/BuzzHolidays/images/home/Landingbackground.png";
import OffersSection from "../OffersSection";
import TopFlightRoutes from "../TopFlightRoutes";
import { FooterSection } from "../FooterSection";
import SearchEngineBg from "assets/BuzzHolidays/images/home/flights-banner.png";

const B2bLoginPage = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [usertype, setUsertype] = useState(null);
  const { setUserTypeb2c } = React.useContext(AppStateContext);
  const [corporateType, setCorporateType] = useState(null);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [showServices, setShowServices] = useState(
    props.hideServices ? props.hideServices : props.showServices
  );

  useEffect(() => {
    (async () => {
      const userDetails = await localforageGetItemAsync("user-details");
      if (!userDetails) {
        setIsLoading(true);
        // console.log("##### user-details not found ##");
        WebApi.getAgentInfo({ userType: "G" }, (response) => {
          if (response != null && response.success === true) {
            localforageClear(() => {
              localforageSetItem("user-id", 0);

              localforageSetItem("user-details", response.data, () => {
                dispatch(setUserType(response.data));
                localforageSetItem("access-key", response.accesskey, () => {
                  setIsLoading(false);
                });
              });
            });
          }
        });
      } else {
        dispatch(setUserType(userDetails));
      }
    })();
  }, [isLoading]);

  const getValuesFromLocalStorage = () => {
    localforageGetItem("user-details", function(err, value) {
      // console.log("typ check",value)
      if (value) {
        if (value.userTypeAbv == "S") {
          setIsLoggedIn(true);
        }

        setShowServices(
          value.userType == "distributor"
            ? false
            : showServices != null
            ? showServices
            : true
        );

        setServiceOptions(value.serviceOption);

        setUsertype(value.userType);
        setUserTypeb2c(value.userType);
        setCorporateType(value.corporateType);
      }
    });
  };

  useEffect(() => {
    getValuesFromLocalStorage();
  }, []);

  const width = window.innerWidth;
  const breakpoint = 980;

  return (
    <div className={classes.root}>
      <HomePageNavbar />
      <div className="loginContainer">
        <div className={classes.leftSideContainer}>
          <img src={leftCircle} className="leftCircleImg" />

          <img src={girl} className="girlCharacterImg" />

          <img
            onClick={() => {
              // console.log("first");
              props.history.push("/");
            }}
            src={companyLogo}
            className="companyLogo"
          />
          <img src={dots} className="dots" />
        </div>
        <div className="search__section">
          <div className="login__Div">
            <LoginSection />
          </div>
        </div>
      </div>

      {/* <OffersSection />
            <TopFlightRoutes /> */}
      <FooterSection />
    </div>
  );
};

export default B2bLoginPage;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    margin: 0,
    "& .temp-container": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& img": {
        width: "100%",
        maxWidth: 1280,
      },
    },

    "& .search__section": {
      width: "60%",
      minHeight: "230px",
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.background.default,
      paddingTop: 30,
      // backgroundImage: `url(${SearchEngineBg})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      minHeight: 359,
      [theme.breakpoints.down(1450)]: {
        minHeight: 371,
        backgroundSize: "cover",
      },
      "& h3": {
        textAlign: "center",
        color: "#fff",
        fontWeight: 400,
        marginTop: "2.3rem",
        marginBottom: 0,
        fontSize: "2rem",
        // color: ColorPaleete,
        color: theme.palette.primary.darkText,
      },
    },
    "& .search__box": {
      width: "1200px",
      minHeight: "130px",
      padding: "10px 0",
      [theme.breakpoints.down(980)]: {
        width: "100%",
      },
      [theme.breakpoints.down(1026)]: {
        width: "100%",
      },
    },

    "& .login__Div": {
      float: "right",
      position: "relative",
    },
    "& .loginContainer": {
      display: "flex",
      minHeight: 600,
    },
  },
  homePageContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    minHeight: 750,
    position: "relative",
    "& .landingBg": {
      top: 70,
      width: "100%",
      height: 750,
      position: "absolute",
    },
    "& .rightCircleImg": {
      width: 150,
      height: 350,
      position: "absolute",
      bottom: 0,
      right: -30,
      // left: 20,
    },
  },
  leftSideContainer: {
    width: "40%",
    display: "flex",
    position: "relative",
    "& .leftCircleImg": {
      width: 650,
      height: 550,
      position: "absolute",
      top: 0,
    },

    "& .girlCharacterImg": {
      width: 450,
      height: 450,
      position: "absolute",
      left: 150,
      top: 145,
      zIndex: 5,
      borderRadius: 70,
    },
    "& .dots": {
      width: 120,
      height: 120,
      position: "absolute",
      left: 180,
      top: 150,
      zIndex: 1,
    },
    "& .companyLogo": {
      width: 160,
      height: 120,
      position: "absolute",
      left: 160,
      top: 2,
      zIndex: 20,
      cursor: "pointer",
    },
  },
}));
