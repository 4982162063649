import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import WebApi, {
  POST_PAYGOAL_API_CALLBACK,
  EXT_REDIRECT_SERVER,
} from "api/ApiConstants";
import { Divider, Grid, Button, useTheme } from "@material-ui/core";
import OaFormAlerts from "pages/components/OaFormAlerts";
import { setTimerInitialMinute, setTimerInitialSecond } from "redux/action";
import { connect } from "react-redux";
import { localforageGetItem } from "oautils/oaForageUtils";
import image1 from "assets/rive/busanimation.riv";
import Rive from "rive-react";
import { submitDataToPaygoal } from "pages/module/paymentgateway/Paygoal";
import { submitDataToRazorpay } from "pages/module/paymentgateway/Razorpay";
import { submitDataToCcAvenue } from "pages/module/paymentgateway/CcAvenue";
import { localforageSetItem } from "oautils/oaForageUtils";
import { submitDataToPayU } from "pages/module/paymentgateway/PayU";
import { submitDataToPhonepe } from "pages/module/paymentgateway/Phonepe";
import { POST_PHONEPE_API_CALLBACK } from "api/ApiConstants";

const BusPaymentProgressPage = (props) => {
  const theme = useTheme();
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [priceChangedStop, setPriceChangedStop] = useState(false);
  const [blockData, setBlockData] = useState({});
  const [orderId, setOrderId] = useState(null);
  const [paymentMode, setPaymentMode] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userType, setUserType] = useState(null);
  const [retryPayment, setRetryPayment] = useState(false);

  const [responseMessage, setResponseMessage] = useState(
    "Blocking seat, Please wait"
  );

  const param = { ...props.history.location.state };
  const leadPax = param?.Passenger.filter((v) => {
    return v.LeadPassenger == true;
  })[0];

  const saveTrnParamsToLocal = (paramsToSave) => {
    return new Promise((resolve, reject) => {
      localforageSetItem("bus-transact", paramsToSave, () => {
        resolve();
      });
    });
  };

  async function showRazorPayWindow(trnResponse) {
    // setIsSaving(true);
    setRetryPayment(false);

    if (
      props.location.state &&
      props.location.state.Passenger &&
      props.location.state.Passenger[0]
    ) {
      trnResponse.travelers = [
        { nameReference: props.location.state.Passenger[0].Title },
      ];
      trnResponse.deliveryAddress = {
        firstName: props.location.state.Passenger[0].FirstName,
        lastName: props.location.state.Passenger[0].LastName,
        email: param.Email,
        mobileNumber: param.Phoneno,
        phoneno: param.Phoneno,
        address: param.Address,
      };
      // trnResponse.amountDue = param.selectedSeatIndex.reduce(
      //   (accumulator, currentValue) =>
      //     accumulator + currentValue.Price.CommissionCharge.grossProductPrice,
      //   0
      // );
    }

    let request = {
      paymentOptions: param.pgOption,
      trnResponse: trnResponse,
      selectedPaymentMethod: param.pgOption.method,
      theme: theme,
      trnType: "bus-reservation",
      checkoutUrl: trnResponse.paymentUrl,
    };
    var response = await submitDataToRazorpay(request);
    setIsSaving(false);
    setIsError(response.isError);
    setIsSuccess(response.isSucess);
    setInfoText(response.infoText);
    setRetryPayment(response.retryPayment);
    setResponseMessage(response.responseMessage);

    if (response.isSucess) {
      submitData(response.orderId);
    } else {
      props.history.push(`/bus/payment-status/${param.txnId}`);
    }
  }

  async function showPaygoalWindow(trnResponse) {
    if (
      props.location.state &&
      props.location.state.Passenger &&
      props.location.state.Passenger[0]
    ) {
      // trnResponse.amountDue = param.selectedSeatIndex.reduce(
      //   (accumulator, currentValue) =>
      //     accumulator + currentValue.Price.CommissionCharge.grossProductPrice,
      //   0
      // );
    }
    const request = {
      amount: trnResponse.amountDue,
      encTxnId: trnResponse.uniqueTransactionId,
      EncTxnId: trnResponse.uniqueTransactionId,
      redirectUrl: `${EXT_REDIRECT_SERVER}bus/bus-payment-progress/`, //http://oari.co:8080/emt/#/flight/payment-status/http://localhost:3000/psa_web/flight/payment-status/
      // redirectUrl: `http://oari.co:8080/psa_web/bus/payment-status/${trnResponse.transactionId}/success`,//http://oari.co:8080/emt/#/flight/payment-status/http://localhost:3000/psa_web/flight/payment-status/
      siteUrl: POST_PAYGOAL_API_CALLBACK,
      txnId: trnResponse.transactionId,
      paymentMode: "PayGoal",
      failUrl: `${EXT_REDIRECT_SERVER}bus/payment-status/`,
      checkoutUrl: trnResponse.paymentUrl,
    };

    saveTrnParamsToLocal(param).then(() => {
      submitDataToPaygoal(request);
    });
  }

  async function showCcAvenueWindow(trnResponse) {
    const request = {
      amount: trnResponse.amountDue,
      encTxnId: trnResponse.uniqueTransactionId,
      EncTxnId: trnResponse.uniqueTransactionId,
      redirectUrl: POST_PAYGOAL_API_CALLBACK + "?paymentMode=ccavenue",
      siteUrl: `${EXT_REDIRECT_SERVER}bus/bus-payment-progress/`,
      txnId: trnResponse.transactionId,
      paymentMode: "CCAvenue",
      failUrl: `${EXT_REDIRECT_SERVER}bus/payment-status/`,
      checkoutUrl: trnResponse.paymentUrl,
    };
    saveTrnParamsToLocal(param).then(() => {
      submitDataToCcAvenue(request);
    });
  }

  async function showPayUWindow(trnResponse) {
    const request = {
      amount: trnResponse.amountDue,
      encTxnId: trnResponse.uniqueTransactionId,
      EncTxnId: trnResponse.uniqueTransactionId,
      redirectUrl: POST_PAYGOAL_API_CALLBACK + "?paymentMode=payu",
      siteUrl: `${EXT_REDIRECT_SERVER}bus/bus-payment-progress/`,
      txnId: trnResponse.transactionId,
      paymentMode: "PayU",
      trnType: "bus-reservation",
      checkoutUrl: trnResponse.paymentUrl,
    };
    saveTrnParamsToLocal(param).then(() => {
      submitDataToPayU(request);
    });
  }

  async function showPhonepeWindow(trnResponse) {
    saveTrnParamsToLocal(param);
    const request = {
      amount: trnResponse.amountDue,
      encTxnId: trnResponse.uniqueTransactionId,
      EncTxnId: trnResponse.uniqueTransactionId,
      redirectUrl: POST_PAYGOAL_API_CALLBACK + "?paymentMode=phonepe",
      siteUrl: `${EXT_REDIRECT_SERVER}bus/bus-payment-progress/`,
      failUrl: POST_PHONEPE_API_CALLBACK,
      txnId: trnResponse.transactionId,
      paymentMode: "phonepe",
      trnType: "bus-reservation",
      checkoutUrl: trnResponse.paymentUrl,
      mobileNumber: param.Phoneno,
    };
    submitDataToPhonepe(request);
  }

  const processWalletTransaction = () => {
    submitData(null);
  };

  const processPayment = (userDetails) => {
    param.paymentMode = param.pgOption.method;
    param.amountDue = param.totalPayable;
    param.discountCoupon = param.discountApplied ? param.couponObj?.voucher_code : "";
    param.discountCouponAmount = param.discountApplied ? param.couponObj?.voucher_value : 0;
    
    WebApi.getBusCreateTransaction(
      param,
      (response) => {
        if (response.success) {
          param.txnId = response.data.txnId;
          param.encTxnId = response.data.encTxnId;

          response.data.userDetails = userDetails;
          response.data.paymentMode = param.pgOption.method;
          response.data.transactionId = response.data.txnId;
          response.data.uniqueTransactionId = response.data.encTxnId;
          response.data.userType = userDetails.userTypeAbv;

          switch (param.payMode) {
            case "razorpay": {
              showRazorPayWindow(response.data);
              break;
            }

            case "paygoal": {
              showPaygoalWindow(response.data);
              break;
            }

            case "ccavenue": {
              showCcAvenueWindow(response.data);
              break;
            }

            case "cash": {
              userDetails.userTypeAbv == "S" && processWalletTransaction();
              break;
            }

            case "payu": {
              showPayUWindow(response.data);
              break;
            }

            case "phonepe": {
              showPhonepeWindow(response.data);
              break;
            }
          }
        } else {
          console.error("getBusCreateTransaction failed", response);
          props.history.push(`/bus/payment-status/0/failed`);
        }
      },
      (error) => {
        console.error("getBusCreateTransaction error", error);
        props.history.push(`/bus/payment-status/0/failed`);
      }
    );
  };

  const submitData = (orderId) => {
    param.orderId = orderId;
    WebApi.doBusSeatBlock(
      param,
      (response) => {
        if (response.success) {
          // console.log("success");

          if (response.data.BlockResult.IsPriceChanged) {
            setBlockData(response.data.BlockResult);
            setResponseMessage(
              "Seat blocked successfully, price changed. Please confirm for booking seat on changed price"
            );
            setPriceChangedStop(true);
          } else {
            bookSeat();
          }
        } else {
          setIsError(true);
          setInfoText(response.message);

          setResponseMessage("Error occured. please wait");
          setTimeout(() => {
            // window.history.back();
            props.history.push(`/bus/payment-status/${param.txnId}/failed`);
          }, 5000);
        }
      },
      (error) => {
        console.log("doBusSeatBlock error", error);
        props.history.push(`/bus/payment-status/${param.txnId}/failed`);
      }
    );
  };

  const bookSeat = () => {
    setPriceChangedStop(false);
    setResponseMessage(
      "Seat blocked successfully, please wait booking seat now"
    );

    WebApi.doBusSeatBook(
      param,
      (response) => {
        // console.log(response);
        if (response.success === true) {
          // console.log(response);
          props.setTimerInitialMinute(0);
          props.setTimerInitialSecond(0);
          setResponseMessage(
            "Seat booked successfully, please wait for ticket details"
          );

          WebApi.getBusSeatBookingDetails(
            {
              TraceId: response.data.BookResult.TraceId,
              BusId: response.data.BookResult.BusId,
            },
            (response) => {
              
              setInfoText(response.message);
              if (response.success === true) {
                setIsSuccess(true);
                props.history.push({
                  pathname: `/bus/payment-status/${param.txnId}/success`,
                  state: response.data,
                });
              } else {
                setIsError(true);
                setResponseMessage("Error occured. please wait");
                setTimeout(() => {
                  // window.history.back();
                  props.history.push(
                    `/bus/payment-status/${param.txnId}/failed`
                  );
                }, 10000);
              }
            },
            (error) => {
              console.log("getBusSeatBookingDetails error", error);
              props.history.push(`/bus/payment-status/${param.txnId}/failed`);
            }
          );
        } else {
          setIsError(true);
          setInfoText(response.message);

          setResponseMessage("Error occured. please wait");
          setTimeout(() => {
            // window.history.back();
            props.history.push(`/bus/payment-status/${param.txnId}/failed`);
          }, 10000);
        }
      },
      (error) => {
        console.log("doBusSeatBook error", error);
        props.history.push(`/bus/payment-status/${param.txnId}/failed`);
      }
    );
  };

  useEffect(() => {
    if (isLoading) {
      localforageGetItem("user-details", function(err, value) {
        if (value) {
          setUserType(value.userType ? value.userType : "b2c_user");
          processPayment(value);
        } else {
          console.log("failed to get user detail", err);
        }
      });
    }
  }, [isLoading]);

  return (
    <GridContainer style={{ height: "60vh" }}>
      <Grid item md={12}></Grid>

      {!priceChangedStop ? (
        <>
          <GridItem md={12} style={{width: "100%"}}>
            <OaFormAlerts
              isSaving={isSaving}
              isSuccess={isSuccess}
              isError={isError}
              infoText={infoText}
              setIsError={setIsError}
              setIsSuccess={setIsSuccess}
            />
          </GridItem>
          <GridItem
            md={12}
            style={{
              height: "20vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              backgroundColor: "transparent",
            }}
          >
            {/* <img src={image1} width="100" /> */}
            <div
              style={{
                width: 400,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Rive src={image1} />
            </div>
          </GridItem>
          <GridItem
            md={12}
            style={{
              height: "20vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "transparent",
            }}
          >
            <span style={{ fontSize: 20, fontWeight: "bold" }}>
              {responseMessage}
            </span>
          </GridItem>
        </>
      ) : (
        <>
          <GridItem md={12}>
            <div
              style={{
                fontSize: "20px",
                marginBottom: "0.8rem",
                marginTop: "6rem",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                backgroundColor: "transparent",
                fontWeight: "bold",
              }}
            >
              The fares for the Bus(s) you have selected has changed. Please
              check the fares and continue.
            </div>
            <Divider style={{ marginBottom: "2rem" }} />
            <GridContainer>
              <GridItem md={2}>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginBottom: "4%",
                  }}
                >
                  <u>Bus Travells</u>
                </div>
                <div style={{ fontWeight: "450" }}>{blockData.TravelName}</div>
              </GridItem>
              <GridItem md={1}>
                <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                  <u>Departure</u>
                </div>
                <div style={{ fontWeight: "450", marginTop: "11%" }}>
                  {blockData.DepartureTime}
                </div>
              </GridItem>
              <GridItem md={1}>
                <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                  <u>Arrival</u>
                </div>
                <div style={{ fontWeight: "450", marginTop: "11%" }}>
                  {blockData.ArrivalTime}
                </div>
              </GridItem>
              <GridItem md={2}>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginBottom: "4%",
                  }}
                >
                  <u>Date</u>
                </div>
                <div style={{ fontWeight: "450" }}>
                  {blockData.DepartureTime}
                </div>
              </GridItem>
              <GridItem md={2}>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginBottom: "4%",
                  }}
                >
                  <u>Origin</u>n
                </div>
                <div style={{ fontWeight: "450" }}>Bangalore</div>
              </GridItem>
              <GridItem md={2}>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginBottom: "4%",
                  }}
                >
                  <u>Destination</u>
                </div>
                <div style={{ fontWeight: "450" }}>Hyderabad</div>
              </GridItem>
              <GridItem md={2}>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginBottom: "3%",
                  }}
                >
                  <u>Fare + Tax (Rs.)</u>
                </div>
                <div style={{ fontWeight: "450" }}>
                  <Button style={{ padding: 0, background: "#ececec" }}>
                    1150.00
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
            <Divider style={{ marginBottom: "1rem", marginTop: "2rem" }} />
            <div
              style={{
                marginBottom: "0.2rem",
                marginTop: "1.5rem",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                backgroundColor: "transparent",
                fontWeight: "400",
                fontSize: "16px",
              }}
            >
              Actual Fare: Rs. 1150.00 (Rs. 1000.00 + Rs. 150.00 (Fees and
              Taxes))
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                backgroundColor: "transparent",
              }}
            >
              <Button style={{ padding: "3px 15px", background: "#ececec" }}>
                New Fare : Rs. 1090.00
              </Button>
            </div>
            <Divider style={{ marginBottom: "0.2rem", marginTop: "1rem" }} />
            <div
              style={{
                fontSize: "20px",
                marginBottom: "0.8rem",
                marginTop: "1rem",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                backgroundColor: "transparent",
                fontWeight: "bold",
              }}
            >
              Do you want to continue booking?
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                backgroundColor: "transparent",
              }}
            >
              <Button variant="contained" color="primary" onClick={bookSeat}>
                Yes
              </Button>
              <Button
                style={{ background: "#ececec", marginLeft: "1rem" }}
                onClick={window.history.back()}
              >
                No
              </Button>
            </div>
          </GridItem>
        </>
      )}

      {/* {props.location.state.departureCityId} {props.location.state.destinationCityId} */}
    </GridContainer>
  );
};

function mapStateToProps(state, props) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setTimerInitialMinute: (timerInitialMinute) =>
      dispatch(setTimerInitialMinute(timerInitialMinute)),
    setTimerInitialSecond: (timerInitialSecond) =>
      dispatch(setTimerInitialSecond(timerInitialSecond)),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BusPaymentProgressPage)
);
