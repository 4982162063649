import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import HotelPackageDetail from "pages/module/packages/HotelPackageDetail";
import PackageInfo from "pages/module/packages/PackageInfo";
import PostLoginNavBar from "components/Navbars/PostLoginNavBar.BuzzHolidays";
import { FooterSection } from "components/LandingPage/BuzzHolidays/FooterSection";

const PackagesLayout = (props) => {
  const { ...rest } = props;
  return (
    <div
      style={{
        background: "#f5f5f5",
      }}
    >
      <PostLoginNavBar {...rest} isSearchPage={true} showServices={true} />
      <div
        className="packageContainer"
        style={{
          background: "#f5f5f5",
          padding: "0px 20px",
          paddingTop: 20,
          maxWidth: 1280,
          width: "100%",
          justifyContent: "center",
          display: "flex",
          margin: "0 auto",
        }}
      >
        <Switch>
          {/* <Route path="/packages/categories" exact component={HotelPackages} /> */}
          <Route path="/packages" exact component={HotelPackageDetail} />
          <Route path="/packages/packageinfo" exact component={PackageInfo} />
        </Switch>
      </div>

      {/* <PageFooter white {...props}/> */}
      <FooterSection />
    </div>
  );
};

export default PackagesLayout;
