import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { localforageGetItem } from "oautils/oaForageUtils";
import { useSelector, useDispatch } from "react-redux";
import WebApi from "api/ApiConstants";
import FlightSection from "./forms/FlightSection";
import MobileHotelSection from "components/LandingPage/for-mobile/MobileHotelSection";
import MobileFlightSection from "components/LandingPage/for-mobile/MobileFlightSection";
import MobileBusSection from "components/LandingPage/for-mobile/MobileBusSection";
import HotelSection from "./forms/HotelSection";
import BusSection from "./forms/BusSection";
import { refreshUserSession } from "oautils/oaAuthUtils";
import OaLoading from "pages/components/OaLoading";
import SearchEngineBg from "assets/BuzzHolidays/images/home/flights-banner.png";
import leftCircle from "assets/BuzzHolidays/images/home/LeftCircle.png";
import RightCircle from "assets/BuzzHolidays/images/home/RightCircle.png";
import companyLogo from "assets/BuzzHolidays/images/home/logo.png";
import dots from "assets/BuzzHolidays/images/home/Dots.png";
import girl from "assets/BuzzHolidays/images/home/girl.png";
import landingBg from "assets/BuzzHolidays/images/home/Landingbackground.png";
import SearchEngineBusBg from "assets/BuzzHolidays/images/home/bus-banner.png";
import SearchEngineHotelBg from "assets/BuzzHolidays/images/home/hotels-banner.png";
import { AppStateContext } from "layouts/AppStateProvider";
import { Switch, Route } from "react-router-dom";
import cx from "classnames";

import { Carousel } from "react-responsive-carousel";
import { BASE_URL_IMAGE_VIEW } from "api/ApiConstants";
import PopularRoutes from "./forms/PopularRoutes";
import DomesticSection from "./forms/DomesticSection";
import InternationalSection from "./forms/InternationalSection";
import BeachDestination from "./forms/BeachDestination";
import AdventurePackage from "./forms/AdventurePackage";
import WhyChooseUs from "./forms/WhyChooseUs";

const HomePage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMounted = React.useRef(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [usertype, setUsertype] = React.useState(null);
  const { setUserTypeb2c } = React.useContext(AppStateContext);
  const [corporateType, setCorporateType] = React.useState(null);
  const [serviceOptions, setServiceOptions] = React.useState([]);
  const [showServices, setShowServices] = React.useState(
    props.hideServices ? props.hideServices : props.showServices
  );
  const [routesItem, setRoutesItem] = React.useState([]);
  const [offers, setOffers] = React.useState([]);
  const [packageCategory, setPackageCategory] = useState();
  const [LeadListData, SetLeadListData] = useState([]);

  const LoaderView = () => {
    return (
      <div className="loader-view">
        <OaLoading loadertext="..." />
        <div>
          <span className="loader-text"></span>
        </div>
      </div>
    );
  };

  const FlightSearch = () => {
    return (
      <div className="section-content">
        <div className="bannerText">
          Going Somewhere? <br />
          Book your <span className="selectedTitleText">flight</span> Here
        </div>
        <div className="bannerSubText">
          We are a trusted Flight booking site with 5+ years experience in the
          nation. Check your flight anywhere, anytime.
        </div>
        <div className="search__box">
          {width < breakpoint ? <MobileFlightSection /> : <FlightSection />}
        </div>
      </div>
    );
  };

  const HotelSearch = () => {
    return (
      <div className="section-content">
        <div className="bannerText">
          Discover the collection of <br /> affordable luxury
          <span className="selectedTitleText"> hotels</span>
        </div>
        <div className="bannerSubText">
          Explore this world with great ease with us with attractive holiday
          package deals and business packages that are friendly to your pocket
        </div>
        <div className="search__box">
          {width < breakpoint ? <MobileHotelSection /> : <HotelSection />}
        </div>
      </div>
    );
  };

  const BusSearch = () => {
    return (
      <div className="section-content">
        <div className="bannerText">
          Book Tickets Online and Enjoy <br /> Hassle Free
          <span className="selectedTitleText"> Bus</span> Journey
        </div>
        <div className="bannerSubText">
          You can make your online booking bus on alternate routes, on our
          platform, in case no direct route buses are available.
        </div>
        <div className="search__box">
          {width < breakpoint ? <MobileBusSection /> : <BusSection />}
        </div>
      </div>
    );
  };

  // const InsuranceSearch = () => {
  //   return (
  //     <div className="section-content">
  //       <h3>
  //         {" "}
  //         <span>
  //           <HeaderServiceIcon src={InsuranceIcon} />
  //         </span>{" "}
  //         Secure Your Journey & Travel Stress Free
  //       </h3>
  //       <div className="search__box">
  //         <InsuranceSection />
  //       </div>
  //     </div>
  //   );
  // };

  const callbackRefreshToken = (flag) => {
    setIsLoading(flag);
  };

  const getUserDetails = (mountStatus) => {
    localforageGetItem("user-details", function(err, value) {
      if (mountStatus && value) {
        if (value.userTypeAbv == "S") {
          setIsLoggedIn(true);
        }

        setShowServices(
          value.userType == "distributor"
            ? false
            : showServices != null
            ? showServices
            : true
        );

        setServiceOptions(value.serviceOption);

        setUsertype(value.userType);
        setUserTypeb2c(value.userType);
        setCorporateType(value.corporateType);
        setIsLoading(false);
      } else {
        console.log("error getting user details", err);
      }
    });
  };

  React.useEffect(() => {
    isMounted.current = true;
    refreshUserSession(dispatch, callbackRefreshToken);
    return () => (isMounted.current = false);
  }, []);

  // React.useEffect(() => {
  //   let mounted = true;
  //   if (mounted) {
  //     WebApi.getPopularRoutes(
  //       {
  //         callFrom: "web",
  //       },
  //       (resp) => {
  //         if (resp != null && resp.success) {
  //           // console.log(resp.data, "get pop");
  //           setRoutesItem(resp.data);
  //         } else {
  //           console.log("error resp null", resp);
  //         }
  //       },
  //       (error) => console.log("error", error)
  //     );
  //   }
  //   return () => (mounted = false);
  // }, []);

  React.useEffect(() => {
    getUserDetails(isMounted.current);
    if (isMounted.current) {
      WebApi.getExclusiveOffer(
        {
          callFrom: "web",
          offerCategory: "",
        },
        (resp) => {
          if (resp != null && resp.success) {
            // console.log(resp.data, "get pop");
            setOffers(resp.data);
            // setDataIs(true);
          } else {
            console.log("error resp null", resp);
          }
        },
        (error) => console.log("error", error)
      );
    }
  }, [isMounted.current]);

  const width = window.innerWidth;
  const breakpoint = 980;
  const currentURL = window.location.href; // returns the absolute URL of a page
  const currentTab = currentURL.split("/");

  React.useEffect(() => {
    isMounted.current = true;
    let today = new Date();
    WebApi.getPackageCategory(
      { date: today },
      (response) => {
        if (response.data) {
          //console.log(response.data);
          setPackageCategory(response.data);

          // console.log(response.data)
        }
      },
      (error) => console.log("error fetching package category", error)
    );
    return () => (isMounted.current = false);
  }, []);

  let d = new Date();
  const date =
    ("0" + (d.getDate() + 0)).slice(-2) +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    d.getFullYear();

  useEffect(() => {
    WebApi.getFlightLeadList(
      {
        agentId: 0,
        dateFrom: date,
        dateTo: date,
        status: 1,
      },
      (resp) => {
        // console.log("res", resp);
        SetLeadListData(resp.data);
      },
      (error) => console.log("error", error)
    );
  }, []);

  return (
    <div className={classes.root}>
      {/* <div className="ad_banner">
        <img src={adBanner} />
      </div> */}

      <div className={classes.homePageContainer}>
        <img src={RightCircle} className="rightCircleImg" />
        <img src={landingBg} className="landingBg" />
        <div className={classes.leftSideContainer}>
          <img src={leftCircle} className="leftCircleImg" />

          <img src={girl} className="girlCharacterImg" />

          <img
            src={companyLogo}
            onClick={() => {
              // console.log("first");
              props.history.push("/");
            }}
            className="companyLogo"
          />
          <img src={dots} className="dots" />
        </div>
        <div className={classes.rightSideContainer}>
          <Switch>
            <Route path="/flights" component={FlightSearch} />
            <Route path="/buses" component={BusSearch} />
            <Route path="/hotels" component={HotelSearch} />
            {/* <Route path="/insurances" component={InsuranceSearch} /> */}
            <Route component={FlightSearch} />
          </Switch>
        </div>
      </div>
      <div className={classes.topBanner}>
        <Carousel infiniteLoop showThumbs={false} enableAutoPlay width={"100%"}>
          {LeadListData &&
            LeadListData?.map((item) => (
              <div className={classes.carouselItem} key={item.id}>
                <img
                  src={
                    BASE_URL_IMAGE_VIEW +
                    `agency/view-file?fileName=${item.imagePath}&type=PROMO_IMAGES`
                  }
                />
              </div>
            ))}
        </Carousel>
      </div>

      {/* <div
        className={`search__section ${currentTab[4] === "buses" &&
          classes.busBg} ${currentTab[4] === "flights" &&
          classes.flightBg} ${(currentTab[4] === undefined ||
          currentTab[4] === "") &&
          classes.flightBg}  ${currentTab[4] === "hotels" && classes.hotelBg}`}
      >
        <Switch>
          <Route path="/flights" component={FlightSearch} />
          <Route path="/buses" component={BusSearch} />
          <Route path="/hotels" component={HotelSearch} />
          <Route path="/insurances" component={InsuranceSearch} />
          <Route component={FlightSearch} />
        </Switch>
      </div> */}
      <PopularRoutes data={packageCategory?.[0]} />
      <DomesticSection data={packageCategory?.[0]} />
      <InternationalSection data={packageCategory?.[0]} />
      <BeachDestination data={packageCategory?.[0]} />
      <AdventurePackage data={packageCategory?.[0]} />
      <WhyChooseUs data={packageCategory?.[0]} />
    </div>
  );
};

export default HomePage;

const HeaderServiceIcon = (props) => (
  <img
    className={cx("header-service-icon", props.lg ? "icon-lg" : "")}
    src={props.src}
  />
);

const useStyles = makeStyles((theme) => ({
  flightBg: {
    backgroundImage: `url(${SearchEngineBg})`,
  },
  busBg: {
    backgroundImage: `url(${SearchEngineBusBg})`,
  },
  hotelBg: {
    backgroundImage: `url(${SearchEngineHotelBg})`,
  },

  root: {
    padding: 0,
    fontFamily: "poppins",
    margin: 0,
    backgroundColor: "#F9F9F9",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& .ad_banner": {
      height: 130,
      width: "100%",
      [theme.breakpoints.down(1350)]: {
        width: "90%",
      },
      padding: 10,
      // borderRadius: 5,
      "& img": {
        width: "100%",
        height: "100%",
        borderRadius: 5,
      },
    },
    "& .temp-container": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& img": {
        width: "100%",
        maxWidth: 1280,
      },
    },
    "& .search__section": {
      width: "100%",
      minHeight: "230px",
      position: "relative",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      paddingTop: 70,
      // backgroundColor: theme.palette.background.lightYellowGreen,
      // backgroundImage: `url(${SearchEngineBg})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      minHeight: 500,
      // backgroundHeight: "100% 100px",
      // [theme.breakpoints.down(980)]: {
      //     display: 'block',
      // },
      [theme.breakpoints.down(1450)]: {
        minHeight: 371,
        backgroundSize: "cover",
      },
      "& .section-content": {
        width: 1200,
        [theme.breakpoints.down(1220)]: {
          width: "98%",
        },
        padding: "16px 0",
        "& .header-container": {
          display: "flex",
          justifyContent: "flex-end",
        },
        "& .header-hotel-container": {
          display: "flex",
          justifyContent: "flex-start",
        },
        "& .bannerText": {
          fontSize: 50,
          fontWeight: 700,
          color: "#004064",
          textAlign: "center",
          marginBottom: 55,
          fontFamily: "TimesNewRoman",
        },
        "& .bus-banner-text": {
          fontSize: 50,
          fontWeight: 700,
          color: "#fff",
          textAlign: "center",
          marginBottom: 15,
          fontFamily: "TimesNewRoman",
          textAlign: "right",
          lineHeight: 1,
          // width: 600,
          margin: "0 32px",

          // marginLeft: 0,
        },
        "& .hotel-banner-text": {
          fontSize: 50,
          fontWeight: 700,
          color: "#fff",
          textAlign: "center",
          marginBottom: 15,
          fontFamily: "TimesNewRoman",
          textAlign: "left",
          lineHeight: 1,
          // width: 600,
          margin: "0 32px",
        },
        "& h3": {
          textAlign: "center",
          //color: "#fff",
          color: "#665757",

          fontWeight: 400,
          // marginTop: "2.3rem",
          marginBottom: 0,
          fontSize: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // color: theme.palette.primary.darkText,
          "& span": {
            display: "inline-block",
            margin: "0 5px 5px",
            "& .MuiSvgIcon-root": {
              fontSize: "30px",
            },
          },
        },
      },
    },
    "& .search__box": {
      // width: '1200px',
      minHeight: "130px",
      padding: "0px 0",
      [theme.breakpoints.down(980)]: {
        width: "100%",
      },
      [theme.breakpoints.down(1026)]: {
        width: "100%",
      },
    },

    "& .login__Div": {
      float: "right",
      position: "relative",
    },
    "& .loader-view": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "fixed",
      flexDirection: "column",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 103,
      background: "rgba(0,0,0,0.5)",
      "& img": {
        height: "7rem",
      },
      "& .loader-text": {
        color: theme.palette.primary.main,
        fontWeight: 600,
        textAlign: "center",
        marginLeft: 10,
      },
    },
    "& .header-service-icon": {
      height: 40,
      marginRight: 10,
      "&.lg": {
        height: 40,
      },
    },
  },

  carouselItem: {
    margin: "4px 0px",
    height: 150,
    // width: "100%",
    // width: 300,
    "& img": {
      borderRadius: "0.5rem 0.5rem 0 0",
      marginBottom: 10,
      height: 150,
      // width: 300,
      [theme.breakpoints.down("sm")]: {
        // width: 270,
      },
    },
    "& p": {
      color: theme.palette.primary.defaultText,
      fontSize: 16,
      fontWeight: 500,
      margin: 5,
    },
    "& .desc": {
      margin: "16px 0",
    },
  },
  topBanner: {
    width: "100%",
    "& .carousel-root": {
      width: "100%",
    },
    "& img": {
      borderRadius: "0.5rem 0.5rem 0 0",
      marginBottom: 10,
      height: 150,
      width: "100%",
      // width: "700px !important",
      [theme.breakpoints.down("sm")]: {
        // width: 270,
      },
    },
  },
  homePageContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    minHeight: 750,
    position: "relative",
    "& .landingBg": {
      top: 70,
      width: "100%",
      height: 750,
      position: "absolute",
      [theme.breakpoints.down(1200)]: {
        height: 550,
        top: 170,
      },
    },
    "& .rightCircleImg": {
      width: 120,
      height: 320,
      position: "absolute",
      bottom: 0,
      right: 0,
      // left: 20,
    },
  },
  leftSideContainer: {
    width: "40%",
    display: "flex",
    position: "relative",
    "& .leftCircleImg": {
      width: 650,
      height: 550,
      position: "absolute",
      top: 0,
      [theme.breakpoints.down(1380)]: {
        height: 550,
        width: 550,
      },
      [theme.breakpoints.down(1250)]: {
        height: 450,
        width: 450,
      },
    },

    "& .girlCharacterImg": {
      width: 450,
      height: 450,
      position: "absolute",
      left: 150,
      top: 145,
      zIndex: 5,
      borderRadius: 70,
      [theme.breakpoints.down(1380)]: {
        left: 100,
      },
      [theme.breakpoints.down(1250)]: {
        left: 60,
        width: 350,
        height: 350,
        top: 200,
      },
    },
    "& .dots": {
      width: 120,
      height: 120,
      position: "absolute",
      left: 180,
      top: 150,
      zIndex: 1,
      [theme.breakpoints.down(1250)]: {
        left: 100,
      },
    },
    "& .companyLogo": {
      width: 160,
      height: 120,
      position: "absolute",
      left: 160,
      top: 2,
      zIndex: 20,
      cursor: "pointer",
      [theme.breakpoints.down(1250)]: {
        left: 80,
      },
    },
  },
  rightSideContainer: {
    padding: 40,
    marginTop: 70,
    width: "60%",
    "& .section-content": {
      "& .bannerText": {
        fontSize: 50,
        fontWeight: 800,
        lineHeight: 1.3,
        [theme.breakpoints.down(1100)]: {
          fontSize: 35,
        },
      },
      "& .selectedTitleText": {
        background: theme.palette.text.secondary,
        backgroundClip: "text",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        textTransform: "uppercase",
      },
      "& .bannerSubText": {
        marginTop: 15,
        fontSize: 17,
        fontWeight: 500,
        width: 650,
        color: "#7C7C7E",
        [theme.breakpoints.down(1150)]: {
          width: "unset",
        },
      },
    },
  },
}));
