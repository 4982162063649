import React, { useEffect, useRef, useState } from 'react';
// import './Autocomplete.css';
import WebApi from 'api/ApiConstants';
import styled from "styled-components";
import cross from "assets/icons/cross.png"

const HotelCityLocationInput = ({ props, index, type, values, setFieldValue }) => {
  const [inputValue, setInputValue] = useState(props.values.cityObj.cityName);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [hotelCityList, setHotelCityList] = React.useState(popularCities);
  const [isOpen, setIsOpen] = useState(false);
  const [showClear, setShowClear] = useState(false)
  const autocompleteRef = useRef(null);

  // console.log(p)

  useEffect(() => {
    setInputValue(props.values.cityObj.cityName || "")
  }, [props])
// 
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleInputChange = (event) => {
    const value = event.target.value;
    fetchMatchingCities(1,event.target.value.trim(), props.values.countryCode)
    setIsOpen(true)
    setInputValue(value);
    console.log(hotelCityList)

    const filtered = hotelCityList.filter(option =>
      option.cityName.toLowerCase().includes(value.toLowerCase())
    );
    // console.log(filtered)
    setFilteredOptions(filtered);
  };

  const fetchMatchingCities = (searchType, searchParam, countryCode) => {
    // console.log("hotel city search");

    if (searchParam.length < 3) {
      return;
    }

    // setCallingApi(true);
    WebApi.getMatchingHotelCity(
      {
        searchType: searchType,
        countryCode: countryCode || "IN",
        hotelCity: searchParam,
      },
      (response) => {
        if (response.success === true) {
          // console.log("hotel city search", searchParam);
          // console.log("matching cities response", response);
          setHotelCityList(response.data.destinations)
          props.setHotelCity(response.data.destinations);
        } else {
        //   setIsError(!response.success);
        //   setInfoText(response.message);
        }
        // setCallingApi(false);
      },
      (error) => {
        // setCallingApi(false);
        console.log("getMatchingHotelCity error", error);
      }
    );
  };

  const handleSelectOption = (value) => {
    setFieldValue("cityObj", value);
    setFieldValue("cityId", value.destinationId);
    setInputValue(value.cityName)
    setFilteredOptions([]);
  };

  const clearInputHandler = (value) => {
    setInputValue("") 
    autocompleteRef.current.focus();
  };

  return (
    <Root ref={autocompleteRef} className="flight-location-input-container"   onMouseEnter={() => setShowClear(true)}
    onMouseLeave={() => setShowClear(false)}>
      <input
        type="text"
        value={inputValue}
        className='hotel-citylocation-input'
        onChange={handleInputChange}
        placeholder="Search."
      />
      {showClear && <img src={cross} className='clear-icon' onClick={() => clearInputHandler()} />}
      {isOpen && filteredOptions.length > 0 &&  <ul      className='hotel-location-lists'>
        {filteredOptions.map((option, index) => (
          <li  className='hotel-location-list' key={index} onClick={() => handleSelectOption(option)}>
            
            <div>
              <div className='hotel-input-popup'>
                <div>{option.cityName}</div>
                
              </div>
              
            </div>
          </li>
        ))}
      </ul>}
     
    </Root>
  );
};

export default HotelCityLocationInput;


const popularCities = [
  {
    cityName: "Delhi",
    countryCode: "IN",
    countryName: "India",
    destinationId: "130443",
    hotelName: null,
    stateProvince: "DL",
    type: null,
  },
  {
    cityName: "Navi Mumbai",
    countryCode: "IN",
    countryName: "India",
    destinationId: "128734",
    hotelName: null,
    stateProvince: "MH",
    type: null,
  },
  {
    cityName: "Bengaluru",
    countryCode: "IN",
    countryName: "India",
    destinationId: "111124",
    hotelName: null,
    stateProvince: "KA",
    type: null,
  },
  {
    cityName: "Goa",
    countryCode: "IN",
    countryName: "India",
    destinationId: "119805",
    hotelName: null,
    stateProvince: "GA",
    type: null,
  },
  {
    cityName: "Chennai",
    countryCode: "IN",
    countryName: "India",
    destinationId: "127343",
    hotelName: null,
    stateProvince: "TN",
    type: null,
  },
  {
    cityName: "Jaipur",
    countryCode: "IN",
    countryName: "India",
    destinationId: "122175",
    hotelName: null,
    stateProvince: "RJ",
    type: null,
  },
];


const Root = styled.div`
position: relative;
font-family: poppins;
.hotel-citylocation-input {
  height: 45px;
  padding: 10px;
  border-radius: 10px;
  border: 0px;
  font-size: 17px;
  font-family: poppins;
  color: #838383;
  font-weight: 500;
  width: 100%;
  position: relative;
}
.clear-icon{
  // display: none;
  height: 20px;
  width: 20px;
  position: absolute;
  right: 10px;
  top: 12px;
  opacity: 50%;
  cursor: pointer;

}
.hotel-location-lists {
  position: absolute;
  width: 100%;
  background-color: #fff;
  margin: 0px;
  list-style: none;
  z-index:10;
  padding: 0px;
  overflow-y: scroll;
  max-height: 250px;
}
.hotel-location-list {
  font-family: "poppins";
  padding: 10px;
  font-size: 14px;
  font-family: "poppins";
  color: #838383;
  font-weight: 500;

  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
}
.hotelcity-input-popup {
  display: flex;
  justify-content: space-between;
}
.airport-input-popup {
font-size: 12px;
overflow: hidden;
width: 100%;
white-space: nowrap;
text-overflow: ellipsis;
}

  
`;
